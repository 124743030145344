import './aboutus.css';
import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/infoleak_text.svg';
import dataLeakDiscovery from '../../assets/images/data-leak-discovery.svg';
import threatIntelligence from '../../assets/images/threat-intelligence.svg';
import customerCentricLogo from '../../assets/images/customer-centric-logo.svg';
import target from '../../assets/images/target.png';
import stack from '../../assets/images/stack.png';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { getInfoleaks } from '../../api/getDetails';
import LoaderComponents from '../../components/loader/loader';
import RecorderCount from '../../components/recordcount/recordcount.component';
import go from './../../assets/images/go.png';
import { useNavigate } from 'react-router-dom';
import joinus from '../../assets/images/joinus.png';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import user5 from '../../assets/images/user5.png';

function AboutUs() {
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('user')));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    useEffect(()=>{
      setLoading(true);
      getInfoleaks('api/infoleaks').then((response) => {
        setData(response.data);
        setLoading(false);
      }).catch(error => {
        console.log(error);
      })
    },[])

    function getInfoLeaks() {
      setLoading(true);
      getInfoleaks('api/infoleaks').then((response) => {
        setData(response.data);
        setLoading(false);
      }).catch(error => {
        console.log(error);
      })
    }

    function goToHome() {
      navigate("/");
    }

    function logout(){
        localStorage.removeItem('user');
    localStorage.removeItem('access_token');
        setUserDetails(null)
      }
    const styles = {
        background: '#1B1D25 !important',
    borderRadius: '50%',
    fontSize: '20px',
    color: '#ebebeb',
    fontStyle: "normal",
    fontWeight: "500",
      }
      function navigateToSubscription() {
        if(userDetails) {
            navigate('/main/chooseplan');
        } else {
            navigate('/login');
        }
    }   
  return (
    <div className='wrapper'>
        <div className='back-image'>
          <div className={'col-12 d-flex about-wraper-header'}>
            <div className='col-md-2'></div>
            <div className='col-md-8 col-12 d-flex header-section py-3'>
              <div className='w-50 px-4'>
              <img src={logo} width="150" height="40" className='cursor-pointer' onClick={goToHome}/>
              </div>
              <div className='w-50 d-flex justify-content-end align-items-center px-4'>
                { !userDetails &&
                <>
                <div>
                <Link to={'/aboutus'} className="aboutus-link cursor-pointer px-2 right-border mobile-font"> About Us </Link>
                <div className='h-110'></div>
                </div>
                <Link to={'/login'} className="signin-link cursor-pointer px-2 mobile-font"> Sign In </Link>
                </>
                }
                { userDetails && userDetails.name &&
                <div className="col-4 d-flex justify-content-end align-items-center">
                <span className="d-flex about-header-menu-userSection-textlable cursor-pointer position-relative user-name justify-content-end">
                  <span className='header-name'>{userDetails.name}</span>
                  <Dropdown>
      <Dropdown.Toggle style={styles} variant='secondary' className='about-header-menu-userSection-textlable-icon user-icon position-absolute d-flex justify-content-center align-items-center'>
      {userDetails?.name[0].toUpperCase()}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                </span>
              </div>
                }
              </div>
            </div>
            <div className='col-md-2'></div>
          </div>
          <div className='center-alligned'>
            <p className='hero-text'>We help organisations to Identify data leaks </p>
            <p className='hero-sub-text'>Your reliable partner in the ever-evolving landscape of cybersecurity. Founded by a dedicated team of cybersecurity specialists.</p>
          </div>
        </div>
        <div className='container max-1100'>
        <div className='row top-to-200'>
            <div className='col-lg-6 col-md-12 mb-md-2'>
                <div className='card'>
            <p className='card-header'>Our mission</p>
            <p className='card-text'>
            Explore the intricate layers of the digital universe, including the enigmatic realms of the deep and dark web.
            </p>
            <div className='center-image'>
            <img width={350} height={350} src={target}/>
            </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
            <div className='card'>
            <p className='card-header'>Our vision</p>
            <p className='card-text'>
            Ensure data security for dynamic digital environments with instant leak alerts.
            </p>
            <div className='center-image'>
            <img width={350} height={387} src={stack}/>
            </div>
                </div>
            </div>
        </div>
        </div>
        <div className='container'>
        <div className='row'>
                <div className='col-lg-4 col-md-12'>
                  <p className='gradient-text strength'>Our Strength</p>
                  <p className='pioneering'>Pioneering cybersecurity intelligence</p>
                </div>
                <div className='col-lg-8 col-md-12'>
                  <p className='strength-text'>
                  We empower individuals and organisations with the insights they need to fortify their defences against cyber adversaries. From uncovering data breaches to identifying sensitive information exposed on the web, our comprehensive research and analysis provide actionable intelligence that enables proactive cybersecurity measures
                  </p>
                </div>
            </div>
            <div className='row margin-top-50 margin-bottom-50'>
                <div className='col-lg-4 col-md-12'>
                  
                </div>
                <div className='col-lg-8 px-4 col-md-12'>
                  <div className='data-leaks-row py-2'>
                    <img src={dataLeakDiscovery} height="30" width="30"/>
                    <div className='mx-4'>
                      <p className='mb-0 data-text'>Data leak discovery</p>
                      <p className='mb-0 support-text'>Supporting text between 10 to 15 words max</p>
                    </div>
                  </div>
                  <div className='data-leaks-row my-4 py-2'>
                    <img src={threatIntelligence} height="30" width="30"/>
                    <div className='mx-4'>
                      <p className='mb-0 data-text'>Threat intelligence research</p>
                      <p className='mb-0 support-text'>Supporting text between 10 to 15 words max</p>
                    </div>
                  </div>
                  <div className='data-leaks-row py-2'>
                    <img src={threatIntelligence} height="30" width="30"/>
                    <div className='mx-4'>
                      <p className='mb-0 data-text'>Threat intelligence research</p>
                      <p className='mb-0 support-text'>Supporting text between 10 to 15 words max</p>
                    </div>
                  </div>
                </div>
            </div>
            <div className='row margin-top-50'>
                <div className='col-lg-4 col-md-12'>
                  <p className='gradient-text strength'>Our Values</p>
                  <p className='pioneering'>Our core principles guide our actions.</p>
                </div>
                <div className='col-lg-8 col-md-12'></div>
            </div>
            <div className='col-12 margin-top-50'>
                <div className='row'>
                <div className='col-lg-4 col-md-12 customer-centric-card p-3 m-2'>
                  <img src={customerCentricLogo} height="30" width="30"/>
                  <p className='customer-centric-card-heading mt-2'>Customer centric</p>
                  <p className='mb-0 customer-centric-card-text'>We don’t simply respond to customer needs; we anticipate them. We listen with an open mind, embracing diverse inputs and collaborative perspectives to deliver the very best solutions that proactively meet - and exceed - their needs.</p>
                </div>
                {/* <div className='col-lg-4 col-md-12 customer-centric-card p-3 m-2'>
                <img src={customerCentricLogo} height="30" width="30"/>
                <p className='customer-centric-card-heading mt-2'>Customer centric</p>
                  <p className='mb-0 customer-centric-card-text'>We don’t simply respond to customer needs; we anticipate them. We listen with an open mind, embracing diverse inputs and collaborative perspectives to deliver the very best solutions that proactively meet - and exceed - their needs.</p>
                </div>
                <div className='col-lg-4 col-md-12 customer-centric-card p-3 m-2'>
                <img src={customerCentricLogo} height="30" width="30"/>
                <p className='customer-centric-card-heading mt-2'>Customer centric</p>
                  <p className='mb-0 customer-centric-card-text'>We don’t simply respond to customer needs; we anticipate them. We listen with an open mind, embracing diverse inputs and collaborative perspectives to deliver the very best solutions that proactively meet - and exceed - their needs.</p>
                </div> */}
                </div>
            </div>
            <div className='col-12 leaks-section'>
                <p className='w-100 text-center pt-4 heading mb-0'>Data leaks we found till date</p>
                <p className='w-100 text-center heading mb-0'>and we never stop.</p>
                <div className='leaks-counts-section'>
                  <div>
                  {loading ? (<LoaderComponents small={true}/>) : (<>
                    <p className='leak-count'><RecorderCount recordCount={data?.usersCount}/></p>
                    <p className='leak-count-text'>Users leaked</p>
                    </>)}
                  </div>
                  <div>
                  {loading ? (<LoaderComponents small={true}/>) : (<>
                    <p className='leak-count'><RecorderCount recordCount={data?.employeesCount}/></p>
                    <p className='leak-count-text mb-0'>Corporate Credentials</p>
                    <p className='leak-count-text'>leaked</p>
                  </>)}
                  </div>
                  <div>
                    {loading ? (<LoaderComponents small={true}/>) : (<>
                    <p className='leak-count'><RecorderCount recordCount={data?.numDocs}/></p>
                    <p className='leak-count-text'>Credentials leaked</p>
                    </>)}
                  </div>
                </div>
            </div>
            <br/>
            <br/>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-12 mb-4'>
                    <div className='row'>
                    <div className='col-6 text-center'><img className='team-user-icon' src={user1}/></div>
                    <div className='col-6 text-center'><img className='team-user-icon' src={user2}/></div>
                    </div>
                </div>
                <div className='col-12 text-center my-4'>
                <img className='team-user-icon' src={user3}/>
                </div>
                <div className='col-12 mb-4'>
                <div className='row'>
                <div className='col-6'><img className='team-user-icon' src={user4}/></div>
                <div className='col-6 text-center'><img className='team-user-icon position-relative left-50px' src={user5}/></div>
                </div>
                </div>
            </div>
        </div>
        <div className='container position-relative'>
            <div className='h-500-fix'>
                <div className='back-mask'></div>
                <div className='front-mask text-center'>
                    <p className='hero-text'>We help you in discovery of your data leaks </p>
                    <hr className='hr-border'/>
                    <div className='d-flex w-100 justify-content-center'>
                        <img src={logo} width={150} height={40} alt="img" className=''/>
                    </div>
                </div>
            </div>
        </div>
        <div className='container mt-4'>
            <div className='row text-left joinus-section'>
                <div className='col-lg-6 col-md-12 order-1 p-r-32'>
                    <p className='hero-text'>Join us</p>
                    <p className='hero-sub-text'>Join us as we continue our mission to illuminate the unseen and defend against the unknown. Together, let's make cyberspace safer for all. InfoLeak.io—where cybersecurity meets discovery.</p>
                </div>
                <div className='col-lg-6 col-md-12 order-2'>
                <img src={joinus} width={'100%'} height={'auto'}/>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='footer-section'>
                <div className='w-100'></div>
            </div>
            <div className='footer-logo'>
                <img src={logo} width={150} height={40} alt="img" className=''/>
            </div>
            <div className='row'>
                <div className='col-md-12 col-lg-6'>
                <p className='p-0 m-0 sub-card-footer feature-moto'>Let’s secure the future</p>
                <p className='p-0 m-0 feature-moto'>of your company.</p>
                </div>
                <div className='col-md-12 col-lg-6'>
                <div className=' text-center m-4'>
                <button className='btn btn-primary p-3 find-btn' onClick={navigateToSubscription}>Find the current risks<img className='mx-4' src={go} alt="img"/></button>
                </div>
                </div>
            </div>
            <div className='margin-top-50 margin-bottom-50 footer'>
            <hr/>
            </div>
            <div className='margin-top-50 margin-bottom-50'>
                <div className='d-lg-flex justify-content-between policy-text w-100'>
                <div className='mobile-footer-section'><a className='mobile-footer-section-tag' href='/#/privacypolicy' target='_blank'>Privacy Policy</a><img className='mobile-footer-section-img' src={go} alt='img'/></div>
                <div className='mobile-footer-section'><a className='mobile-footer-section-tag' href='/#/support' target='_blank'>Support</a><img className='mobile-footer-section-img' src={go} alt='img'/></div>
                <div className='mobile-footer-section'><a className='mobile-footer-section-tag' href='/#/cancellationpolicy' target='_blank'>Cancellation Policy</a><img className='mobile-footer-section-img' src={go} alt='img'/></div>
                <div className='mobile-footer-section'><a className='mobile-footer-section-tag' href='/#/termsofservice' target='_blank'>Terms Of Service</a><img className='mobile-footer-section-img' src={go} alt='img'/></div>
                <div className='mobile-footer-section'>@2024 InfoLeak</div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default AboutUs;