import { useEffect, useState } from 'react';
import './Login.css';
import { useNavigate } from "react-router-dom";
import logo from './assets/images/infoleak_text.svg'
import {Form, InputGroup} from 'react-bootstrap';
import { postLoginDetails } from './api/postDetails';
import { getSubscription } from './api/getDetails';
import { connect } from "react-redux";
import eyeIconShow from "./assets/images/eye_show.svg";
import eyeIconHide from "./assets/images/eye_hide.svg";

function Login({user, 
  subscriptionStatus, 
  subscriptionType, saveUser, savePayment}) {
  const navigate = useNavigate();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showUserNameError, setShowUserNameError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showCredentialsError, setShowCredentialsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(()=>{
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
  },[])

  function navigateToSignUp() {
      navigate('/signup');
  }

  function submitLogin() {
    if(username !== '' && password !== '') {
      setShowUserNameError(false);
      setShowPasswordError(false);
      postLoginDetails({username: username, password: password}).then((response) => { 
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('access_token', JSON.stringify(response.data.accessToken));
        saveUser(response.data.user)
        getSubscriptionDetails(response.data.user.id);
      }).catch(error => { 
        setShowCredentialsError(true);
      })
    } else {
      if(!username || username === '') {
        setShowUserNameError(true);
      }
      if(!password || username === ''){
        setShowPasswordError(true);
      }
    }
  }

  function getSubscriptionDetails(id) {
    getSubscription('api/payment/retrive', id).then((response) => {
      savePayment(response);
if(response.data.subscriptionStatus === 'active' || response.data.subscriptionStatus === 'trialing') {
      navigate('/main/leakeddetails');
} else {
      navigate('/main/search');
}
    }).catch((error) => {
      console.log(error);
      navigate('/main/search');
    })
  }

  function userNameChange(event) {
    if(event.target.value !== '') {
      setUserName(event.target.value);
      setShowUserNameError(false);
    } else {
      setShowUserNameError(true);
    }
  }

  function passwordChange(password) {
    if(password !== '') {
      setPassword(password);
      setShowPasswordError(false);
    } else {
      setShowPasswordError(true);
    }
  }

  function navigateToForgotPassword() {
    navigate('/forgotpassword');
  }
  function goToHome(){
    navigate('/');
  }

  return (
    <div className="pre-login-screen">
      <div className="pre-login-screen-header pt-3">
          <div className="col-12 d-flex p-4">
              <div className="col-6 position-relative">
              <img className='cursor-pointer' src={logo} width="150" height="40" onClick={goToHome}/>
              </div>
              <div className="col-6 position-relative text-right d-lg-flex justify-content-end align-items-center disp-mob-none">
                  <p className="cursor-pointer text-white mx-3 mb-0 account-link">Don't have an account?</p>
                  <button className='header-btn px-4 py-2' onClick={navigateToSignUp}>Create new account</button>
              </div>
          </div>
      </div>
      <div className="pre-login-content-screen">
          <div className="pre-login-content-block px-4">
              <div className="p-4">
                  <p className='my-2 signin-header'>Sign In</p>
                  <div className='my-4'>
                    <Form.Control required size="lg" type="text" placeholder="Email" onKeyUp={(e) => {e.target.value = e.target.value.toLowerCase()}} onChange={userNameChange} />
                    {showUserNameError && <div className='error-msg'>Email is required.</div>}
                  </div>
                  <div>
                    <Form.Group>
                    <InputGroup>
                    <Form.Control size="lg" required type={showPassword?"text":"password"} placeholder="Password" onChange={(e) => passwordChange(e.target.value)}/>
                    <InputGroup.Text className='back-transparent'>
                        <img width="15" height="15" src={showPassword ? eyeIconHide : eyeIconShow} className='filter-term cursor-pointer' onClick={()=>{setShowPassword(!showPassword)}}/>
                    </InputGroup.Text>
                    </InputGroup>
                    </Form.Group>
                    {/* <Form.Control size="lg" required type="password" placeholder="Password" onChange={passwordChange}/> */}
                    {showPasswordError && <div className='error-msg'>Password is required.</div>}
                  </div>
                  <p className='mb-0 forgot-password cursor-pointer mt-4' onClick={navigateToForgotPassword}>Forgot Password</p>
                  {showCredentialsError && <div className='error-msg my-2 text-center'>Invalid Credentials.</div>}
                  <div className='my-4'><button className='create-btn px-4 py-2 w-100' onClick={submitLogin}>Sign In</button></div>
                  <div className='disp-lg-none disp-mob-block'>
                  <p className='my-2 text-center white-font'>Don't have an account? <span className='cursor-pointer color-blue' onClick={navigateToSignUp}>Create new account!</span></p>
                  </div>
              </div>
          </div>
          <p className="mb-0 mt-4 text-white w-100 text-center">Know Your Leaks Before They're Exploited!</p>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    subscriptionStatus: state.user.subscriptionStatus,
    subscriptionType: state.user.subscriptionType,
  }
}
const mapDispatchToProps = (dispatch) => ({
  saveUser: (payload) => dispatch({ type: "user", payload: payload }),
  savePayment: (payload) => dispatch({ type: "subscription", payload: payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
