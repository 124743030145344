import { memo, useCallback, useEffect, useState } from 'react';
import { getBrandScoreData } from '../../api/getDetails';
import downArrow from '../../assets/images/down_arrow.svg';
import upArrow from '../../assets/images/up_arrow.svg';
import dropdown from '../../assets/images/dropdown.png';
import dropup from '../../assets/images/dropup.png';
import data from './../../assets/data.json';
import leftDownArrow from './../../assets/images/left-down-arrow.png';
import progressCircle from './../../assets/images/progress-circle.svg';
import rightDownArrow from './../../assets/images/right-down-arrow.png';
import VerticleScroller from './../verticle-scroller/verticle-scroller';
import './cyber-security.css';
import LoaderComponents from '../loader/loader';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const CyberSecurity = memo(function CyberSecurity(props) {
    
    // const d = data;
    const [d, setD] = useState(null);
    const [count, setCount] = useState(0);
    const [emailSec, setEmailSec] = useState([]);
    const [network, setNetWork] = useState([]);
    const [ssl, setSSL] = useState([]);
    const [applications, setApplications] = useState([]);
    const [sectionsOpen, setSectionsOpen] = useState(false);
    const [sslPending, setSslPending] = useState(false);
    const [emailPending, setEmailPending] = useState(false);
    const [networkPending, setNetworkPending] = useState(false);
    const [applicationsPending, setApplicationsPending] = useState(false);
    const [loading, setLoading] = useState(true);
    const width = window.innerWidth;
    const left = {
        left: (width*(count/100))- (count == 0 ? 0 : 30)+'px',
    }
    const size = width<768?'mobile':'desktop'
    const right = {
        right: width - (width*(count/100)) - (count == 100 ? 10 : 20)+'px',
      }
    const progressStyle = {
        left: count+'%',
    }
    function showSections() {
        setSectionsOpen(!sectionsOpen);
    }
    let id;

    useEffect(()=>{
        getBrandData();
        return () => {
            window.clearInterval(id);
          };
    },[props.domain])

    const getBrandData = useCallback(() => {
        getBrandScoreData(props.domain).then((response) => {
            if(response?.data?.scan_status == 'COMPLETED') {
                setD(response.data);
                setCount(response?.data?.score);
                response?.data?.scanTypes.forEach((element) => {
                    if(element.type === 'email_sec') {
                        if(element.scan_status === 'PENDING'){
                            setEmailPending(true);
                        } else {
                            setEmailPending(false);
                        }
                        element?.checks?.sort((a, b) => (a.passed > b.passed) ? 1 : -1);
                        setEmailSec(element.checks);
                    } else if(element.type === 'network_sec') {
                        if(element.scan_status === 'PENDING'){
                            setNetworkPending(true);
                        } else {
                            setNetworkPending(false);
                        }
                        element?.checks?.sort((a, b) => (a.passed > b.passed) ? 1 : -1)
                        setNetWork(element.checks);
                    } else if(element.type === 'ssl_sec') {
                        if(element.scan_status === 'PENDING'){
                            setSslPending(true);
                        } else {
                            setSslPending(false);
                        }
                        element?.checks?.sort((a, b) => (a.passed > b.passed) ? 1 : -1)
                        setSSL(element.checks);
                    } else if(element.type === 'app_sec') {
                        if(element.scan_status === 'PENDING'){
                            setApplicationsPending(true);
                        } else {
                            setApplicationsPending(false);
                        }
                        element?.checks?.sort((a, b) => (a.passed > b.passed) ? 1 : -1)
                        setApplications(element.checks);
                    }
                });
            } else if(response?.data?.scan_status == 'PENDING'){
                setD(response.data);
                id = setTimeout(() => getBrandData(), 30000);
            }
            setLoading(false);
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }, [props.domain])
    
    return (
        <>
            {d?.scan_status == 'COMPLETED' && 
            <div className='row background-13'>
                <div className='w-96'>
                <div className='d-flex progress-bar'></div>
                <div className='d-flex position-relative'>
                        <img src={progressCircle} className='progressCircle' style={progressStyle}/>
                        {size=='desktop'&&<span className={'hover-plane'} style={count>50?right:left}>{count<50&& <img src={leftDownArrow}/>}<span className='p-1'>Your cybersecurity score {count}</span>{count>=50&& <img src={rightDownArrow}/>}</span>}
                </div>
                <div className='d-flex justify-content-between font-white p-0 pt-1'>
                    <div>0</div><div>100</div>
                </div>
                </div>
                <div className='d-flex w-2'>
                    <img src={sectionsOpen ? upArrow : downArrow} onClick={showSections} />
                </div>
                {sectionsOpen && 
                <div className='col-12 mt-4'>
                    {size == 'desktop' && <div className='row'>
                            <div className='col-lg-3 col-md-12'>
                                <VerticleScroller name={'applications'} data={applications} statusPending={applicationsPending}/>
                            </div>
                            <div className='col-lg-3 col-md-12'>
                                <VerticleScroller name={'emails'} data={emailSec} statusPending={emailPending}/>
                            </div>
                            <div className='col-lg-3 col-md-12'>
                                <VerticleScroller name={'ssl'} data={ssl} statusPending={sslPending}/>
                            </div>
                            <div className='col-lg-3 col-md-12'>
                                <VerticleScroller name={'network'} data={network} statusPending={networkPending}/>
                            </div>
                            </div>
                    }
                    {size == 'mobile' && <div>
                        <Tabs
                        defaultActiveKey="applications"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        >
                        <Tab eventKey="applications" title="Applications">
                        <VerticleScroller name={'applications'} data={applications} statusPending={applicationsPending}/>
                        </Tab>
                        <Tab eventKey="emails" title="Emails">
                        <VerticleScroller name={'emails'} data={emailSec} statusPending={emailPending}/>
                        </Tab>
                        <Tab eventKey="ssl" title="SSL">
                        <VerticleScroller name={'ssl'} data={ssl} statusPending={sslPending}/>
                        </Tab>
                        <Tab eventKey="network" title="Network">
                        <VerticleScroller name={'network'} data={network} statusPending={networkPending}/>
                        </Tab>
                        </Tabs>
                    </div>                        
                    }
                </div>
                }
                {size == 'mobile' && <div className='col-12 text-center'>
                    <p><img src={sectionsOpen ? dropup : dropdown} onClick={showSections} /></p>
                </div>}
            </div>
            }
            {d?.scan_status == 'PENDING' && 
            <div className='col-12 text-amber text-center'>
                <p className='mb-0'>We are calculating your score, generally it might take 2Min.</p>
                <div className='loader col-12'>
                    <div className='loader-mask'></div>
                </div>
            </div>
            }
        </>
    );
    })

export default CyberSecurity;