import { Outlet, Navigate } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import HeaderComponent from "./components/header/HeaderComponent";
import React, { useEffect, useState } from 'react';
import withRouter from "./withRouter";
import Intercom from '@intercom/messenger-js-sdk';

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  useEffect(()=>{
  setUser(JSON.parse(localStorage.getItem('user')));
  },[])
  Intercom({
    app_id: 'wlpu0zit',
    user_id: user.id, 
    name: user.name, 
    email: user.email,
    created_at: new Date(),
  });

  return (
    <div className="App">
      <HeaderComponent/>
      {user ? (
        <Outlet />
            ) : (
        <Navigate to="/login" />
            )}
    </div>
  );
}

export default withRouter(App);
