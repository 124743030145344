import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLeakedStatistics, getVendor, getVendorFromClearBit, getVendors } from '../../api/getDetails';
import { createVendor, getBrandScoreDataForMultiple, reinitiate } from '../../api/postDetails';
import downArrow from '../../assets/images/down_arrow.svg';
import refresh_icon from '../../assets/images/refresh.png';
import upArrow from '../../assets/images/up_arrow.svg';
import PagenationComponent from '../../components/pagination/pagination';
import RecorderCount from '../../components/recordcount/recordcount.component';
import VerticleScroller from '../../components/verticle-scroller/verticle-scroller';
import LoaderComponents from './../../components/loader/loader';
import './../../components/thirdParty/thirdparty.css';
import store from './../../redux/store';
import './vendor.css';

const Vendors = memo(function Vendors({vendors, saveVendors}){

  const [loading, setLoading] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
  const [expandedCount, setExpandedCount] = useState(0);
  const [showDomainsPopUp, setShowDomainsPopUp] = useState(false);
  const [domain, setDomain] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [logo, setLogo] = useState('');
  const [file, setFile] = useState(null);
  const [vendorResultList, setVendorResultList] = useState([]);
  const fileInput = useRef();
  const [vendorsCount, setVendorsCount] = useState(0);
  const width = window.innerWidth;
  const size = width<768?'mobile':'desktop';
  const [domainInValid, setDomainInValid] = useState(false);
  const [nameInValid, setNameInValid] = useState(false);
  const [urlInValid, setUrlInValid] = useState(false);
  const [descriptionInValid, setDescriptionInValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
      loadFiles();
      saveVendors(vendors);
  },[]);

  const loadFiles = useCallback(() => {
    // if(!searchString) {
        // navigate('/main/leakeddetails');
        // return;
    // }
    setLoading(true);
    let url = 'api/vendor/latest-vendors?page='+currentPage+'&pageSize=10';
    getVendors(url).then((response) => { 
      const loadedFiles = response.data.vendors;
      setFilesCount(response.data.totalVendors);
      saveVendors(loadedFiles);
      fetchScore(response.data.vendorList);
      setLoading(false);
    }).catch((error) => {
      saveVendors([]);
      console.log(error);
      setLoading(false);
    });
  }, [currentPage])

  function create(currentPageNUmber) {
    setLoading(true);
    if(!validateForm()){
        return;
    }
    const formData = new FormData();
    formData.append('name', name);
    formData.append('url', url);
    formData.append('description', description);
    formData.append('domain', domain);
    if(file){
    formData.append('file', file);
    } else {
    formData.append('logo', logo);
    }
    createVendor('api/vendor/savevendor', formData).then((response) => { 
      setLoading(false);
      setShowDomainsPopUp(false);
      if(response.data.vendor){
        const leaks = store.getState()['vendor']['vendors'];
        leaks.push(response.data.vendor);
        saveVendors([...leaks]);
        fetchScore(response.data.vendor.domain, leaks.length);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  function validateForm(){
    let formValid = true;
    if(!validateDomain()){
        setDomainInValid(true);
        formValid = false;
    } else {
        setDomainInValid(false);
    }
    if(!name || name.length<4){
        setNameInValid(true);
        formValid = false;
    } else {
        setNameInValid(false);
    }
    if(!description || description.length<10){
        setDescriptionInValid(true);
        formValid = false;
    } else {
        setDescriptionInValid(false);
    }
    if(!url){
        setUrlInValid(true);
        formValid = false;
    } else {
        setUrlInValid(false);
    }
    return formValid;
  }

  const validateDomain = useCallback(() => {
    return /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/.test(domain)
  }, [domain])

  function pageChanged(pageEvent){
    setCurrentPage(pageEvent.currentPage);
    setTimeout(()=>{
      loadFiles();
    })
  }

  const handleClose = useCallback(() => {
    setShowDomainsPopUp(false);
  }, []);

  const openPopUp = useCallback( () =>{
    setShowDomainsPopUp(true);
    setDomain('');
    setDescription('');
    setUrl('');
    setName('');
    setLogo('');
    setFile(null);
  }, [])

  const fetchDomainDetails = useCallback(() => {
    if(!domain)return;
    if(!validateDomain()){
        setDomainInValid(true);
        return;
    } else {
        setDomainInValid(false);
    }
    setLoading(true);
    getVendor('api/vendor/isVendorAssociated?domain='+domain).then((response) => { 
        if(response.data){
            setUrl(response.data.url);
            setName(response.data.name);
            setDescription(response.data.description);
            setLogo(response.data.logo)
        } else {
            getVendorFromExternal();
        }
        setLoading(false);
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [domain]);

  const getVendorFromExternal = useCallback(() => {
    setVendorResultList([]);
    setLoading(true);
    getVendorFromClearBit(domain).then(async(response)=>{
        if(JSON.parse(response.data.contents).length>0){
            setVendorResultList(JSON.parse(response.data.contents));
            setTimeout(()=>{
                JSON.parse(response.data.contents).map((vendor)=>{
                    if(vendor.domain == domain){
                        setLogo(vendor.logo);
                        setName(vendor.name);
                    }
                })
            }, 100)
    
        }
    })
  }, [domain])
  

  const openFileOpener = useCallback(() => {
    fileInput.current.click();
  }, [])

  const handleFile = useCallback((e) => {
    setLogo(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  }, [])

  function fetchScore(domains){
    getBrandScoreDataForMultiple(domains).then((response) => {
      const scoreObjs = response.data;
      scoreObjs.map(scre => {
        const score = {loading:false};
        if(scre?.scan_status == 'COMPLETED') {
            score.data = scre;
            const leaks = store.getState()['vendor']['vendors'];
            leaks.map(leak => {
              if(leak.domain == scre.domain) {
                leak.score = score;
              }
            })
            saveVendors([...leaks]);
        } else if(scre?.scan_status == 'PENDING'){
            const score = {loading:true};
            const leaks = store.getState()['vendor']['vendors'];
            const difference_In_Time = new Date().getTime() - new Date(scre?.scan_started_date).getTime();
            const difference_In_Days = Math.round(difference_In_Time / (1000 * 3600 * 24));
            score.showRefresh = difference_In_Days>0;
            leaks.map(leak => {
              if(leak.domain == scre.domain) {
                leak.score = score;
              }
            })
            saveVendors([...leaks]);
        }
        setLoading(false);
      })
        
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
}

function fetchStatistics(domain, index) {
  getLeakedStatistics('api/compromiseduser/statistics', domain).then((response) => { 
    const leaks = store.getState()['vendor']['vendors'];
    leaks[index]["thirdPartyCount"] = response.data.thirdPartyCredentialsCount;
    leaks[index]["corporateCount"] = response.data.employeesCount;
    leaks[index]["usersCount"] = response.data.usersCount;
    leaks[index]["statisticsLoaded"] = true;
    saveVendors([...leaks]);
  });
}

const getComputedStyle = useCallback((count) => {
const progressStyle = {
    width: count+'%',
    background: count>66?'#32D74B':count>33?'ffbf00':'EF1650'
}
return progressStyle;
}, [])

const updateEnabled = useCallback((item, ind) => {
  vendors.map((obj, index) => {
    if(index == ind) {
      if(!!!item?.enabledExpanded && !!!item?.statisticsLoaded) {
        fetchStatistics(item.domain, ind);
      }
      obj.enabledExpanded=!item.enabledExpanded;
      setVendorsCount(vendorsCount+1);
    } else {
      obj.enabledExpanded=false;
      setVendorsCount(vendorsCount+1);
    }
  })
}, [vendors])

const refresh = useCallback((domain, index) => {
    reinitiate(domain).then((response) => {
        const vendors = vendors;
        vendors[index].score.showRefresh = false;
        saveVendors(...vendors);
    }).catch((error)=>{
        console.log(error);
    })
}, [vendors])

return(
    <div>
        <div className='row'>
        <div className='col-12'>
      </div>
        <div className='col-12 mt-4 px-4'>
            <div className='d-flex justify-content-end'>
            <Button className='mx-2 create-btn' onClick={openPopUp}>
                Create new Vendor </Button>
            </div>
        <div className='files-table position-relative'>
          {loading ? <LoaderComponents/>:
          <Table >
            <thead >
              <tr >
                <th className='px-2 w-5'>Logo</th>
                <th className="px-2 w-10">Vendor</th>
                <th className="px-2 w-10 hide-in-mobile">Domain</th>
                <th className='px-2 w-10'>Score</th>
                <th className="px-3 w-50 hide-in-mobile">Description</th>
                <th className="px-2 w-5"></th>
              </tr>
            </thead>
            <tbody className='mt-2'>
              {vendors && vendors.map((item, index) => (
                <>
                  <tr key={index} className={item.enabledExpanded ? 'tr-highlight' : ''}>
                    <td className={"px-2"}><img src={item.logo} width={'24px'}/></td>
                    <td className="px-2"><p className="mb-0">{item?.domain}</p></td>
                    <td className="px-2 hide-in-mobile"><p className="mb-0">{item?.name}</p></td>
                    <td className='px-2'>     
                    <div className='row'>
            <div className='col-12'>
            {item?.score?.loading && <div className='position-relative'>Preparing data...{item?.score?.showRefresh&&<span className='mx-2' role='button' onClick={()=>refresh(item.domain, index)}><img width={"12px"} src={refresh_icon}/></span>}</div>}
            {item?.score?.data?.scan_status=='COMPLETED' &&<><div className='d-flex w-100 mb-1 justify-content-between'>
                {/* <div className='score-text D'>
                    D+
                </div> */}
                <div className='marks-text'>
                {item?.score?.data?.passed}/<span>{item?.score?.data?.total}</span>
                </div>
                <div >
                    {/* <span className='grow-text'><img src={arrowup}/>2</span> */}
                </div>
            </div>
            <div className='progress-bar-wrapper'>
            <div className='progress-score-bar-sub' style={getComputedStyle(item?.score?.data?.score)}></div>
            </div></>}
            </div>
                    </div>
                    </td>
                    <td className="px-3 hide-in-mobile">
                      <div className='row justify-content-between'>
                        <p className={'mb-0 '+(item.enabledExpanded ? 'col-6' : 'col-12')}>{item?.description}</p>
                        {item.enabledExpanded && <div className='col-6 d-flex'>
                          <div className="mx-1 filter-checkbox position-relative">
                            <div className='d-flex color-gray'>{/* <img className='mx-1' src={thirdParty} width={20} height={20}/> */}Third-party (<RecorderCount recordCount={item?.thirdPartyCount}/>)</div>
                          </div>
                          <div className="mx-1 filter-checkbox position-relative">
                            <div className='d-flex color-gray'>{/* <img className='mx-1' src={corporate} width={20} height={20}/> */}Corporate (<RecorderCount recordCount={item?.corporateCount}/>)</div>
                          </div>
                          <div className="mx-1 filter-checkbox position-relative">
                            <div className='d-flex color-gray'>{/* <img className='mx-1' src={users} width={20} height={20}/> */}Users (<RecorderCount recordCount={item?.usersCount}/>)</div>
                          </div>
                        </div>}
                      </div>
                    </td>
                    <td>
                    <img src={item.enabledExpanded ? upArrow : downArrow} height="25" width="25" className="cursor-pointer" onClick={() => updateEnabled(item, index)} alt="logo" />
                    </td>
                  </tr>
                  {item.enabledExpanded &&
                <tr key={'child'+index} className={item.enabledExpanded ? 'tr-highlight' : ''}>
                  <td colSpan="6">
                    <div  className='py-2 px-4 col-12'>
                      <div className={'col-12 '+(size == 'mobile' ? '' : 'mt-4')}>
                        {size == 'desktop' && 
                          <div className='row'>
                            <div className='col-lg-3 col-md-12'>
                                {item.score?.data?.scanTypes[0] &&
                                <VerticleScroller name={item.score?.data?.scanTypes[0].type.replace('_', ' ')} data={item.score?.data?.scanTypes[0].checks} statusPending={item.score?.data?.scanTypes[0].scan_status}/>
                                }
                            </div>
                            <div className='col-lg-3 col-md-12'>
                            {item.score?.data?.scanTypes[1] &&
                                <VerticleScroller name={item.score?.data?.scanTypes[1].type.replace('_', ' ')} data={item.score?.data?.scanTypes[1].checks} statusPending={item.score?.data?.scanTypes[1].scan_status}/>
                            }
                            </div>
                            <div className='col-lg-3 col-md-12'>
                            {item.score?.data?.scanTypes[2] &&
                                <VerticleScroller name={item.score?.data?.scanTypes[2].type.replace('_', ' ')} data={item.score?.data?.scanTypes[2].checks} statusPending={item.score?.data?.scanTypes[2].scan_status}/>
                            }
                            </div>
                            <div className='col-lg-3 col-md-12'>
                            {item.score?.data?.scanTypes[3] &&
                                <VerticleScroller name={item.score?.data?.scanTypes[3].type.replace('_', ' ')} data={item.score?.data?.scanTypes[3].checks} statusPending={item.score?.data?.scanTypes[3].scan_status}/>
                            }
                            </div>
                          </div>
                        }
                        {size == 'mobile' && 
                              <>
                            <div className='col-12 d-flex justify-content-between mb-2'>
                              <div className="m-1 col-3 filter-checkbox position-relative text-center">
                                <div className='d-flex color-gray'>{/* <img className='mx-1' src={thirdParty} width={20} height={20}/> */}Third-party (<RecorderCount recordCount={item?.thirdPartyCount}/>)</div>
                              </div>
                              <div className="m-1 filter-checkbox position-relative text-center">
                                <div className='d-flex color-gray'>{/* <img className='mx-1' src={corporate} width={20} height={20}/> */}Corporate (<RecorderCount recordCount={item?.corporateCount}/>)</div>
                              </div>
                              <div className="m-1 filter-checkbox position-relative text-center">
                                <div className='d-flex color-gray'>{/* <img className='mx-1' src={users} width={20} height={20}/> */}Users (<RecorderCount recordCount={item?.usersCount}/>)</div>
                              </div>
                            </div>
                            <div>
                            <Tabs
                            defaultActiveKey="applications"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            >
                            <Tab eventKey="applications" title="Applications">
                            {item.score?.data?.scanTypes[0] &&
                            <VerticleScroller name={item.score?.data?.scanTypes[0].type.replace('_', ' ')} data={item.score?.data?.scanTypes[0].checks} statusPending={item.score?.data?.scanTypes[0].scan_status}/>
                            }
                            </Tab>
                            <Tab eventKey="emails" title="Emails">
                            {item.score?.data?.scanTypes[1] &&
                            <VerticleScroller name={item.score?.data?.scanTypes[1].type.replace('_', ' ')} data={item.score?.data?.scanTypes[1].checks} statusPending={item.score?.data?.scanTypes[0].scan_status}/>
                            }
                            </Tab>
                            <Tab eventKey="ssl" title="SSL">
                            {item.score?.data?.scanTypes[2] &&
                            <VerticleScroller name={item.score?.data?.scanTypes[2].type.replace('_', ' ')} data={item.score?.data?.scanTypes[2].checks} statusPending={item.score?.data?.scanTypes[0].scan_status}/>
                            }
                            </Tab>
                            <Tab eventKey="network" title="Network">
                            {item.score?.data?.scanTypes[3] &&
                            <VerticleScroller name={item.score?.data?.scanTypes[3].type.replace('_', ' ')} data={item.score?.data?.scanTypes[3].checks} statusPending={item.score?.data?.scanTypes[0].scan_status}/>
                            }
                            </Tab>
                            </Tabs>
                        </div> 
                        </>                      
                        }
                      </div>
                    </div>
                  </td>
                </tr>
                }
                </>
              ))}
            </tbody>
          </Table>}
          {vendors?.length == 0 && 
          <div className='text-white mt-4 text-center'>
            <p>No vendors added for this domain.</p>
          </div>}
        </div>
        {filesCount > 0 && 
        <div className="pt-2"><PagenationComponent count={filesCount} pageSize={10} currentPage={currentPage} pageChange={(event)=>{
          pageChanged(event);
        }}/></div>}
      </div>
        </div>
        <Modal show={showDomainsPopUp} className='domains-modal' onHide={handleClose}>
        <Modal.Header closeButton>
          <div className='col-12 export-header d-flex justify-content-between'>
            <div className="modal-title h4">Add Vendor</div>
            <Button onClick={handleClose}>X</Button>
          </div>
        </Modal.Header>
        <Modal.Body>
            <div>
        <div>
            <Form.Control size="lg" className='mx-2' type="text" value={domain} onBlur={fetchDomainDetails} onChange={(e)=>{setDomain(e.target.value)}} placeholder="Enter Vendor domain" />
            {domainInValid && (<div className='error-text'>Domain is invalid!</div>)}
        </div>
        <br/>
        <div>
            <Form.Control size="lg" className='mx-2' type="text" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Enter Vendor name" />
            {nameInValid && (<div className='error-text'>Name is required and atleast 4 characters.</div>)}
        </div>
        <br/>
        <div>
            <Form.Control size="lg" className='mx-2' type="text" value={description} onChange={(e)=>{setDescription(e.target.value)}} placeholder="Enter Vendor description" />
            {descriptionInValid && (<div className='error-text'>Description is required and atleast 10 characters</div>)}
        </div>
        <br/>
        <div>
            <Form.Control size="lg" className='mx-2' type="text" value={url} onChange={(e)=>{setUrl(e.target.value)}} placeholder="Enter Vendor url" />
            {urlInValid && (<div className='error-text'>Url is invalid</div>)}
        </div>
        <br/>
        <div>
        <div>Logo</div>
        {logo && (<div className='logo'>
        <img src={logo} width={'48px'} height={'48px'}/>
        </div>)}
        {!logo && (<div>
            <input type='file' accept='.png,.svg,.jpg,.jpeg' className='d-none' onChange={handleFile} ref={fileInput}/>
            <div className='filedroper' onClick={openFileOpener}>
                Select file
                </div>
        </div>)}
        </div>
        <br/>
        <div>
        <Button onClick={create}>Save Vendor</Button>
        </div>
        </div>
    </Modal.Body>
      </Modal>
    </div>
)
})
function mapStateToProps(state) {
    return {
        vendors:state.vendor.vendors
    }
}
const mapDispatchToProps = (dispatch) => ({
    saveVendors: (payload) => dispatch({ type: "vendors", payload: payload }),
})
export default connect(mapStateToProps, mapDispatchToProps)(Vendors);