import Modal from 'react-bootstrap/Modal';
import './domain.css';
import { useNavigate } from "react-router-dom";
import arrows from "../../assets/images/discover_arrows.svg";
import { Button } from 'react-bootstrap';
import { useState, useEffect, memo, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { saveDomains } from '../../api/postDetails';
import { getSubscription, getDomains } from '../../api/getDetails';
import { useToast } from '../../Toast';
import eyeIcon from "../../assets/images/eye_show.svg";

const Domains = memo(function Domains(props) {

  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [domains, setDomains] = useState([]);
  const [userDomains, setUserDomains] = useState([]);
  const [domainsLength, setDomainsLength] = useState(0);
  const [showDomainsPopUp, setShowDomainsPopUp] = useState(false);
  const toast = useToast();
  // const [showAddButtonText, setShowAddButtonText] = useState(false);

  useEffect(()=>{
    if(user) {
      getSubscription('api/payment/retrive', user.id).then((response) => {
        domains.length = 0;
        for(let i=0;i<response.data.noOfDomains;i++){
          domains.push('');
        }
        setDomains([...domains]);
        getDomainValues();
      }).catch((error) => {
      }
      )
    }
  },[domainsLength])

  const showToast = useCallback((text) => {
    toast.open(text);
  }, [])

  const getDomainValues = useCallback(() => {
    getDomains('api/user/get-domains', user.id).then((response) => {
      const userDomains = response.data.domains;
      setUserDomains(userDomains);
      const d = [...domains]
      d.map((item, index) => {
        if(userDomains[index]) {
          d[index] = userDomains[index];
        }
      })
      setDomains(d);
      // setDomainsLength(domains.length);
    }).catch((error) => {

    })
  }, [user, domains])

  const navigateToPaymet = useCallback(() => {
    const userDomains = [];
    domains.map(dom => {
        if(dom !== '') {
            userDomains.push(dom);
        }
    })
    if(userDomains.length > 0) {
      saveDomains('api/user/save-domains', {userId: user.id, domain: userDomains}).then((response) => {
          // navigate('/main/search');
          handleClose();
          props.callBack();
      })
    } else {
      showToast('Please enter atleast one domain');
    }
  }, [domains, user])

  const handleDomainChange = useCallback((event, index) => {
    const data = [...domains];
    data[index] = event.target.value;
    setDomains(data);
  }, [domains])

  const handleNewDomain = useCallback((event) => {
    // setNewDomain(event.target.value);
  }, [])

  const addOnDomain = useCallback(() => {
    // if(newDomain !== '') {
        const data = [...domains];
        // data.push(newDomain);
        data.push('');
        setDomains(data);
        setDomainsLength(domains.length);
    // }
  }, [domains])

  const showDomains = useCallback(() => {
    setShowDomainsPopUp(true);
  }, [])

  const handleClose = useCallback(() => {
    setShowDomainsPopUp(false);
  }, [])

  return (
    <>
    {/* <Button className='mx-2' variant="secondary" onMouseEnter={() => setShowAddButtonText(true)} onMouseLeave={() => setShowAddButtonText(false)} onClick={showDomains}>+<span className={showAddButtonText ? 'mx-2 font-12' : ''}>{showAddButtonText ? 'Add/update domains' : ''}</span></Button> */}
    <Button className='mx-2' variant="secondary" onClick={showDomains}><img height="18" width="18" src={eyeIcon}/></Button>
    <Modal show={showDomainsPopUp} className='domains-modal' onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Add your domains to monitor</Modal.Title> */}
          <div className='col-12 export-header d-flex justify-content-between'>
            <div className="modal-title h4">Add/Update domains</div>
            <Button onClick={handleClose}>X</Button>
          </div>
          <div className='col-12'>
            <p className='mb-0 sub-title'>You can add or update domains/mobileapk url according to your need</p>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className='domains'>
        <br/>
        <div className='col-12 d-flex'>
            <div className='col-12 mx-2 domains-card'>
            <div className='domains-block'>
            {domains.map((item, index) => (
                <div className='col-12 d-flex my-4 align-items-center' key={index}>
                    <Form.Control size="lg" disabled="true" className='mx-2' id={"domain_"+index} type="text" onChange={(event) => handleDomainChange(event, index)} value={item} placeholder="Enter domain here" />
                </div>
            ))}
            </div>
            {/* <hr/> */}
            <div className='col-12 py-2 d-flex add-on'>
                <div className='col-6'>
                {/* <Form.Control size="lg" className='mx-2' type="text" onChange={handleNewDomain} placeholder="Enter domain here" /> */}
                </div>
                {/* <div className='col-5 mx-2 px-2'>
                <Button className="cursor-pointer text-white add-domain-btn mb-0" onClick={addOnDomain}>Add another domain (₹150)</Button>
                </div> */}
            </div>
            </div>
        </div>
        <br/>
        {/* <div className='col-12 text-center continue-btn'>
            <Button className="cursor-pointer mb-0" onClick={navigateToPaymet}>Continue<img src={arrows} height="15" width="15" className='mx-2'></img></Button>
        </div> */}
    </div></Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
      </>
  );
})

export default Domains;
