import './cancellationpolicy.css'

function CancellationPolicy() {
return (
    <div className='back-black'>
    <h1 className='font-52'>Cancellation Policy</h1>

    <p>Effective Date: 18th April 2024</p>
   <p>Thank you for choosing infoleak.io ("we," "us," or "our"). This Cancellation Policy outlines the terms and conditions governing cancellations of our services or products. By using our services or products, you agree to the terms of this Cancellation Policy. If you do not agree with the terms of this Cancellation Policy, please do not use our services or products.</p>

  <h2>1. Cancellation of Services</h2>
  <p>The following cancellation terms apply to our services:</p>
   <ul>
       <li><strong>Subscription Services</strong>: If you are subscribed to a recurring service or subscription plan, you may cancel your subscription at any time by following the cancellation instructions provided on our website or contacting our customer support team.</li>
       <li><strong>One-Time Services</strong>: If you have purchased a one-time service or product, you may cancel your order before the service or product is delivered or provided. Once the service or product has been delivered or provided, it is non-refundable.</li>
   </ul>

   <h2>2. Refunds</h2>
   <p>The following refund terms apply to our services:</p>
   <ul>
        <li><strong>Subscription Services</strong>: If you cancel a subscription before the end of the current billing cycle, you will not be entitled to a refund for any unused portion of the subscription period. Your subscription will remain active until the end of the current billing cycle, and you will not be charged for subsequent billing cycles.</li>
       <li><strong>One-Time Services</strong>: Refunds for one-time services or products are issued at our discretion and may be subject to a processing fee or other conditions. Requests for refunds must be submitted in writing to our customer support team.</li>
   </ul>

   <h2>3. Changes to Services or Products</h2>
  <p>We reserve the right to modify, suspend, or discontinue any aspect of our services or products at any time without prior notice. In the event of such changes, you may be entitled to a prorated refund for any unused portion of your subscription or service.</p>

  <h2>4. Contact Us</h2>
  <p>If you have any questions or concerns about this Cancellation Policy or wish to cancel your subscription or service, please contact us at <a href="mailto:noreply@infoleak.io">noreply@infoleak.io</a>.</p>

  <p>Last Updated: 15th April 2024</p>
</div>
)
}

export default CancellationPolicy