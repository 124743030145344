
import './unsubscribe.css';
import { useNavigate } from "react-router-dom";
import {cancelSubscribtion} from './../../api/postDetails';
import { connect } from 'react-redux';
import { memo, useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const Unsubscribe = memo(function Unsubscribe({ paymentMethod, subscriptionType }){

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const unsubscribeToPayment = useCallback(() => {
        cancelSubscribtion().then((response) => {
            setShowModal(true);
            navigate('/');
           }).catch((error) => {

           })
    }, [])

    const handleClose = useCallback(() => {
        setShowModal(false);
    }, [])

    const gohome = useCallback(() => {
        navigate('/');
    }, [])

return(
    <>
    <div className='full-width'>
        <div className='center-alligned'>
            <h1>You have an active subscription for {subscriptionType} Plan, are you sure you want to unsubscribe?</h1>
            <div className='d-flex alignitems-center justify-content-center'><button className='home-button' onClick={unsubscribeToPayment}>Unsubscribe</button>
            <button className='home-button' onClick={gohome}>Unsubscribe</button></div>
        </div>
    </div>

    <Modal show={showModal} onHide={handleClose}>
    <Modal.Header>
    <Modal.Title>Success</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className='unsubscribe-section'>
        <p>Subscription Cancelled Successfully.</p>
    </div>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
        Close
    </Button>
    </Modal.Footer>
    </Modal>
    </>
)
})

function mapStateToProps(state) {
    return {
      paymentMethod: state.user.paymentMethod,
      subscriptionType: state.user.subscriptionType
    }
  }

export default connect(mapStateToProps)(Unsubscribe);