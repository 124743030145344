import './thirdparty.css';
import arrowup from './../../assets/images/arrowup.png'
import { Link } from 'react-router-dom';
import rightArrow from '../../assets/images/right_arrow.svg';
import { getVendors } from '../../api/getDetails';
import { memo, useCallback, useEffect, useState } from 'react';
import { getBrandConsolidatedScoreData } from '../../api/postDetails';
import LoaderComponents from '../loader/loader';
import { connect } from "react-redux";
import store from './../../redux/store';

const Thirdparty = memo(function Thirdparty({vendors, saveVendors}){
    const [loading, setLoading] = useState(false);
    const [vendorsCount, setVendorsCount] = useState(0);
    const [vendorsDetails, setVendorsDetails] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))

    useEffect(()=>{
        loadFiles();
    },[])
    
    const loadFiles = useCallback(() => {
        setLoading(true);
        let url = 'api/vendor/latest-vendors';
        getVendors(url).then((response) => { 
        const loadedFiles = response.data.vendors;
        saveVendors(loadedFiles);
        setVendorsCount(loadedFiles.length)
        // loadedFiles.slice(0,3).map((v, i)=>{
            fetchScore(response.data.vendorList);
        // })
        setLoading(false);
        }).catch((error) => {
        saveVendors([]);
        setVendorsCount(0);
        console.log(error);
        setLoading(false);
        });
    }, [])

    const fetchScore = useCallback((domains) => {
        getBrandConsolidatedScoreData(domains).then((response) => {
            const score = {loading:false};
            response.data['avaragePassed'] = parseInt((response.data?.passed)/(response.data?.total/52));
            setVendorsDetails(response.data);
            // if(response?.data?.scan_status == 'COMPLETED') {
            //     score.data = response.data;
            //     const leaks = store.getState()['vendor']['vendors'];
            //     leaks[i].score = score;
            //     saveVendors([...leaks]);
            // } else if(response?.data?.scan_status == 'PENDING'){
            //     const score = {loading:true};
            //     const leaks = store.getState()['vendor']['vendors'];
            //     leaks[i].score = score;
            //     saveVendors([...leaks]);
            // }
            setLoading(false);
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }, [])

    const getComputedStyle = useCallback((count) => {
        const val = isNaN(count) ? 0 : count;
        const progressStyle = {
            width: val+'%',
            background: val>66?'#32D74B':(val>33?'ffbf00':'EF1650')
        }
        return progressStyle;
    }, [])

    return (<>
    {(vendorsDetails) &&
    <div className='row third-party'>
        <div  className={'col-9'}>
            <p className='text-white'>Vendors ({vendorsCount})</p>
        {vendorsCount>0&&<div className='row'>
            <div className='col-12'>
            <div className='d-flex w-100 mb-1 justify-content-between'>
                {!isNaN(vendorsDetails.avaragePassed) && <div className='marks-text'>
                    {vendorsDetails.avaragePassed}/<span>{52}</span>
                </div>}
                {isNaN(vendorsDetails.avaragePassed) && <div className='marks-text'>
                    Nodata available
                </div>}
                <div >
                </div>
            </div>
            <div className='progress-bar-wrapper'>
            <div className='third-party-progress-score-bar-sub' style={getComputedStyle((vendorsDetails?.passed/vendorsDetails?.total)*100)}></div>
            </div>
            </div>
        </div>}
        {vendorsCount==0&&<div className='row'>
        <div className='col-12'>
        <div className='marks-text'>
                    No vendors for this domain, please add vendors in third party risk.
                </div>
            </div></div>}
        </div>
        <div className={'viewmore-wrapper col-3'}>
        <Link to={'/main/vendors'} className='mb-0 mx-lg-2 cursor-pointer source-code-row'><p className='mb-0 mx-1 width-135px'>Third party risk</p> <img className='right-arrow' src={rightArrow} width="20" height="20" /></Link>
        </div>
    </div>
}
    </>)
})
function mapStateToProps(state) {
    return {
        vendors:state.vendor.vendors
    }
}
const mapDispatchToProps = (dispatch) => ({
    saveVendors: (payload) => dispatch({ type: "vendors", payload: payload }),
})
export default connect(mapStateToProps, mapDispatchToProps)(Thirdparty);