import './Payment.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import paymentSSL from "../../assets/images/payment-ssl.svg";
import { Button } from 'react-bootstrap';
import { useState, useEffect, memo, useCallback } from 'react';
import Form from 'react-bootstrap/Form';

const Payment = memo(function Payment() {

  const navigate = useNavigate();
  const location = useLocation();
  const [cost, setCost] = useState(999);
  const [selectedPlan, setSelectedPlan] = useState('Company');
  let { plan } = useParams();

    useEffect(()=>{
        if(plan === 'Company') {
            setCost(999);
            setSelectedPlan('Company');
        } else {
            setCost(2999);
            setSelectedPlan('Premium');
        }
    },[location])

    const navigateToSuccess = useCallback(() => {
        navigate('/main/success');
    }, [])

    return (
    <div className='payment'>
        <br/>
        <br/>
        <div className='col-12 d-flex'>
            <div className='col-2'></div>
            <div className='col-5 px-4'>
            <p className='mb-0 select-text'>Select payment method</p>
            <p className='mb-0 my-2 py-2 payment-method-text'>Credit Card</p>
            </div>
            <div className='col-3'></div>
        </div>
        <br/>
        <div className='col-12 d-flex'>
            <div className='col-2'></div>
            <div className='col-5 mx-2 payment-card'>
                <div className='col-12 d-flex'>
                    <div className='col-6 px-2'>
                        <label>Name on Card</label>
                        <Form.Control className='payment-field' type="text" placeholder='Name on Card'/>
                    </div>
                    <div className='col-6 px-2'>
                        <label>Card Number</label>
                        <Form.Control className='payment-field' type="text" placeholder='0000 0000 0000 0000'/>
                    </div>
                </div>
                <div className='col-12 d-flex mt-4'>
                    <div className='col-6 px-2'>
                        <label>Expiry Date</label>
                        <Form.Control size="sm" className='payment-field' type="text" placeholder='mm/yy'/>
                    </div>
                    <div className='col-6 px-2'>
                        <label>CVV</label>
                        <Form.Control size="sm" className='payment-field' type="text" placeholder='000'/>
                    </div>
                </div>
                <div className='col-12 mt-4 px-2'>
                    <label>Address</label>
                    <Form.Control size="lg" className='payment-field' type="text" placeholder='Address'/>
                </div>
                <div className='col-12 mt-4 px-2 d-flex'>
                    <input type='checkbox'/>
                    <p className='mb-0 mx-2 save-card-text'> Save this card securely</p>
                </div>
                <div className='col-12 d-flex mt-4 px-2'>
                    <div className='col-4'><Button className="cursor-pointer text-white pay-btn mb-0" onClick={navigateToSuccess}>Pay ₹{cost}</Button></div>
                    <div className='col-6'>
                        <img src={paymentSSL} width="100" height="35"/>
                        <p className='mb-0 ssl-text px-3'>Powered by SSL secure connection</p>
                    </div>
                </div>
            </div>
            <div className='col-3 mx-2 payment-cost-card'>
                <div className='payment-details'>
                <div className='col-12 company-text'>Payment Summary</div>
                <div className='col-12 d-flex align-items-center mt-4'>
                    <div className='col-8 payment-option'>{selectedPlan}</div>
                    <div className='col-4 payment-cost'>₹{cost}</div>
                </div>
                <br/>
                <br/>
                <br/>
                </div>
                <div className='col-12 d-flex mt-4'>
                    <div className='col-8'><p className='mb-0 grand-total'>Grand Total</p></div>
                    <div className='col-4 total-cost'>₹{cost}</div>
                </div>
            </div>
        </div>
        <br/>
    </div>
    );
})

export default Payment;
