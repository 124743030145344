import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import './manageUsers.css';
import PagenationComponent from '../../components/pagination/pagination';
import LoaderComponents from '../../components/loader/loader';
import UsersPaymentForm from '../usersPaymentForm/usersPaymentform';
import { getUsers } from '../../api/getDetails';
import { disableUserPayment } from '../../api/postDetails';

const ManageUsers = memo(function ManageUsers() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [users, setUsers] = useState([{}]);
    const [count, setUsersCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [dataLoading, setDataLoading] = useState(false);
    const [showPlansPopUp, setShowPlansPopUp] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [searchText, setSearchText] = useState('');
    const pageSize = 20;

    useEffect(()=>{
      if(user && user.role=='ADMIN') {
        getUsersList(0);
      }
    },[]);

    const handleClose = useCallback(() => {
      setShowPlansPopUp(false);
    }, [])

    const openPlansPopup = useCallback((item, index) => {
      setSelectedUser(item);
      setShowPlansPopUp(true);
    }, [])

    const disablePayment = useCallback(() => {
      disableUserPayment({userId:selectedUser._id}).then((response) => {
        setShowPlansPopUp(false);
        getUsersList(0);
      }).catch((error) => {
        console.log(error);
      })
    }, [selectedUser])

    const paymentSaved = useCallback(() => {
      setShowPlansPopUp(false);
      getUsersList(0);
    }, [])

    const getUsersList = useCallback((page) => {
      setDataLoading(true);
      getUsers(page, pageSize, searchText?searchText:null).then((response) => {
        setUsers(response.data.users);
        setUsersCount(response.data.totalUsers);
        setDataLoading(false);
      }).catch((error) => {
        console.log(error);
        setDataLoading(false);
      })
    }, [searchText, pageSize])

    const pageChanged = useCallback((event) => {
      setCurrentPage(event.currentPage);
      setTimeout(()=>{
      getUsersList(event.currentPage);
      })
    }, [])

    const search = useCallback((e) => {
      if(e.keyCode==13){
        setCurrentPage(0);
        getUsersList(0);
      }
    }, [])
    
  return (
    <div className='payment-admin'>
    {(user && user.role=='ADMIN') ? (
    <div className='white-font m-4 invite-block'>
        <div className="container">
        <div className='d-flex col-12 mt-2 justify-content-end'>
          <Form.Control className='w-25 mobile' required type="text" placeholder="Search By Email" onChange={(e)=>setSearchText(e.target.value)} onKeyUp={search} />
        </div>
        <div className="col-12 py-4">
        {(users?.length === 0 && !dataLoading) && <p className="text-center text-white">No data found</p>}
        {(users?.length > 0) &&
          <div className='height-75 leaked-table position-relative'>
            {dataLoading ? <LoaderComponents/>:
          <Table >
            <thead >
              <tr >
                <th className="w-25 px-2">User Name</th>
                <th className="w-30 px-2">Email</th>
                <th className="w-30 px-2">Role</th>
                <th className="w-25">Subscription Status</th>
                <th className="w-20"></th>
              </tr>
            </thead>
            <tbody className='mt-2'>
              {users.map((item, index) => (
                <>
                  <tr key={index}>
                    <td className="w-25 px-2"><p className="mb-0">{item?.name ? item?.name : 'NA'}</p></td>
                    <td className="w-30 px-2"><p className="mb-0">{item?.username ? item?.username : 'NA'}</p></td>
                    <td className="w-30 px-2"><p className="mb-0">{item?.role ? item?.role : 'NA'}</p></td>
                    <td className='w-25 px-2'><p className="mb-0">{item.payment && item.payment[0]?.payment_status == 1 ? 'Active' :  'In Active'}</p></td>
                    <td className="w-20 text-center">
                      <Button className="cursor-pointer text-white continue-btn mb-0" onClick={() => openPlansPopup(item, index)}>Add/Update Payment</Button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>}
          </div>
        }
        {count>0 && 
        <div className="pt-3"><PagenationComponent count={count} pageSize={pageSize} currentPage={currentPage} pageChange={(event)=>{
        pageChanged(event);
        }}/></div>}
        <Modal show={showPlansPopUp} className='plans-modal' onHide={handleClose}>
          <Modal.Header>
            <div className='col-12 export-header d-flex justify-content-between'>
              <div className="modal-title h4">Add/Update Payments</div>
              <div className='d-flex'>
              {showPlansPopUp && selectedUser?.payment.length>0 && selectedUser?.payment[0]?.payment_status==1 && (<Button className='mx-2' onClick={disablePayment}>Disable Payment</Button>)}
              <Button onClick={handleClose}>X</Button>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <UsersPaymentForm callBack={()=> paymentSaved()} user={selectedUser}/>
          </Modal.Body>
        </Modal>
      </div>
        </div>
    </div>):(<div className='white-font w-100 invite-access d-grid justify-content-center align-items-center'><h1>You dont have to access this page.</h1></div>)}
    </div>
  );
})

export default ManageUsers;