import { useEffect } from 'react';
import './pagination.css';
import { Pagination } from 'react-bootstrap';

function PagenationComponent(props) {
    const totalPages = parseInt(props.count/props.pageSize);
    useEffect(() => {
    },[]);
    const pageChanged = (page) => {
        props.pageChange({currentPage:page});
    }
  return (
    <div className='w-100 d-flex justify-content-center leaked'>
<Pagination>
      <Pagination.First onClick={()=>pageChanged(0)} />
      <Pagination.Ellipsis />
        {props.currentPage-3 > 0 && props.currentPage>=(totalPages) && <Pagination.Item onClick={()=>pageChanged(props.currentPage-4)}>{props.currentPage-3}</Pagination.Item>}
        {props.currentPage-2 > 0 && props.currentPage>=(totalPages-1) && <Pagination.Item onClick={()=>pageChanged(props.currentPage-3)}>{props.currentPage-2}</Pagination.Item>}
        {props.currentPage-1 > 0 && <Pagination.Item onClick={()=>pageChanged(props.currentPage-2)}>{props.currentPage-1}</Pagination.Item>}
        {props.currentPage > 0 && <Pagination.Item onClick={()=>pageChanged(props.currentPage-1)}>{props.currentPage}</Pagination.Item>}
        <Pagination.Item active>{props.currentPage+1}</Pagination.Item>
        {props.currentPage+2 <= totalPages && <Pagination.Item onClick={()=>pageChanged(props.currentPage+1)}>{props.currentPage+2}</Pagination.Item>}
        {props.currentPage+3 <= totalPages && <Pagination.Item onClick={()=>pageChanged(props.currentPage+2)}>{props.currentPage+3}</Pagination.Item>}
        {props.currentPage+4 <= totalPages && props.currentPage<=1 && <Pagination.Item onClick={()=>pageChanged(props.currentPage+3)}>{props.currentPage+4}</Pagination.Item>}
        {props.currentPage+5 <= totalPages && props.currentPage<1 && <Pagination.Item onClick={()=>pageChanged(props.currentPage+4)}>{props.currentPage+5}</Pagination.Item>}
      <Pagination.Ellipsis />
      <Pagination.Last onClick={()=>pageChanged(totalPages)}/>
    </Pagination>
    </div>
  );
}

export default PagenationComponent;
