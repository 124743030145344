import React, { useEffect, useState, useRef, memo, useCallback } from 'react';
import { Table, Button, Form, Modal, InputGroup } from "react-bootstrap";
import { getDomains, getRequest, getSubscription, getLeakedStatistics, getDomainSuggestions } from '../api/getDetails';
import { notifyUser } from '../api/postDetails';
import downArrow from '../assets/images/down_arrow.svg';
import upArrow from '../assets/images/up_arrow.svg';
import rightArrow from '../assets/images/right_arrow.svg';
import PagenationComponent from '../components/pagination/pagination';
import './LeakedDetails.css';
import moment from 'moment';
import Domains from './../components/domains/domain';
import LoaderComponents from '../components/loader/loader';
import thirdParty from '../assets/images/third_party.svg';
import corporate from '../assets/images/corporate.svg';
import users from '../assets/images/user.svg';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import CyberSecurity from '../components/cyber-security/cyber-security';
import Thirdparty from '../components/thirdParty/thirdparty';
import store from './../redux/store';
import RecorderCount from '../components/recordcount/recordcount.component';
import GeneratedFilesComponent from '../components/exportLeakInfo/generatedFiles';
import { useToast } from '../Toast';
import notify from '../assets/images/notify.png';
import noNotify from '../assets/images/nonotification.png';
import NotifiModel from '../components/notifymodel/notifyModel';
import { useNavigate } from "react-router-dom";

const LeakedDetails = memo(function LeakedDetails({ user, 
  subscriptionStatus, 
  subscriptionType, 
  domains, 
  leakedDetailsData, 
  count, 
  currentPage, 
  thirdPartySelected, 
  employeesSelected, 
  usersSelected, 
  searchString,
  saveUser, savePayment, saveDomains, savePageChanged, saveData, saveFilters, saveString}) {

    const [searchTerm, setSearchTerm] = useState('');
    const [statsLoading, setStatsLoading] = useState(false);
    const [thirdPartyCount, setThirdPartyCount] = useState(0);
    const [corporateCount, setCorporateCount] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [placeholder, setPlaceholder] = useState('please enter domain');
    const [searchBy, setSearchBy] = useState('DOMAIN');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showAlertPopup, setShowAlertPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const [showSuggestionBox, setShowSuggestionBox] = useState(false);
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [validDomain, setValidDomain] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const domainRef = useRef();
    const toast = useToast();
    const navigate = useNavigate();
    const [selectedIssues, setSelectedIssues] = useState([]);
    const [showNotifyButton, setShowNotifyButton] = useState(false);
  useEffect(() => {
    const localUser = JSON.parse(window.localStorage.getItem('user'));
    saveUser(localUser);
    if(!localUser?.id) return;
    getSubscription('api/payment/retrive', localUser.id).then((response) => {
      if(response.data.subscriptionStatus === 'active' || response.data.subscriptionStatus === 'trialing') {
        savePayment(response);
      if(response.data.subscriptionType === 'ENTERPRISE') {
        getDomains('api/user/get-domains', localUser.id).then((domainResponse) => {
          if(domainResponse.data.domains.length>0){
            saveDomains(domainResponse);
            if(!!searchString && domainResponse.data.domains.indexOf(searchString)>-1){
              // domainRef.current.value = searchString;
              handleSelectedStion({}, {domain:searchString});
              } else {
                saveString(domainResponse.data.domains[0]);
                // domainRef.current.value = domainResponse.data.domains[0]
            setTimeout(()=>{
              getLeakedStats();
              pageChanged({currentPage:0});
            })
          }
          } else {
            showToast("You don't have any domains added to your subscription.");
          }
        }).catch(error => {
          console.log(error);
        })
      } else {
        if(!!searchString){
        domainRef.current.value = searchString;
        handleSelectedStion({}, {domain:searchString});
        } else {
        saveString(localUser?.username.split('@')[1]);
        }
        setTimeout(()=>{
        getData();
        })
      }
    } else {
      navigate('/');
    }
    }).catch(error => {
      console.log(error);
    getData();
    })
  }, []);

  const showToast = useCallback((text) => {
    toast.open(text);
  }, [])

  const getLeakedStats = useCallback(() => {
    setStatsLoading(true);
    const string = store.getState()['user']['string'];
    getLeakedStatistics('api/compromiseduser/statistics', string).then((response) => { 
      setStatsLoading(false);
      setThirdPartyCount(response.data.thirdPartyCredentialsCount);
      setCorporateCount(response.data.employeesCount);
      setUsersCount(response.data.usersCount);
    });
  }, [])

  const handleSearchBy = useCallback((e) => {
    const element = document.getElementById('leaked-search-input');
    if(element)element.value = '';
    const elementDomain = document.getElementById('leaked-search-input-domain');
    if(elementDomain)elementDomain.value = '';
    setValidDomain(false);
    setShowDownload(false);
    setShowSuggestionBox(false);
    setSearchSuggestions([]);
    setPlaceholder(e.target.value === 'DOMAIN'? 'please enter domain':e.target.value ==='ANDROID'?'please enter mobile apk url here':'please enter email');
    setSearchBy(e.target.value);
  }, [])

  const handlePopup = useCallback((showPopup, popupMsg) => {
    setShowErrorPopup(showPopup);
    setErrorMessage(popupMsg);
  }, [])

  const handleAlertPopup = useCallback((showPopup,) => {
    setShowAlertPopup(showPopup);
  }, [])

  function assignSearchValue (e) {
    if(e.keyCode === 13 && e?.target?.value?.trim() != '') {
      const searchVal = e.target.value;
      if(subscriptionType === 'ENTERPRISE') {
        if(searchBy=='EMAIL') {
          if(validateEmail(searchVal)) {
            if(checkEmailMatchingExistingDomains(searchVal)) {
              setShowDownload(true);
              loadSearchData(searchVal);
            } else {
              setShowDownload(false);
              const ext = searchVal.split('@')[1];
              handlePopup(true, "You don't have permission to access this domain '"+ext+"' please add '"+ext+"' to your subscription");
            }
          } else {
           handlePopup(true, 'Email you have entered is invalid.');
          }
        } else if(searchBy=='ANDROID') {
          if(/^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/.test(searchVal)) {
            setShowDownload(true);
            loadSearchData(searchVal);
          } else {
            setShowDownload(false);
           handlePopup(true, 'Domain you have entered is invalid.');
          }
        }
      } else if (subscriptionType != 'ENTERPRISE') {
        if(searchBy=='DOMAIN') {
          if(validateDomain(searchVal)) {
            setShowDownload(true);
            loadSearchData(searchVal);
          } else {
            setValidDomain(false);
            setShowDownload(false);
           handlePopup(true, 'Domain you have entered is invalid.');
          }
        } else if(searchBy=='EMAIL') {
          if(validateEmail(searchVal)) {
            setShowDownload(true);
            loadSearchData(searchVal);
          } else {
            setShowDownload(false);
           handlePopup(true, 'Email you have entered is invalid.');
          }
        } else {
          if(/^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/.test(searchVal)) {
            setShowDownload(true);
            loadSearchData(searchVal);
          } else {
            setShowDownload(false);
           handlePopup(true, 'Domain you have entered is invalid.');
          }
        }
      }
    }
  }

  const loadSearchData = useCallback((searchVal) => {
    saveString(searchVal);
    savePageChanged(0);
    setValidDomain(searchVal != domains?.toString() && searchVal != '' ? true : validDomain);
    setTimeout(()=>{
      getLeakedStats();
      pageChanged({currentPage:0});
    })
  }, [domains, validDomain])

  const validateEmail = useCallback((email) => {
    if (/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(email)) {
      return true;
    }
    return false;
  }, [])

  const validateDomain = useCallback((domain) => {
    const ext = domain.split('.').length > 1 ? domain.split('.')[domain.split('.').length - 1] : '';
    if(ext?.length < 2) return false;
    return true;
  }, [])

  const checkEmailMatchingExistingDomains = useCallback((email) => {
    const ext = email.split('@')[1];
    if(!ext) return false;
    return domains.indexOf(ext)>-1;
  }, [])

  const getData = useCallback(() => {
    setDataLoading(true);
    const string = store.getState()['user']['string']
    if(!!!string) return;
    getRequest('api/compromiseduser', string, currentPage, thirdPartySelected, employeesSelected, usersSelected).then((response) => { 
      response.data.records.map(obj => {
        obj.date_compromised = getCompromisedDate(obj.date_compromised);
      })
      savePageChanged(0);
      setDataLoading(false);
      saveData(response.data);
    });
  }, [currentPage, thirdPartySelected, employeesSelected, usersSelected])

  const updateEnabled = useCallback((item, ind) => {
    leakedDetailsData.map((obj, index) => {
      if(index == ind) {
        obj.enabledExpanded=!item.enabledExpanded;
      } else {
        obj.enabledExpanded=false;
      }
    })
    saveData({records: [...leakedDetailsData], numDocs: count});
  }, [leakedDetailsData, count])

  const sendAlert = useCallback((item) => {
    if(selectedIssues?.length > 0) {
      if(selectedIssues.indexOf(item.id) == -1) {
        let selectedIssuesList = selectedIssues;
        selectedIssuesList.push(item.id);
        setSelectedIssues([...selectedIssuesList]);
      }
    } else {
      setSelectedIssues([item.id]);
    }
    setShowAlertPopup(true);
    setShowNotifyButton(true);
  }, [selectedIssues])

  const notifyAll = useCallback(() => {
    setShowAlertPopup(true);
  }, [])

  const issueSelected = useCallback((event, item) => {
    if(event.target.checked){
      setShowNotifyButton(true);
      let selectedIssuesList = selectedIssues;
      if(selectedIssuesList.indexOf(item.id)==-1){
      selectedIssuesList.push(item.id);
      }
      setSelectedIssues([...selectedIssuesList]);
    } else {
      let selectedIssuesList = selectedIssues.filter(e => e != item.id);
      setSelectedIssues([...selectedIssuesList]);
      if(selectedIssuesList?.length == 0) {
        setShowNotifyButton(false);
      }
    }
  }, [selectedIssues])

  const sendNotification = useCallback((body) => {
      body['issueIds'] = selectedIssues;
      setDataLoading(true);
      notifyUser(body).then((response) => { 
        setDataLoading(false);
        setSelectedIssues([]);
        setShowNotifyButton(false);
      }).catch((error)=>{
        setDataLoading(false);
      })
      setShowAlertPopup(false);
  }, [selectedIssues])

  const closeAlertPopup = useCallback(() => {
    setShowAlertPopup(false);
  }, [])

  const handleThirdPartyFilter = useCallback((event) => {
    if(event.target.checked){
      saveFilters({thirdPartySelected:event.target.value == 'thirdparty', employeesSelected:event.target.value == 'corporate', usersSelected:event.target.value == 'users'});
    } else {
    saveFilters({thirdPartySelected:false, employeesSelected:false, usersSelected:false});
    }
    setTimeout(()=>{
      pageChanged({currentPage:0});
    })
  }, [])

  const restartSearch = useCallback((event) => {
    saveString(event.target.value);
    savePageChanged(0);
    setValidDomain(event.target.value != domains?.toString() && event.target.value != '' ? true : false);
    setShowDownload(true);
    setTimeout(()=>{
      getLeakedStats();
    pageChanged({currentPage:0});
    })
  }, [domains])

  const pageChanged = useCallback((pageEvent) => {
    const string = store.getState()['user']['string'];
    const isthirdPartySelected = store.getState()['user']['thirdPartySelected']
    const isemployeesSelected = store.getState()['user']['employeesSelected']
    const isusersSelected = store.getState()['user']['usersSelected']
    setDataLoading(true);
    getRequest('api/compromiseduser', string, pageEvent.currentPage, isthirdPartySelected, isemployeesSelected, isusersSelected, searchTerm).then((response) => { 
      response.data.records.map(obj => {
        obj.date_compromised = getCompromisedDate(obj.date_compromised);
      })
      savePageChanged(pageEvent.currentPage);
      setDataLoading(false);
      saveData(response.data);
    });
  }, [searchTerm])

  const getCompromisedDate = useCallback((date) => {
    if(!date){
      return 'N/A';
    }
    if((moment(new Date(), "YYYY-MM-DDTHH:mm:ss.SSSSZ").diff(moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ"), 'days')) >= 0) return moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format('DD MMM, yyyy');
    const new_date = moment(date, "YYYY-DD-MMTHH:mm:ss.SSSSZ").format('DD MMM, yyyy');
    return (moment(new Date(), "YYYY-DD-MMTHH:mm:ss.SSSSZ").diff(new_date, 'days')) >= 0 ? moment(new_date).format('DD MMM, yyyy'):'N/A';
  }, [])

  const domainSaved = useCallback(() => {
    getDomains('api/user/get-domains', user.id).then((domainResponse) => {
      if(domainResponse.data.domains.length>0){
        saveString(domainResponse.data.domains.toString());
        savePageChanged(0);
        setTimeout(()=>{
          getLeakedStats();
          pageChanged({currentPage:0});
        })
    }
    }).catch(error => {
      console.log(error);
    })
  }, [user])

  const domainSearchSuggestion = useCallback((e) => {
    const searchVal = e?.target?.value?.trim();
    if(e.keyCode === 13 && e?.target?.value?.trim() != '') {
      setShowSuggestionBox(false);
      setSearchSuggestions([]);
      if(validateDomain(searchVal)) {
        setShowDownload(true);
        loadSearchData(searchVal);
      } else {
        setShowDownload(false);
        if(searchBy == 'DOMAIN') {
          setValidDomain(false);
        }
       handlePopup(true, 'Domain you have entered is invalid.');
      }
    } else if(e.keyCode != 13 && e?.target?.value?.trim() != ''){
      if(searchVal.length >= 3) {
        getSearchSuggestions(searchVal);
      } else if(searchVal.length < 3) {
        setValidDomain(false);
        setShowSuggestionBox(false);
        setSearchSuggestions([]);
      }
    } else {
      setShowDownload(false);
      setShowSuggestionBox(false);
      setSearchSuggestions([]);
      setValidDomain(false);
    }
  }, [searchBy])

  const getSearchSuggestions = useCallback((searchVal) => {
    getDomainSuggestions(searchVal).then((response) => {
      setShowSuggestionBox(true);
      setSearchSuggestions(response.data);
    }).catch(error => {
      setShowSuggestionBox(false);
      setSearchSuggestions([]);
    });
  }, [])

  const handleSelectedStion = useCallback((event, suggestion) => {
    setShowSuggestionBox(false);
    setValidDomain(true);
    const elementDomain = document.getElementById('leaked-search-input-domain');
    if(elementDomain)elementDomain.value = suggestion.domain;
    setShowDownload(true);
    loadSearchData(suggestion.domain);
  }, [])

  const applyFilter = useCallback(() => {
    pageChanged({currentPage:0})
  }, [])

  return (
    <div className="leaked-details px-4">
      <div className="d-lg-flex py-3 px-4 top-section-border justify-content-bitween">
          <div className="col-lg-2 col-md-12 d-flex align-items-center">
              <p className="comprimised-users mb-0">Total Records</p>
              <p className="comprimised-users-count mx-2 mb-0"><RecorderCount recordCount={count}/></p>
          </div>
          <div className={'col-lg-5 col-md-12 d-flex align-items-center leaked justify-content-even'}>
            <div className='d-flex'>
            <div className=''>
              <Form.Select size="lg" onChange={handleSearchBy}>
                <option value="DOMAIN">Domain{(subscriptionType && subscriptionType=="ENTERPRISE") ? ' ('+domains?.length+')' : ''}</option>
                <option value="EMAIL">Email</option>
                {subscriptionType != 'ENTERPRISE' && <option value="ANDROID">Android</option>}
              </Form.Select>
            </div>
            <div className='px-2 position-relative'>
              {(searchBy=='EMAIL' || searchBy=='ANDROID') && 
              <Form.Control size="lg" className='search-field' id='leaked-search-input' type="text" onKeyUp={assignSearchValue} placeholder={placeholder} />
        }
        {(searchBy=='DOMAIN' && subscriptionType!="ENTERPRISE") && 
              <>
              <Form.Control ref={domainRef} size="lg" className='search-field' id='leaked-search-input-domain' type="text" onKeyUp={(event) => domainSearchSuggestion(event)} placeholder={placeholder} />
              {(searchBy=='DOMAIN' && subscriptionType!="ENTERPRISE" && showSuggestionBox) && 
                <div className='suggestions-box mt-1 p-2'>
                  {searchSuggestions.length > 0 && searchSuggestions.map(suggestion => (
                    <div className='py-2 px-3 cursor-pointer suggestion-option' key={suggestion} onClick={(event) => handleSelectedStion(event, suggestion)}>
                      <p className='mb-0'>{suggestion.domain}</p>
                    </div>
                  ))}
                  {searchSuggestions.length == 0 &&
                    <div className='py-2 px-3 suggestion-option-empty'>
                      <p className='mb-0'>No Matches Found</p>
                    </div>
                  }
                </div>
              }
              </>
        }
        {(searchBy=='DOMAIN' && subscriptionType=="ENTERPRISE") && 
        <div className='d-flex'>
              <Form.Select size="lg" onChange={(event)=>restartSearch(event)}>
                <option value={(domains)?(domains.toString()):''}>All</option>
                {domains && domains.map((dom, index) => (
                  <option value={dom} key={index}>{dom}</option>
                ))}
              </Form.Select>
              <Domains callBack={()=>domainSaved()}></Domains>
              </div>
        }
            </div>
            </div>
            <div className='width-135px'>
            {(searchBy=='DOMAIN' && validDomain) && <Link to={'/main/sourcecodeleaks'} className='mb-0 mx-lg-2 cursor-pointer source-code-row'><p className='mb-0 mx-1 width-135px'>Source code Leaks</p> <img className='right-arrow' src={rightArrow} width="20" height="20" /></Link>}
            </div>
          </div>
          { subscriptionType && 
            <div className={"col-md-12 leaked-top-row justify-content-end align-items-center col-lg-5"}>
            <div className="mx-2 filter-checkbox position-relative">
              {statsLoading ?<><Form.Check className='d-inline' type="checkbox" value={'thirdparty'} checked={thirdPartySelected} onChange={(event) => handleThirdPartyFilter(event)} id={`third-party`} /> Third-party (<div className='position-relative d-inline'><LoaderComponents micro={true}/></div>)</> :
              <div className='d-flex color-gray'><Form.Check type="checkbox" value={'thirdparty'} checked={thirdPartySelected} onChange={(event) => handleThirdPartyFilter(event)} id={`third-party`} /><img className='mx-1' src={thirdParty} width={20} height={20}/>Third-party (<RecorderCount recordCount={thirdPartyCount}/>)</div>}
            </div>
            <div className="mx-2 filter-checkbox position-relative">
            {statsLoading ? <><Form.Check className='d-inline' type="checkbox" value={'corporate'} checked={employeesSelected} onChange={(event) => handleThirdPartyFilter(event)} id={`corporate`} /> Corporate (<div className='d-inline'><LoaderComponents micro={true}/></div>)</> :
              <div className='d-flex color-gray'><Form.Check type="checkbox" value={'corporate'} checked={employeesSelected} onChange={(event) => handleThirdPartyFilter(event)} id={`corporate`} label={``}/><img className='mx-1' src={corporate} width={20} height={20}/>Corporate (<RecorderCount recordCount={corporateCount}/>)</div>
            }
            </div>
            <div className="mx-2 filter-checkbox position-relative">
            {statsLoading ? <><Form.Check className='d-inline' type="checkbox" value={'users'} checked={usersSelected} onChange={(event) => handleThirdPartyFilter(event)} id={`users`} /> Users (<div className='d-inline'><LoaderComponents micro={true}/></div>)</> :
              <div className='d-flex color-gray'><Form.Check type="checkbox" value={'users'} checked={usersSelected} onChange={(event) => handleThirdPartyFilter(event)} id={`users`} label={``}/><img className='mx-1' src={users} width={20} height={20}/>Users (<RecorderCount recordCount={usersCount}/>)</div>}
            </div>
            {showDownload && <div className='mx-2 download position-relative'>
              {/* <Button variant="secondary" size="sm">Notify <img src={logo} height="15" width="15" alt="logo" /></Button> */}
              <GeneratedFilesComponent domain={searchString}/>
            </div>}
          </div>
          }
      </div>
      {(searchBy=='DOMAIN' && validDomain) && 
      <div className='col-12 p-4'>
          <CyberSecurity domain={searchString}></CyberSecurity>
          <br/>
          <Thirdparty></Thirdparty>
      </div>
      }
      <div className="col-12 pos-relative">
        {(leakedDetailsData?.length === 0 && !dataLoading) && <p className="mt-4 text-center text-white">No data found</p>}
        {(leakedDetailsData?.length > 0) &&
        <div className='height-75 leaked-table position-relative'>
          {dataLoading ? <LoaderComponents/>:
          <>
          <div className='row pos-fix-t-300'>
          <div className='col-11'></div>
          <div className={`col-1`}>
          {showNotifyButton && <Button className='notify-button' variant="primary" size="sm" onClick={() => notifyAll()}><img src={notify} width={'24px'}/></Button>}
          </div>
          </div>
        <Table >
          <thead >
            <tr className='align-items-bottom'>
            <th className="w-3"></th>
              <th className="w-29 px-2 hide-in-mobile">
                <div className='d-flex align-items-center'>URLS
                <Form.Group>
                        <InputGroup className='mx-1'>
                        <Form.Control size="lg" value={searchTerm} className='search-field' type="search" onChange={(e)=>{setSearchTerm(e.target.value)}} placeholder='Please enter Search term' />
                                <InputGroup.Text className='back-transparent'>
                                    {/* <img src="search" width={'24px'} height={'auto'}/> */}
                                    <button className='filter-term' onClick={()=>applyFilter()}>Apply</button>
                                </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
              </div>
              </th>
              <th className="w-15 px-2">Login</th>
              <th className="w-12">Password</th>
              <th className="w-10 px-2 hide-in-mobile">IP</th>
              <th className="w-15 px-1 hide-in-mobile">Compromised date</th>
              <th className="w-3"></th>
              <th className="w-3"></th>
            </tr>
          </thead>
          <tbody className='mt-2'>
            {leakedDetailsData.map((item, index) => (
              <>
                <tr key={index} className={item.enabledExpanded ? 'tr-highlight' : ''}>
                  <td><input disabled={item.notified} type="checkbox" value={item.id} checked={selectedIssues.indexOf(item.id)>-1} onChange={(event) => issueSelected(event, item)} id={`issue_`+index} label={``}/></td>
                  <td className={"hide-in-mobile w-35 "+ (item.enabledExpanded ? 'px-3 py-2' : 'px-2')}><p className={'mb-0 '}>{item?.url ? <a className='url-link text-white line-clamp-3' target='blank' href={item?.url}>{item?.url}</a> : 'NA'}</p></td>
                  <td className="w-15 px-2">{item?.username ? item?.username : 'NA'}</td>
                  <td className='w-12'>
                    <p className="mb-0">{item?.password ? item?.password.slice(0, 60)+(item?.password.length > 60 ? '...' : '') : 'NA'}</p>
                    <p className={`mb-0 ${item?.passwordStrength}`}>{item?.passwordStrength}</p>
                  </td>
                  <td className="hide-in-mobile w-10 px-2">{item?.ip ? item?.ip : 'NA'}</td>
                  <td className="hide-in-mobile w-12 px-1">{item?.date_compromised} </td>
                  <td className={"w-6 "+ (item.enabledExpanded ? 'px-2 py-2' : '')}>
                    <div className='d-flex align-items-center'>
                    <img src={item.enabledExpanded ? upArrow : downArrow} height="25" width="25" className="cursor-pointer" onClick={() => updateEnabled(item, index)} alt="logo" />
                    <img src={!!!item.notified?notify:noNotify} height="18" width="18" className={`cursor-pointer mx-2 ${!!item.notified?"no-pointers":""}`} onClick={() => sendAlert(item)} alt="logo" />
                    </div>
                  </td>
                </tr>
                {item.enabledExpanded &&
                <tr key={'child'+index} className={item.enabledExpanded ? 'tr-highlight' : ''}>
                  <td colSpan="7">
                    <div  className='steeler-details py-2 px-4 col-12 hide-in-mobile'>
                    <table className='col-12'>
                      <tr>
                        <th>Operating System:</th>
                        <th>Antivireses:</th>
                        <th>Computer name:</th>
                      </tr>
                      <tr>
                      <td className="mb-0"> {item.operating_system ? item.operating_system : 'N/A'}</td>
                       <td className="mb-0 my-2"> {item.installed_antivireses ?item.installed_antivireses.toString():'N/A'}</td>
                       <td className="mb-0 my-2"> {item.computer_name ?item.computer_name:'N/A'}</td>
                      </tr>
                    </table>
                    </div>
                    <div  className='steeler-details py-2 px-4 col-12 hide-in-desktop'>
                    <div className='row'>
                    <div className='col-4 mb-2'>URLS</div>
                    <div className='col-8 mb-2'>{item?.url ? <a className='url-link text-white' target='blank' href={item?.url}>{item?.url}</a> : 'NA'}</div>
                    <hr/>
                    <div className='col-4 mb-2'>IP</div>
                    <div className='col-8 mb-2'>{item?.ip ? item?.ip : 'NA'}</div>
                    <hr/>
                    <div className='col-4 mb-2'>Comprimised date</div>
                    <div className='col-8 mb-2'>{item?.date_compromised}</div>
                    <hr/>
                    <div className='col-4 mb-2'>Last time added</div>
                    <div className='col-8 mb-2'>{item?.date_uploaded ? ((moment(new Date(), "YYYY-MM-DDTHH:mm:ss.SSSSZ").diff(moment(item?.date_uploaded, "YYYY-MM-DDTHH:mm:ss.SSSSZ"), 'days')) >= 0 ? moment(item?.date_uploaded, "YYYY-MM-DDTHH:mm:ss.SSSSZ").add(-5.5,'h').format('DD MMM, yyyy') : 'NA') : 'NA'}</div>
                    <hr/>
                    <div className='col-4 mb-2'>Operating System:</div>
                    <div className='col-8 mb-2'>{item.operating_system ? item.operating_system : 'N/A'}</div>
                    <hr/>
                    <div className='col-4 mb-2'>Computer name:</div>
                    <div className='col-8 mb-2'>{item.computer_name ?item.computer_name:'N/A'}</div>
                    </div>
                    </div>
                  </td>
                </tr>
                }
              </>
            ))}
          </tbody>
        </Table>
        </>}
        </div>
}
{count>0 && 
<div className="pt-3"><PagenationComponent count={count} pageSize={50} currentPage={currentPage} pageChange={(event)=>{
pageChanged(event);
}}/></div>}
      </div>
      <Modal show={showErrorPopup} className='alert-modal'>
          <Modal.Header>
            <div className='col-12 export-header d-flex justify-content-between'>
              <div className="modal-title h4">Alert</div>
              <div className='d-flex'>
              <Button onClick={() => handlePopup(false, "")}>X</Button>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
              <div className='w-100 text-center'>
                <p className='mb-0'>{errorMessage}</p>
              </div>
          </Modal.Body>
        </Modal>
        <Modal show={showAlertPopup} className='notify-modal'>
          <Modal.Body>
              {/* <div className='w-100 text-center p-3'>
                <p>Please enter an Email to whom you want to notify.</p>
                <Form.Control size="lg" className='search-field' type="text" onKeyUp={assignEmail} placeholder='Please enter Email' />
                {showEmailError && <div className='col-12 d-flex justify-content-lg-start'><p className='mb-0 text-danger'>Email is required.</p></div>}
                <br/>
                <div className='col-12 d-flex justify-content-between'>
                <Button onClick={() => handleAlertPopup(false, "")}>close</Button>
                <Button  variant="secondary" size="sm" onClick={() => sendNotification()}>Notify</Button>
                </div>
              </div> */}
              <NotifiModel sendNotification={sendNotification} closeAlertPopup={closeAlertPopup}></NotifiModel>
          </Modal.Body>
        </Modal>
    </div>
  );
})
function mapStateToProps(state) {
  return {
    user: state.user.user,
    subscriptionStatus: state.user.subscriptionStatus,
    subscriptionType: state.user.subscriptionType,
    domains: state.user.domains,
    leakedDetailsData: state.user.leakedDetailsData,
    count: state.user.count,
    currentPage: state.user.currentPage,
    corporateCount: state.user.corporateCount,
    usersCount: state.user.usersCount,
    thirdPartySelected: state.user.thirdPartySelected,
    employeesSelected: state.user.employeesSelected,
    usersSelected: state.user.usersSelected,
    searchString: state.user.string
  }
}
const mapDispatchToProps = (dispatch) => ({
  saveUser: (payload) => dispatch({ type: "user", payload: payload }),
  saveDomains: (payload) => dispatch({ type: "domains", payload: payload }),
  savePayment: (payload) => dispatch({ type: "subscription", payload: payload }),
  savePageChanged: (payload) => dispatch({ type: "pageChanged", payload: payload }),
  saveData: (payload) => dispatch({ type: "leakedDetailsData", payload: payload }),
  saveFilters: (payload) => dispatch({ type: "filters", payload: payload }),
  saveString: (payload) => dispatch({ type: "string", payload: payload }),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeakedDetails);
