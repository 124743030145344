import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './apiDocuments.css';
import back_icon from '../../assets/images/back_icon.svg';
import Form from "react-bootstrap/Form";
import { Button } from 'react-bootstrap';
import { JSONTree } from 'react-json-tree';
import { getAPIData } from '../../api/postDetails';
import copy_icon from '../../assets/images/copy_icon.svg';
import { createAPIToken, getAPIToken, getSubscription } from '../../api/getDetails';
import moment from 'moment';
import { useToast } from '../../Toast';

const ApiDocumentsComponent = memo(function ApiDocumentsComponent() {

  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('user')));
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [docNavLinks, setdocNavLinks] = useState([{title: "Introduction", active: true},{title: "Getting Started", active: false}, {title: "Domain Intelligence", active: false}]);
  const [docNavClicks, setdocNavClicks] = useState(0);
  const [canShowJSON, setCanShowJSON] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [isTokenGenerated, setIsTokenGenerated] = useState(false);
  const [apiKey, setAPIKey] = useState('INFOLEAKSINFOLEAKS');
  const [page, setpage] = useState("1");
  const [thirdPartyDomains, setThirdPartyDomains] = useState(false);
  const [userType, setUserType] = useState('Employees');
  const [domains, setDomains] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showDomainError, setShowDomainError] = useState(false);
  const [showFromDateError, setShowFromDateError] = useState(false);
  const [showToDateError, setShowToDateError] = useState(false);
  const [types, setTypes] = useState([{label: 'Employees', value: 'EMPLOYEES'}, {label: 'Users', value: 'USERS'}, {label: 'All', value: 'ALL'}])
  const [selectedNav, setSelectednav] = useState('Introduction');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const toast = useToast();

  useEffect(() => {
    setdocNavLinks(docNavLinks);
    getSubscription('api/payment/retrive', userDetails?.id).then((response) => {
        setSubscriptionStatus(response.data.subscriptionStatus);
        fetchAPIToken();
      }).catch(error => {
        console.log(error);
      })
  }, [docNavClicks]);

  const fetchAPIToken = useCallback(() => {
    getAPIToken('api/apitokens/get-api-token').then((response) => {
        if(response?.data?.apiTokenId) {
          setAPIKey(response.data.apiTokenId);
          setIsTokenGenerated(true);
        } else {
            setIsTokenGenerated(false);
        }
      }).catch((error) => {
        setIsTokenGenerated(false);
      })
  }, [])

  const goBack = useCallback(() => {
    getSubscription('api/payment/retrive', userDetails?.id).then((response) => {
        if(response.data.subscriptionStatus === 'active' || response.data.subscriptionStatus === 'trialing') {
              navigate('/main/leakeddetails');
        } else {
              navigate('/main/search');
        }
    }).catch((error) => {
        console.log(error);
        navigate('/main/search');
    })
  }, [userDetails])

  const handlePage = useCallback((e) => {
    setpage(e.target.value);
  }, [])

  const handleThirdPartyDomains = useCallback((e) => {
    if(e.target.checked) {
        setThirdPartyDomains(true);
        setUserType('Employees');
        setTypes([{label: 'Employees', value: 'EMPLOYEES'}]);
    } else {
        setThirdPartyDomains(false);
        setUserType('Employees');
        setTypes([{label: 'Employees', value: 'EMPLOYEES'}, {label: 'Users', value: 'USERS'}, {label: 'All', value: 'ALL'}]);
    }
  }, [])

  const handleType = useCallback((e) => {
    setUserType(e.target.value);
  }, [])

  const handleDomain = useCallback((e) => {
    if(e.target.value) {
        setShowDomainError(false);
        const domains = e.target.value.split(',');
        setDomains(domains);
    } else {
        setShowDomainError(true);
        setDomains([]);
    }
  }, [])

  const handleFromDate = useCallback((e) => {
    setFromDate(moment(new Date(e.target.value), "YYYY-MM-DDTHH:mm:SSS").format('YYYY-MM-DDTHH:mm:SSS'));
    if(e.target.value) {
        setShowFromDateError(false);
    }
  }, [])

  const handleToDate = useCallback((e) => {
    setToDate(moment(new Date(e.target.value), "YYYY-MM-DDTHH:mm:SSS").format('YYYY-MM-DDTHH:mm:SSS'));
    if(e.target.value) {
        setShowToDateError(false);
    }
  }, [])

  const highlightNavLink = useCallback((doc) => {
    docNavLinks.map(docNav => {
        docNav.active = (docNav.title == doc.title) ? true : false;
    })
    setSelectednav(doc.title);
    setdocNavClicks(docNavClicks+1);
  }, [docNavLinks])

  const getData = useCallback(() => {
    if(domains.length == 0) {
        setShowDomainError(true);
    }
    if(!fromDate) {
        setShowFromDateError(true);
    }
    if(!toDate) {
        setShowToDateError(true);
    }
    if(domains.length > 0 && fromDate && toDate) {
        setShowErrorMessage(false);
        setShowDomainError(false);
        setShowFromDateError(false);
        setShowToDateError(false);
        getAPIData('api/v1/search-by-domain?page='+page+'&from_date_added='+fromDate+'Z'+'&to_date_added='+toDate+'Z'+'&user_type='+userType+'&third_party_domains='+thirdPartyDomains, {domains: domains}, apiKey).then((response) => {
            setCanShowJSON(true);
            setJsonData(response.data);
        }).catch((error) => {
            setCanShowJSON(false);
            setShowErrorMessage(true);
            setErrorMsg(error.response.data);
        })
    }
  }, [domains, fromDate, toDate, page, userType, thirdPartyDomains, apiKey])

  const generateAPIToken = useCallback(() => {
    if(subscriptionStatus == "active") {
        createAPIToken('api/apitokens/create-api-token').then((response) => {
            if(response?.data?.apiTokenId) {
              setIsTokenGenerated(true);
              setAPIKey(response.data.apiTokenId);
            } else {
              setIsTokenGenerated(false);
            }
          }).catch((error) => {
              setIsTokenGenerated(false);
          })
    } else {
        navigate('/main/chooseplan');
    }
  }, [subscriptionStatus])

  const showToast = useCallback((text) =>{
    toast.open(text);
  }, [])

  return (
    <>
    <div className='col-12 p-4 apidoc'>
        <div className='row api-row'>
            <span className='doc-header-text'><img src={back_icon} width="25" height="25" className='cursor-pointer mx-2' onClick={goBack}/>API Documents</span>
            <div className='d-flex align-items-center api-doc-section mx-2'>
              <span className='api-key-text'>{isTokenGenerated ? '' : 'Free '}API Key</span>
              <span className='api-key-value mx-2'>{apiKey.slice(0, 15)+(apiKey.length > 15 ? '...' : '')}</span>
              <img src={copy_icon} width="25" height="25" className='cursor-pointer' onClick={() =>  {navigator.clipboard.writeText(apiKey);showToast('Apikey copied!')}}/>
            </div>
            <Button className='mx-2 generate-btn' onClick={generateAPIToken}>Generate New API Key</Button>
        </div>
        <div className='row mt-4'>
            <div className='col-2'>
                <div className='doc-nav-block p-2'>
                    {docNavLinks && docNavLinks.map((doc, index) => (
                        <p className={'mb-0 cursor-pointer my-2'+(doc.active ? ' doc-nav-active' : ' doc-nav-text')} onClick={() => highlightNavLink(doc)}>{doc.title}</p>
                    ))}
                </div>
            </div>
            <div className={'doc-content-block px-4 py-2'+(selectedNav == 'Getting Started' ? ' col-6' : ' col-7')}>
                {docNavLinks && docNavLinks.map(link => (
                    <>
                    {link.title == "Introduction" && link.active &&
                        <div>
                            <p className='text-heading'>Introductory Guide to the <a href='https://infoleak.io/'>Infoleak.io</a> API Guide</p>
                            <p className='text-content mt-4'>Welcome to the introductory guide for the Infoleak.io API! This guide aims to provide you with an overview of the capabilities and usage of the Infoleak.io API, a powerful tool for accessing compromised device data and information leaks sourced from global info-stealer operations.</p>
                            <br/>
                            <p className='text-heading'>1. What is the Infoleak.io API?</p>
                            <p className='text-content'>The Infoleak.io API grants users access to a comprehensive database containing information on compromised devices and data leaks resulting from global info-stealer operations. With this API, cybersecurity professionals can stay ahead of emerging threats and fortify their defenses against data breaches and credential theft incidents.</p>
                            <br/>
                            <p className='text-heading'>2. Key Features:</p>
                            <div className='text-content px-4'>
                                <li>
                                  Expansive Database: Gain access to a vast repository comprising close to 2.3 Billion records of compromised devices and data leaks.
                                </li>
                                <li>
                                  Daily Updates: The database receives regular updates to ensure that users have access to the latest information on compromised devices and emerging threats.
                                </li>
                                <li>
                                  Search Functionality: Utilize the API's search functionality to query the database for specific information, such as email addresses, usernames, domains, or other relevant identifiers.
                                </li>
                            </div>
                            <br/>
                            <p className='text-heading'>3. Getting Started:</p>
                            <p className='text-content'>To begin using the Infoleak.io API, users must obtain an API key. This API key serves as a secure authentication token, enabling access to the API endpoints and database.</p>
                            <br/>
                            <p className='text-heading'>4. API Endpoints:</p>
                            <p className='text-content'>The Infoleak.io API offers various endpoints to retrieve specific information from the database. These endpoints allow users to tailor their queries according to their requirements and extract actionable insights to enhance their cybersecurity posture.</p>
                            <br/>
                            <p className='text-heading'>5. Integrating with Your Application:</p>
                            <p className='text-content'>Once you have obtained your API key and familiarized yourself with the available endpoints, you can integrate the Infoleak.io API into your cybersecurity infrastructure. By incorporating this API into your applications or tools, you can leverage its extensive database to enhance threat detection and response capabilities.</p>
                            <br/>
                            <p className='text-heading'>6. Documentation and Support:</p>
                            <p className='text-content'>For detailed information on using the Infoleak.io API, refer to the official documentation provided by Infoleak.io. Additionally, their support team is available to assist you with any questions or issues you may encounter while using the API.</p>
                            <br/>
                            <p className='text-heading'>7. Conclusion:</p>
                            <p className='text-content'>The Infoleak.io API is a valuable resource for cybersecurity professionals seeking to bolster their defenses against data breaches and credential theft incidents. By leveraging the power of this API, you can gain access to critical insights and take proactive measures to safeguard your organization's assets and data.</p>
                            <br/>
                        </div>
                    }
                    {link.title == "Getting Started" && link.active &&
                        <div>
                            <p className='text-heading'>Authentication</p>
                            <p className='text-content mt-4'>The infoleak.io API uses API keys to authenticate requests.</p>
                            <p className='text-content mt-4'>Your credentials carry many privileges, so be sure to keep them secure! Do not share your secret keys in publicly accessible areas such as GitHub, client-side code, and so forth.</p>
                            <p className='text-content mt-4 mb-0'>Authentication to the API is performed via API key.</p>
                            <p className='text-content'>Provide your API key as a header named "api-key".</p>
                        </div>
                    }
                    {link.title == "Domain Intelligence" && link.active &&
                        <div>
                            <div className='mb-4 text-white'>
                              <h1>Domain Intelligence</h1>
                              <p>
                              Safeguarding businesses against ransomware assaults, network breaches, and industrial espionage poses a formidable task for cybersecurity practitioners. In recent times, pilfered corporate credentials from computer systems have emerged as a prevalent avenue exploited by cyber malefactors to breach corporate networks.
                              </p>
                            </div>
                            <div className='my-4 text-white pt-4'>
                              <h2>The Resolution</h2>
                              <p>
                              Infoleak.io offers its clientele the capability to access a database housing data from over 2.3 billion compromised machines, the aftermath of global info-stealer campaigns orchestrated by threat actors. This database receives daily updates, empowering cybersecurity providers to forewarn security teams of imminent threats, particularly when users fall victim to credential theft incidents.
                              </p>
                            </div>
                            <div className='my-4 text-white py-4'>
                              <p>
                              It's important to note that specifying type=employee in the API call doesn't directly replicate the functionality of the "compromised employee" tab within the Infoleak.io platform. Instead, it indicates the detection of sensitive keywords within the URL (e.g., "confluence" in "confluence.infoleak.io") or the access of a corporate email address (e.g., abc@infoleak.io) to the domain (e.g.,infoleak.io). In practical terms, if the URL lacks sensitive information, the endpoint will categorize the access as type=users, even if it's associated with a device harboring corporate credentials for that domain.
                              </p>
                            </div>
                            <div className='my-4'>
                              <h2 className='text-white'>URL Parameters</h2>
                              <table className='mt-4'>
                                <thead>
                                  <tr>
                                    <th className='wid-20'>Parameter</th>
                                    <th className='wid-20'>Options/Data Type</th>
                                    <th className='wid-20'>Default</th>
                                    <th className='wid-40'>Explanation</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>page</td>
                                    <td>Number</td>
                                    <td>1</td>
                                    <td>The API utilizes data pagination. When there are no more results in the response, a square bracket will be shown "[]".</td>
                                  </tr>
                                  <tr>
                                    <td>third_party_domains</td>
                                    <td>Boolean (true/false)</td>
                                    <td>false</td>
                                    <td>Filter results to show only credentials which are related to the Third Party Domain/s. If this value is true, then value of "type" can be selected only as "Employees".</td>
                                  </tr>
                                  <tr>
                                    <td>user_type</td>
                                    <td>String</td>
                                    <td>Employees</td>
                                    <td>Filter results to show only credentials which are related to the selected Type.</td>
                                  </tr>
                                  <tr>
                                    <td>from_date_added</td>
                                    <td>ISO Date: YYYY-MM-DDThh:mm:sssZ</td>
                                    <td>All Time</td>
                                    <td>Filter results to show only stealers which were added since a specified date. Results will be sorted according to this parameter in an descending order. (This will work only from_date_added  and to_date_added are provided)</td>
                                  </tr>
                                  <tr>
                                    <td>to_date_added</td>
                                    <td>ISO Date: YYYY-MM-DDThh:mm:ss.sssZ</td>
                                   <td>All Time</td>
                                   <td>Filter results to show only stealers which were added until a specified date. Results will be sorted according to this parameter in an descending order. (This will work only from_date_added  and to_date_added are provided)</td>
                                  </tr>
                               </tbody>
                            </table>
                            </div>
                            <div className='my-4 py-4'>
                              <h2 className='text-white mt-4'>Request Body</h2>
                              <table className='mt-4'>
                                <thead>
                                  <tr>
                                    <th className='wid-20'>Parameter</th>
                                    <th className='wid-20'>Options/Data Type</th>
                                    <th className='wid-20'>Default</th>
                                    <th className='wid-40'>Explanation</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>domains</td>
                                    <td>Array</td>
                                    <td>None</td>
                                    <td>Filter results to fetch the data of those particular Domains.<br/>Example - ["netflix.com", "disney.com"]</td>
                                  </tr>
                               </tbody>
                            </table>
                            </div>
                        </div>
                    }
                    </>
                ))}
            </div>
            <div className={selectedNav == 'Getting Started' ? 'col-4' : 'col-3'}>
                {docNavLinks && docNavLinks.map(link => (
                    <>
                    {link.title == "Introduction" && link.active &&
                        <div>

                        </div>
                    }
                    {link.title == "Getting Started" && link.active &&
                        <div>
                            <div className='doc-curl-block p-4'>
                                <div className='row'>
                                <div className='col-9'><p className='text-heading'>AUTHENTICATED REQUEST</p></div>
                                <div className='col-3 d-grid justify-content-end'><img src={copy_icon} width="25" height="25" className='cursor-pointer' onClick={() =>  {navigator.clipboard.writeText(`curl --location 'https://infoleak.io/api/v1/search-by-domain?page=2&from_date_added=2024-04-25T00%3A00%3A000Z&to_date_added=2024-04-27T00%3A00%3A00Z&user_type=Employees&third_party_domains=false' \
                                    --header 'api-key: INFOLEAKSINFOLEAKS' \
                                    --header 'Content-Type: application/json' \
                                    --data '{
                                        "domains":["darwinbox.com", "darwinbox.io"]
                                    }'`); showToast('Code copied!')}}/></div>
                                </div>
                                <div className='text-content curl-content p-3'>
                                <p className='mb-0'><span className='curl'>curl</span> <span className='curl-header'>--location</span> <span className='curl-text'>'https://infoleak.io/api/v1/search-by-domain?page=2&from_date_added=2024-04-25T00%3A00%3A000Z&to_date_added=2024-04-27T00%3A00%3A00Z&user_type=Employees&third_party_domains=false' \ </span>
                                    <span className='curl-header'>--header</span> <span className='curl-text'>'api-key: INFOLEAKSINFOLEAKS' \ </span>
                                    <span className='curl-header'>--header</span> <span className='curl-text'>'Content-Type: application/json' \ </span>
                                    <span className='curl-header'>--data</span> <span className='curl-text'>{`'{
                                        "domains":["darwinbox.com", "darwinbox.io"]
                                    }'`}</span></p>
                                </div>
                                <p className='mb-0 mt-2 note-text'>Note : Replace api-key 'INFOLEAKSINFOLEAKS' with your generated api-key.</p>
                            </div>
                        </div>
                    }
                    {link.title == "Domain Intelligence" && link.active &&
                        <div>
                        <div className='doc-nav-block p-4'>
                        <div className='test-form-section'>
                            <div className='row mt-2'>
                                <div className='col-5'>
                                <label>Page</label>
                                <Form.Select className='my-2' onChange={(event) => handlePage(event)}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </Form.Select>
                                </div>
                                <div className='col-7'>
                                <label></label>
                                <Form.Check type="checkbox" className='mt-3' onClick={(event) => handleThirdPartyDomains(event)} label="Third Party Domains" />
                                </div>
                            </div>
                            <div className='mt-2'>
                            <label>Type</label>
                            <Form.Select className='my-2' onChange={(event) => handleType(event)}>
                                { types && types.map(type => (
                                    <option value={type.value}>{type.label}</option>
                                ))}
                            </Form.Select>
                            </div>
                            <div className='mt-3'>
                            <label>Domain</label>
                            <p className='mb-0 hint-text'>(For adding multiple Domains continue adding using 'comma seperator'.)</p>
                            <Form.Control className='my-2' type="text" onChange={(event) => handleDomain(event)}/>
                            <p className='mb-0 hint-text'>(Example - netflix.com,disney.com)</p>
                            {showDomainError && <div className='error-msg'>Domain is required.</div>}
                            </div>
                            <div className='my-3'>
                            <label>Added Since</label>
                            <Form.Control className='my-2' type="date" onChange={(event) => handleFromDate(event)}/>
                            {showFromDateError && <div className='error-msg'>Added Since Date is required.</div>}
                            </div>
                            <div className='mt-2'>
                            <label>Added Until</label>
                            <Form.Control className='my-2' type="date" onChange={(event) => handleToDate(event)}/>
                            {showToDateError && <div className='error-msg'>Added Until Date is required.</div>}
                            </div>
                        </div>
                        <div className='col-12 my-4 text-center'>
                            <Button variant='secondary' onClick={getData}>Send</Button>
                        </div>
                        {showErrorMessage && <div className='col-12 error-msg my-2 text-center'>{errorMsg}</div>}
                        </div>
                        { canShowJSON && 
                        <div className='mt-4 doc-json-block'>
                            <div>
                            <JSONTree data={jsonData} />
                            </div>
                        </div>
                        }
                        </div>
                    }
                    </>
                ))}
            </div>
        </div>
    </div>
    </>
  );
})

export default ApiDocumentsComponent;
