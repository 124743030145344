import './paymentsuccess.css';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { postPaymentDetails } from '../../api/postDetails';

function Success() {
    const navigate = useNavigate();
    let { id } = useParams();
    function navigateHome(){
        navigate('/');
    }

    useEffect(() => {
        executeAggrement(id);
    }, [id]);

    const executeAggrement = async(subscriptionId) => {
        const data = {
            subscriptionId: subscriptionId,
        }
        await postPaymentDetails('api/payment/v3/executeAgreement', data).then((response) => {

        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <div className='full-width'>
            <div className='center-alligned'>
                <h1>Subscription Successful!</h1>
                <button className='home-button' onClick={navigateHome}>Go to home</button>
            </div>
        </div>
    );
}

export default Success;
