import { memo, useCallback, useEffect, useState } from 'react';
import { Form } from "react-bootstrap";
import { getRepos } from '../../api/getDetails';
import './leakedRepos.css';

const LeakedRepos = memo(function LeakedRepos(props) {

    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('user')));
    const [repos, setRepos] = useState([]);
    const [repoColors, setRepoColors] = useState(['green', 'purple', 'orange', 'yellow', 'green', 'purple', 'orange', 'yellow']);
    const [selectedRepo, setSelectedRepo] = useState(null);
    const [searchPerformed, setSearchperformed] = useState(false);

    useEffect(() => {
        loadRepos('');
    },[]);

    const loadRepoFiles = useCallback((event, repo) => {
        setSelectedRepo(repo);
        props.callBack(repo);
    }, [])

    function loadRepos(searchVal) {
        let url = 'api/paste/search-repositories?q='+props.domain;
        if(searchVal.trim().length > 0) {
            url += '&pattern='+searchVal;
        }
        getRepos(url).then((response) => {  
            props.repoChecked(true);
            response?.data?.buckets?.map((bucket, index) => {
                bucket["repoColor"] = repoColors[index];
            })
            setRepos(response.data.buckets);
            if(response?.data?.buckets?.length > 0) {
                props.callBack(response?.data?.buckets[0]);
                setSelectedRepo(response?.data?.buckets[0]);
                if(searchVal == '') {
                    props.reposCount(response?.data?.buckets?.length);
                }
            }
        });
    }

    const handleSearch = useCallback((e) => {
        if(e.keyCode === 13 && e?.target?.value?.trim() != '') {
            setSearchperformed(true);
            loadRepos(e.target.value);
        } else if(e?.target?.value?.trim() == '' && searchPerformed){
            setSearchperformed(false);
            loadRepos('');
        }
    }, [searchPerformed])

    return (
        <div className='leaked-repos-block p-4'>
            <p className='mb-0 header-text'>Leaked Repositories</p>
            <div className='my-3'>
                <Form.Control size="lg" className='search-field' id='leaked-repos-search' type="text" placeholder="Search Repository" onKeyUp={handleSearch}/>
            </div>
            <div className='repos-block'>
            { repos && repos.map(repo => (
                <div className={'cursor-pointer '+((repo?.val ==  selectedRepo?.val) ? 'repos-row-highlight' : 'repos-row')} onClick={(event) => loadRepoFiles(event, repo)}>
                    <p className='mb-0 repo-text w-80'><span className={'m-2 repo-text-badge w-10 '+(repo?.repoColor)}>{repo?.val.slice(0,1).toUpperCase()}</span><span className='w-90'>{repo?.val}</span></p>
                    <p className='mb-0 repo-text px-2 w-20'>{repo?.count}</p>
                </div>
            ))}
            </div>
        </div>
    );
})
export default LeakedRepos;