import axios from "axios";
import { apiUrl, baseUrl, brandScoreUrl, builtWith, clearbitUrl } from "./endpoints";
import { getMethod } from "./interceptor";

// get  Method
export const getRequest = async function(endPoint, domain, page, thirdPartySelected, employeesSelected, usersSelected, searchTerm) {
  let url = baseUrl + endPoint+'?page='+page+'&domain='+domain+'&thirdPartySelected='+thirdPartySelected+'&employeesSelected='+employeesSelected+'&usersSelected='+usersSelected;
  if(searchTerm){
    url = url + '&urlFilter=' + searchTerm;
  }
  const response = await getMethod(url);
  return response;
};

export const getLeakedStatistics = async function(endPoint, domain) {
  const response = await getMethod(baseUrl + endPoint+'?domain='+domain);
  return response;
};

export const getInfoleaks = async function(endPoint) {
  const response = await getMethod(baseUrl + endPoint);
  return response;
};

export const getSevenData = async function(endPoint, domain) {
  const response = await axios.get(baseUrl + endPoint+'?domain='+domain);
  return response;
};

export const getSubscription = async function(endPoint, userId) {
  const response = await getMethod(baseUrl + endPoint+"?userId="+userId);
  return response;
};

export const getDomains = async function(endPoint, userId) {
  const response = await getMethod(baseUrl + endPoint+"?userId="+userId);
  return response;
};

export const tokenExpired = async function(token) {
  const response = await getMethod(baseUrl + 'api/user/token-expired');
  return response;
};

export const getAPIToken = async function(endPoint, userId) {
  const response = await getMethod(baseUrl + endPoint+'?userId='+userId);
  return response;
};

export const createAPIToken = async function(endPoint, userId) {
  const response = await getMethod(baseUrl + endPoint+'?userId='+userId);
  return response;
};

export const sendInvite = async function(email, domain, team) {
  const response = await getMethod(baseUrl + 'api/sendemail?email='+email+'&domain='+domain+'&team='+team);
  return response;
};

export const getUsers = async function(page, pageSize = 20, name = '') {
  let query = baseUrl + 'api/user/users' + `?page=${page}&pageSize=${pageSize}`;
  if(name) {
    query += `&name=${name}`;
  }
  const response = await getMethod(query);
  return response;
};

export const getDomainSuggestions = async (searchVal) => {
  const config = axios.create();
  const response = await config.get('https://autocomplete.clearbit.com/v1/companies/suggest?query='+searchVal);
  return response;
};

export const getRepos = async function(url) {
  const response = await getMethod(baseUrl + url);
  return response;
};

export const getHits = async function(url) {
  const response = await getMethod(baseUrl + url);
  return response;
};

export const getBrandScoreData = async (domain) => {
  const config = axios.create();
  const response = await config.get(brandScoreUrl+'domain-risk/'+domain);
  return response;
};

export const getGeneratedReports = async function(url) {
  const response = await getMethod(baseUrl + url);
  return response;
};

export const downloadReport = async function(url) {
  const response = await getMethod(baseUrl + url);
  return response;
};

export const deleteReportFile = async function(url) {
  const response = await getMethod(baseUrl + url);
  return response;
};

export const getVendors = async function(url) {
  const response = await getMethod(baseUrl + url);
  return response;
};

export const getAssociatedVendors = async function(url) {
  const response = await getMethod(baseUrl + url);
  return response;
};

export const acknowledge = async function(url){
  const response = await getMethod(baseUrl + url);
  return response;
}

export const getVendor = async function(url) {
  const response = await getMethod(baseUrl + url);
  return response;
};

export const getVendorFromClearBit = async function(domain) {
  const url = `https://api.allorigins.win/get?url=${encodeURIComponent(clearbitUrl+domain)}`
  const response = await axios.get(url)
    return response;
};

export const getBuiltWith = async function(domain) {
  const url = `https://api.allorigins.win/get?url=${encodeURIComponent(builtWith+domain)}`
  const response = await axios.get(url)
    return response;
};

export const getPaymentMethods = async function() {
  const response = await getMethod(baseUrl + 'api/paymentmethod/get');
  return response;
};

export const savePaymentTypes = async function(id) {
  const response = await getMethod(baseUrl + 'api/paymentmethod/update?paymentId='+id);
  return response;
}

export const getActivePaymentMethod = async function() {
  const response = await getMethod(baseUrl + 'api/paymentuser/get-active');
  return response;
}

// get without interceptor
// export const postForgotpasswordDetails = async function (obj) {
//   const response = await axios.get(baseUrl + '');
//   return response;
// };

// export const validateDomain = async function(url, domain) {
//   const response = await axios.get( url+ 'domain/validate/'+domain);
//   return response;
// };