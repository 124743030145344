
import './termsofservice.css';
function Termsofservice() {
  return (
<div className="back-black terms-of-service">
<h1 className='font-53'>Terms of Service</h1>
<br/>
<h2>Applicability</h2>
<p>These terms of service (“TOS”) govern the legal terms of the website of InfoLeak, Ltd. and/or any of its affiliates and/or any of its brand names (“InfoLeak”) at <a href="www.infoleak.io">www.infoleak.io</a> (the “site”), and certain internet services, articles, text, information, graphics, video, documents to be downloaded, and any other content (collectively “content”) which are made available to you (“user”) through the site. The TOS, together with InfoLeak’s privacy policy, available at <a href="infoleak.io/privacy-policy">infoleak.io/privacy-policy</a> (“privacy policy”), constitute the entire and only agreement between InfoLeak and user, and supersede all other agreements, representations, warranties, and understandings with respect to the site and the content and the subject matter contained therein. By attempting to use and/or by using the site and the content, or any part thereof, user agrees to fully comply with and be bound by the TOS and privacy policy. If user does not accept the TOS or the privacy policy, user must not access and use the site and the content, or any part thereof, and/or immediately stop any use of the site and the content. InfoLeak may from time to time modify the TOS. If user does not agree to the TOS, as amended, user must stop using the site and the content. User is advised that if user does not terminate all use of the site and the content, or any part thereof, user will be deemed to have accepted the TOS, as amended.</p>
<br/>
<h2>Representations</h2>
<p>By using the site and/or the content, user represents that:</p>
<ul>
    <li>user is not under 18 years of age,</li>
    <li>user agrees to be bound by the terms of this TOS; and</li>
    <li>user’s use of the site and the content does not conflict with any law applicable to user.</li>
</ul>
<br/>
<h2>Ownership</h2>
<p>Copyright protection. All title, ownership rights, and intellectual property rights (including all copyrights, patents, trade secret rights, and trademarks) in and to the site and the content (except for the third party content – as defined below), shall remain with InfoLeak, its affiliates, or their respective licensors, if any. User agrees that nothing contained in the site shall be construed as granting a license to use any intellectual property right with respect to the site or the content without the prior written permission of InfoLeak.</p>
<br/>
<h2>Third party content</h2>
<p>The site and the content may contain links to certain third-party services, websites, articles, links, icons, ads, videos, graphics, and/or any other content that is offered by third parties (collectively “third party content”). The inclusion of third party content within the site and/or the content does not constitute any endorsement, guarantee, warranty, or recommendation of such third-party websites. InfoLeak does not have any control over the terms of service and privacy policies of third-party websites and user accesses any such third party content at user’s own risk.</p>
<br/>
<h2>Indemnity</h2>
<p>Each user agrees upon InfoLeak’s first demand to indemnify, defend, and hold InfoLeak and its affiliates, licensors, officers, directors, employees, consultants, agents, and representatives (collectively, “affiliates”) harmless from any and all claims, losses, damages, liabilities, actions, or demands, and associated costs and expenses (including without limitation attorneys’ fees) arising out of user’s:</p>
<ul>
    <li>(i) use of the site and/or the content;</li>
    <li>(ii) use of any third party content and/or any other interaction with third parties through the site;</li>
    <li>(iii) violation of the terms hereof; or</li>
    <li>(iv) violation of any third party’s rights.</li>
</ul>
<p>InfoLeak reserves the right, at such user’s expense, to assume the exclusive defense and control of any matter of indemnification by user hereunder. User shall cooperate fully as reasonably required in the defense of any claim.</p>
<br/>
<h2>Disclaimer and warranties</h2>
<p>User understands and agrees that user’s use of InfoLeak’s site and/or content and/or third party content are at user’s own risk. InfoLeak’s site and content and third party content are provided on an “as is” and “as available” basis. InfoLeak and its affiliates expressly disclaim all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of title, merchantability, fitness for a particular purpose, and non-infringement. InfoLeak and its affiliates make no warranty that (i) the site and/or the content and/or the third party content will meet user’s expectations; and (ii) the site and/or the content and/or the third party content will be uninterrupted, timely, secure or error-free. Any content and/or the site and/or third party content is accessed at user’s own discretion and risk, and user will be solely responsible for and hereby waives any and all claims and causes of action with respect to any damage that results from the site, the content, and/or the third party content, and/or the download thereof. No advice or information, whether oral or written, obtained by user from InfoLeak or through or from the site and/or the content and/or the third party content shall create any warranty not expressly stated in the TOS.</p>
<br/>
<h2>Limitation of liability</h2>
<p>In no event shall InfoLeak or its affiliates be liable to user or any third party for any punitive, indirect, incidental, special, consequential, or exemplary damages, whether based on warranty, contract, tort, or any other legal theory, including, but not limited to, damages for loss of profits (even if InfoLeak has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the site and/or the content and/or the third party content; or (ii) any other matter relating to the user’s use of the site and/or the content and/or the third party content or these TOS. Notwithstanding anything to the contrary contained herein, InfoLeak’s total liability to user for any cause whatsoever and regardless of the form of the action, will at all times be limited to US$100. The aforesaid limit shall not be enlarged by the existence of multiple claims. No action arising hereunder regardless of its form may be brought by user more than 40 days after the cause of action has occurred.</p>
<br/>
<h2>Unsolicited idea</h2>
<p>InfoLeak is always pleased to hear from its users, and welcome their comments or suggestions (“ideas”). When InfoLeak refers to an “idea” herein, InfoLeak means: any comment, or suggestion made to InfoLeak. With regard to such ideas user represents and warrants that (i) such ideas are non-confidential and non-proprietary and will be treated as non-confidential and non-proprietary; (ii) InfoLeak is entitled to unrestricted use or disclosure of the ideas for any purpose whatsoever, all without compensation to the user that submitted the idea.</p>
<br/>
<h2>Abiding by jurisdiction</h2>
<p>InfoLeak.io is built to protect the world from threat actors by providing visibility to users and companies on data leaks from various forums. However, it is crucial to note that using the data to gain access to third-party tools is illegal. It is up to the end user to stay within the jurisdiction and use the tool for protecting the data instead of abusing it.</p>
<br/>
<h2>Contact Information</h2>
<p>For any grievances or queries, please contact us at <a href="mailto:contact@infoleak.io">contact@infoleak.io</a>.</p>
<br/>
<h2>Miscellaneous</h2>
<p>To the extent that the site and or the content, or any portion thereof, conflicts or is inconsistent with the TOS, the TOS shall prevail. InfoLeak’s failure to enforce any provision of the TOS shall not be deemed a waiver of such provision nor of the right to enforce such provision. No waiver by InfoLeak of any provision of the TOS shall be deemed a further or continuing waiver of such provision or any other provision, and InfoLeak’s failure to assert any right or provision under the TOS shall not constitute a waiver of such right or provision. If any provision of the TOS is held by a court of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the TOS will continue in full force and effect.
    </p>
</div>
  );
}

export default Termsofservice;