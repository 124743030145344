import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from "react-router-dom";
import { changePassword, setPassword } from '../../api/postDetails';
import logo from '../../assets/images/infoleak_text.svg';
import './SetPassword.css';

function SetPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPasswordError, setShowNewPasswordError] = useState(false);
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
  const [showPasswordMatchError, setShowPasswordMatchError] = useState(false);
  const [showResetError, setShowResetError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(false);
  let { id } = useParams();
  const pass_strength_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,64}$/;
  useEffect(()=>{

  },[])

  function navigateToSignIn() {
    navigate('/login');
  }

  function setNow() {
    checkStrength(newPassword);
    if(passwordStrength){
      return;
    }
    if(newPassword !== '' && confirmPassword !== '' && newPassword === confirmPassword) {
      setShowNewPasswordError(false);
      setShowConfirmPasswordError(false);
      setShowPasswordMatchError(false);
      setPassword({newPassword: newPassword, token: id}).then((response) => {
        navigate('/resetsuccess');
      }).catch(error => { 
        setShowResetError(true);
        setErrorMsg(error.response.data.message);
      })
      
    } else {
      if(!newPassword || newPassword === '') {
        setShowNewPasswordError(true);
      }
      if(!confirmPassword || confirmPassword === ''){
        setShowConfirmPasswordError(true);
      }
      if(newPassword !== confirmPassword) {
        setShowPasswordMatchError(true);
      }
    }
  }

  function newPasswordChange(event) {
    if(event.target.value !== '') {
      setNewPassword(event.target.value);
      setShowNewPasswordError(false);
    } else {
      setShowNewPasswordError(true);
    }
  }

  function confirmPasswordChange(event) {
    if(event.target.value !== '') {
      setConfirmPassword(event.target.value);
      setShowConfirmPasswordError(false);
    } else {
      setShowConfirmPasswordError(true);
    }
  }

  function goToHome(){
    navigate('/');
  }

  function checkStrength(value){
    if(pass_strength_regex.test(value)){
      setPasswordStrength(false);
    } else {
      setPasswordStrength(true);
    }
  }

  return (
    <div className="pre-login-screen">
      <div className="pre-login-screen-header pt-3">
          <div className="col-12 d-flex p-4">
              <div className="col-6 position-relative">
              <img className='cursor-pointer' src={logo} width="150" height="40" onClick={goToHome}/>
              </div>
              <div className="col-6 position-relative text-right d-flex justify-content-end align-items-center">
              <button className='header-btn px-4 py-2' onClick={navigateToSignIn}>Sign In</button>
              </div>
          </div>
      </div>
      <div className="pre-login-content-screen">
          <div className="pre-login-content-block px-4">
              <div className="p-4">
                  <p className='my-2 signin-header'>Set Password</p>
                  <div className='my-4'>
                    <Form.Control required size="lg" type="password" placeholder="Password" onChange={newPasswordChange} onKeyUp={(e)=>checkStrength(e.target.value)} />
                    {showNewPasswordError && <div className='error-msg'>Password is required.</div>}
                    {passwordStrength && <div className='error-msg'>password should be bitween 8 to 16 charecters, atleast one lowercase alphabets, one uppercase alphabets, one numbers and any one of the special charecters !@#$%^&*</div>}
                  </div>
                  <div>
                    <Form.Control size="lg" required type="password" placeholder="Confirm Password" onChange={confirmPasswordChange}/>
                    {showConfirmPasswordError && <div className='error-msg'>Confirm Password is required.</div>}
                  </div>
                  {showPasswordMatchError && <div className='error-msg my-2 text-center'>New Password and Confirm Password doesn't Match.</div>}
                  {showResetError && <div className='error-msg my-2 text-center'>{errorMsg}</div>}
                  <div className='my-4'><button className='create-btn px-4 py-2 w-100' onClick={setNow}>Set Now</button></div>
              </div>
          </div>
          <p className="mb-0 mt-4 text-white w-100 text-center">Know Your Leaks Before They're Exploited!</p>
      </div>
    </div>
  );
}

export default SetPassword;
