import './Domains.css';
import { useNavigate } from "react-router-dom";
import arrows from "../../assets/images/discover_arrows.svg";
import { Button } from 'react-bootstrap';
import { useState, useEffect, memo, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { saveDomains } from '../../api/postDetails';
import { getSubscription, getDomains } from '../../api/getDetails';

const Domains = memo(function Domains(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [newDomain, setNewDomain] = useState('');
  const [domains, setDomains] = useState([]);
  const [userDomains, setUserDomains] = useState([]);
  const [domainsLength, setDomainsLength] = useState(0);

  useEffect(()=>{
    if(user) {
      getSubscription('api/payment/retrive', user.id).then((response) => {
        domains.length = 0;
        for(let i=0;i<response.data.noOfDomains;i++){
          domains.push('');
        }
        setDomains([...domains]);
        console.log(domains);
        getDomainValues();
      }).catch((error) => {
      }
      )
    }
  },[domainsLength])

  const getDomainValues = useCallback(() => {
    getDomains('api/user/get-domains', user.id).then((response) => {
      const userDomains = response.data.domains;
      setUserDomains(userDomains);
      const d = [...domains]
      d.map((item, index) => {
        if(userDomains[index]) {
          d[index] = userDomains[index];
        }
      })
      setDomains(d);
      setDomainsLength(domains.length);
    }).catch((error) => {

    })
  }, [domains])

  // function navigateToPaymet() {
  //   const userDomains = [];
  //   domains.map(dom => {
  //       if(dom !== '') {
  //           userDomains.push(dom);
  //       }
  //   })
  //   if(userDomains.length > 0) {
  //   saveDomains('api/user/save-domains', {userId: user.id, domain: userDomains}).then((response) => {
  //       navigate('/main/leakeddetails');
  //   })
  // } else {
  //   alert('Please enter atleast one domain');
  // }
  // }

  const handleDomainChange = useCallback((event, index) => {
    const data = [...domains];
    data[index] = event.target.value;
    setDomains(data);
  }, [domains])

  const handleNewDomain = useCallback((event) => {
    setNewDomain(event.target.value);
  }, [])

  const addOnDomain = useCallback(() => {
    if(newDomain !== '') {
        const data = domains;
        data.push(newDomain);
        data.push('');
        setDomains(data);
        setDomainsLength(domains.length);
    }
  }, [newDomain, domains])

  return (
    <div className='domains'>
        <br/>
        <div className='col-12 text-left'>
            <p className='mb-0 domain-company-text'>Company</p>
            <p className='mb-0 add-domains-text'>Add your domains to monitor</p>
        </div>
        <br/>
        <div className='col-6 d-flex'>
            <div className='col-12 mx-2 domains-card'>
            <div className='domains-block'>
            {domains.map((item, index) => (
                <div className='col-12 d-flex my-4 align-items-center' key={index}>
                    <Form.Control size="lg" className='mx-2' id={"domain_"+index} type="text" onChange={(event) => handleDomainChange(event, index)} value={item} placeholder="Enter domain here" />
                </div>
            ))}
            </div>
            <hr/>
            <div className='col-12 py-2 d-flex add-on'>
                <div className='col-6'>
                <Form.Control size="lg" className='mx-2' type="text" onChange={handleNewDomain} placeholder="Enter domain here" />
                </div>
                <div className='col-5 mx-2 px-2'>
                <Button className="cursor-pointer text-white add-domain-btn mb-0" onClick={addOnDomain}>Add another domain</Button>
                </div>
            </div>
            </div>
        </div>
        <br/>
        {/* <div className='col-12 text-left'>
            <Button className="cursor-pointer text-white continue-btn mb-0" onClick={navigateToPaymet}>Continue<img src={arrows} height="15" width="15" className='mx-2'></img></Button>
        </div> */}
    </div>
  );
})

export default Domains;
