import React, { memo, useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getHits } from '../../api/getDetails';
import downArrow from '../../assets/images/down_arrow.svg';
import upArrow from '../../assets/images/up_arrow.svg';
import LoaderComponents from '../loader/loader';
import PagenationComponent from '../pagination/pagination';
import './leakedFiles.css';

const LeakedFiles = memo(function LeakedFiles(props) {

  const [leakedFiles, setLeakedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedCount, setExpandedCount] = useState(0);
  const [selectedRepo, setSelectedRepo] = useState(null);

  useEffect(() => {
    if(selectedRepo != props.repo) {
      setSelectedRepo(props.repo);
      loadFiles(0);
    } else {
      setLeakedFiles(leakedFiles);
    }
  },[props.repo, expandedCount]);

  function loadFiles(currentPageNUmber) {
    setLoading(true);
    let url = 'api/paste/search-hits?q='+props.domain+'&repo='+props?.repo?.val+'&page='+currentPageNUmber;
    getHits(url).then((response) => { 
      const loadedFiles = response.data.hits;
      if(loadedFiles?.length > 0) {
        loadedFiles[0].enabledExpanded=true;
      }
      setLeakedFiles(loadedFiles);
      setFilesCount(response?.data?.total);
      setLoading(false);
    }).catch((error) => {
      setLeakedFiles([]);
      setFilesCount(0);
      console.log(error);
      setLoading(false);
    });
  }

  const pageChanged = useCallback((pageEvent) => {
    setCurrentPage(pageEvent.currentPage);
    setTimeout(()=>{
      loadFiles(pageEvent.currentPage);
    })
  }, [])

  const updateEnabled = useCallback((item, ind) => {
    leakedFiles.map((obj, index) => {
      if(index == ind) {
        obj.enabledExpanded=!item.enabledExpanded;
        setExpandedCount(expandedCount+1);
      } else {
        obj.enabledExpanded=false;
      }
    })
  }, [leakedFiles, expandedCount])

  return (
    <div className='mt-4 repo-files'>
      <div className='col-12'>
        <p className='mb-0 repo-text'><span className={'mx-2 repo-text-badge '+(props?.repo?.repoColor)}>{props?.repo?.val.slice(0,1).toUpperCase()}</span>{props?.repo?.val}</p>
      </div>
      <div className='col-12 mt-4'>
        <div className='files-table position-relative'>
          {loading ? <LoaderComponents/>:<>
          {leakedFiles && leakedFiles.length > 0 && <Table >
            <thead >
              <tr key="leakedFilesHeader_0">
                <th className="w-35 px-2">File</th>
                <th className="w-15 px-2">Keyword</th>
                <th className="w-25 px-2">Repo</th>
                <th className="w-15 text-center">Total Matches</th>
                <th className="w-10 text-center"></th>
              </tr>
            </thead>
            <tbody className='mt-2'>
              {leakedFiles && leakedFiles.map((item, index) => (
                <>
                  <tr key={index} className={item.enabledExpanded ? 'tr-highlight' : ''}>
                    <td className={"w-35 "+ (item.enabledExpanded ? 'px-3' : 'px-2')}><p className="mb-0 cursor-pointer"><a className='file-name' href={'https://github.com/'+item?.repo?.raw+'/blob/'+(item?.branch ? item?.branch?.raw : 'master')+'/'+item?.path?.raw} target='_blank'>{item?.path?.raw}</a></p></td>
                    <td className="w-15 px-2"><p className="mb-0">{props?.domain}</p></td>
                    <td className="w-25 px-2"><p className="mb-0">{props?.repo?.val}</p></td>
                    <td className='w-15 px-2 text-center'><p className="mb-0">{item?.total_matches?.raw}</p></td>
                    <td className={"w-10 "+ (item.enabledExpanded ? 'px-2' : '')}>
                      <img src={item.enabledExpanded ? upArrow : downArrow} height="25" width="25" className="cursor-pointer" onClick={() => updateEnabled(item, index)} alt="logo" />
                    </td>
                  </tr>
                  {item.enabledExpanded &&
                    <tr colSpan="" key={'child'+index} className={item.enabledExpanded ? 'tr-highlight' : ''}>
                      <td colSpan="5">
                        <div className='file-row-snippet' id={"content_"+index} dangerouslySetInnerHTML={{ __html: item?.content?.snippet }}>
                        </div>
                      </td>
                    </tr>
                  }
                </>
              ))}
            </tbody>
          </Table>}
          {leakedFiles.length == 0 &&
            <div className='no-hits-found'><h2>Congratulations! No issues found.</h2></div>
          }
          </>
          }
        </div>
        {filesCount > 0 && 
        <div className="pt-2"><PagenationComponent count={filesCount} pageSize={10} currentPage={currentPage} pageChange={(event)=>{
          pageChanged(event);
        }}/></div>}
      </div>
    </div>
  );
})
export default LeakedFiles;