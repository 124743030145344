import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { forgotPassword } from '../../api/postDetails';
import logo from '../../assets/images/infoleak_text.svg';
import './ForgotPassword.css';

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);

  useEffect(()=>{
  },[])

  function navigateToSignIn() {
    navigate('/login');
  }

  function sendResetLink() {
    if(email !== '') {
      setShowEmailError(false);
      forgotPassword({email: email}).then((response) => { 
        navigate('/resetlink');
      }).catch(error => { 
        setShowEmailError(true);
      })
    } else {
      if(!email || email === '') {
        setShowEmailError(true);
      }
    }
  }

  function emailChange(event) {
    if(event.target.value !== '') {
      setEmail(event.target.value);
      setShowEmailError(false);
    } else {
        setShowEmailError(true);
    }
  }
  function goToHome(){
    navigate('/');
  }

  return (
    <div className="pre-login-screen">
      <div className="pre-login-screen-header pt-3">
          <div className="col-12 d-flex p-4">
              <div className="col-6 position-relative">
              <img className='cursor-pointer' src={logo} width="150" height="40" onClick={goToHome}/>
              </div>
              <div className="col-6 position-relative text-right d-flex justify-content-end align-items-center">
              <button className='header-btn px-4 py-2' onClick={navigateToSignIn}>Sign In</button>
              </div>
          </div>
      </div>
      <div className="pre-login-content-screen">
          <div className="pre-login-content-block px-4">
              <div className="p-4">
                  <p className='my-2 signin-header'>Forgot Password</p>
                  <div className='my-4'>
                    <Form.Control required size="lg" type="text" placeholder="Email" onChange={emailChange} />
                    {showEmailError && <div className='error-msg'>Email is required.</div>}
                  </div>
                  <div className='my-4'><button className='create-btn px-4 py-2 w-100' onClick={sendResetLink}>Send Reset Password link</button></div>
              </div>
          </div>
          <p className="mb-0 mt-4 text-white w-100 text-center">Know Your Leaks Before They're Exploited!</p>
      </div>
    </div>
  );
}

export default ForgotPassword;
