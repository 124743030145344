import './SubscriptionFailed.css';
import { useNavigate } from "react-router-dom";
import checkIcon from "../../assets/images/check-circle.svg";
import { Button } from 'react-bootstrap';
import { memo, useCallback } from 'react';

const SubscriptionFailed = memo(function SubscriptionFailed() {

  const navigate = useNavigate();

  const navigateToPayments = useCallback(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    navigate('/main/chooseplan');
  }, [])

  return (
    <div className='sub-failed'>
      <br/>
      <br/>
      <br/>
      <div className='col-12 d-flex'>
      <div className='col-2'></div>
      <div className='col-8 success-card p-4 d-grid justify-content-center'>
        <div className='col-12 d-flex align-items-center mt-2'>
            <p className='mb-0'><img src={checkIcon} height="35" width="35"/></p>
            <p className='mx-2 mb-0 success-msg'>Failed!</p>
        </div>
        <div className='col-12 my-4 text-center'>
            <p className='mb-0 active-msg'>Something went wrong please try again!</p>
            {/* <p className='mb-0 active-msg'>will expire on 18/04/2025</p> */}
        </div>
        <div className='col-12 py-4 text-center'>
            <Button className="cursor-pointer text-white goto-btn mb-0" onClick={navigateToPayments}>Try again!</Button>
        </div>
      </div>
      <div className='col-2'></div>
      </div>
    </div>
  );
})

export default SubscriptionFailed;
