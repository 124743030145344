import './cancel.css';
import React from 'react';
import { useNavigate } from "react-router-dom";
function Cancel() {
    const navigate = useNavigate();
    function navigateHome(){
navigate('/');
    }
    return (
        <div className='full-width'>
            <div className='center-alligned'>
            <h1>Subscription Cancelled</h1>
            <button className='home-button' onClick={navigateHome}>Go to home</button>
            </div>
        </div>
    );
}

export default Cancel;
