import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './pageNotFound.css';
import logo from '../../assets/images/infoleak_text.svg'

function PageNotFoundComponent() {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('user')));

    function goToHome(){
        navigate('/');
    }

    function navigateToSignIn() {
        navigate('/login');
      }

    return (
        <div className={userDetails ? '' : 'pre-login-screen'}>
        {!userDetails && <div className="pre-login-screen-header pt-3">
          <div className="col-12 d-flex p-4">
              <div className="col-6 position-relative">
              <img className='cursor-pointer' src={logo} width="150" height="40" onClick={goToHome}/>
              </div>
              <div className="col-6 position-relative text-right d-lg-flex justify-content-end align-items-center disp-mob-none">
                  <p className="cursor-pointer text-white mx-3 mb-0 account-link">Already have account?</p>
                  <button className='header-btn px-4 py-2' onClick={navigateToSignIn}>Sign In</button>
              </div>
          </div>
        </div>}
        <div className="page-not-found">
            <div className="text-center">
            <p className="p1">404</p>
            <p className="p2">Page Not Found</p>
            <p className="p3">The Page you are looking for does not exist or temporarily unavailable.</p>
            <button className='home-btn px-4 py-2' onClick={goToHome}>Go To Home</button>
            </div>
        </div>
        </div>
      );
}

export default PageNotFoundComponent;