import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import arrows from "../../assets/images/discover_arrows.svg";
import './sendemail.css';
import { useNavigate } from "react-router-dom";
import { sendInvite } from './../../api/getDetails';

const SendEmail = memo(function SendEmail() {

    const [orgName, setOrgName] = useState('');
    const [orgNameInValid, setOrgNameInValid] = useState(false);
    const [domain, setDomain] = useState('');
    const [domainInValid, setDomainInValid] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [emailIdInValid, setEmailIdInValid] = useState(false);
    const [showDomainsPopUp, setShowDomainsPopUp] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const domainRef = useRef();
    const emailRef = useRef();
    const orgRef = useRef();
    const navigate = useNavigate();

    function sendEmail() {
        if(!orgName){
            setOrgNameInValid(true);
            return;
        }
        if(!domain){
            setDomainInValid(true);
            return;
        }
        if(!emailId){
            setEmailIdInValid(true);
            return;
        }
        setOrgNameInValid(false);
        setDomainInValid(false);
        setEmailIdInValid(false);
        sendInvite(emailId, domain, orgName).then((response) => {
            if(response.status == 200){
                setDomain('');
                setOrgName('');
                setEmailId('');
                domainRef.current.value = '';
                emailRef.current.value = '';
                orgRef.current.value = '';
                setShowDomainsPopUp(true);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleClose = useCallback(() => {
      setShowDomainsPopUp(false);
    }, [])

    //   useEffect(()=>{
    //     setDomain(domain);
    //     setOrgName(orgName);
    //     setEmailId(emailId);
    // },[emailId, domain, orgName])

  return (
    <div>
    {(user && user.role=='ADMIN') ? (
    <div className='white-font m-4 py-4 invite-block'>
        <div className='text-center '>
      <h1>Send a Invite Email!</h1>
      </div>
      <div className="container">
        <div className='row'>
            <div className='col-md-6 col-lg-4'>
        <div className="row">
            <div className="col-12">
                <p className='mb-1 mt-2'>Organization Name</p>
            <Form.Control ref={orgRef} size="lg" className='mobile' required type="text" placeholder="Organization Name"  onKeyUp={(e)=>{setOrgName(e.target.value);setOrgNameInValid(false)}}/>
            {orgNameInValid&&<p className='danger-font'>Please enter Organization Name</p>}
            </div>
            <div className="col-12 my-3">
            <p className='mb-1 mt-2'>Domain</p>
            <Form.Control ref={domainRef} size="lg" className='mobile' required type="text" placeholder="Domain" onKeyUp={(e)=>{setDomain(e.target.value); setDomainInValid(false)}}/>
            {domainInValid&&<p className='danger-font'>Please enter Domain name</p>}
            </div>
            <div className="col-12">
            <p className='mb-1 mt-2'>EmailID</p>
            <Form.Control ref={emailRef} size="lg" className='mobile' required type="email" placeholder="EmailID" onKeyUp={(e)=>{setEmailId(e.target.value); setEmailIdInValid(false)}}/>
            {emailIdInValid&&<p className='danger-font'>Please enter a valid EmailID</p>}
            </div>
            <div className="col-12 add-on mt-2">
                <br/>
            <Button className="cursor-pointer text-white continue-btn mb-0" onClick={sendEmail}>Send Invite<img src={arrows} height="15" width="15" className='mx-2'></img></Button>
            </div>
        </div>
        </div>
        </div>
      </div>
      <Modal show={showDomainsPopUp} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Invite sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='center-align text-center'>
                 <p className='font-white'>Invite Sent!</p>
                 </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
    </div>):(<div className='white-font w-100 invite-access d-grid justify-content-center align-items-center'><h1>You dont have to access this page.</h1></div>)}
    </div>
  );
})

export default SendEmail;