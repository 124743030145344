import './privacypolicy.css'

function PrivacyPolicy() {

    return (
<div className='back-black privacy-policy'>
    <h1 className='font-53'>Privacy Policy</h1>
    <br/>
    <h2>Introduction</h2>
      <p>
        Welcome to InfoLeak.io! At InfoLeak, Ltd. ("InfoLeak," "we," "our," or "us"), we are committed to safeguarding your privacy and protecting your data. Our mission is to protect the world from threat actors by providing visibility to users and companies on data leaks from various sources. This Privacy Policy outlines how we collect, use, store, and share your information when you access or use our website at <a href="https://infoleak.io/">https://infoleak.io/</a>.
      </p>
      <br/>
      <h2>Subprocessors</h2>
      <p>
        We utilize multiple subprocessors to provide our services effectively. These subprocessors may have access to your data for specific purposes outlined in this Privacy Policy. Our subprocessors include:
      </p>
      <ul>
        <li><strong>Amazon Web Services (AWS):</strong> We use AWS for hosting our platform and storing data. AWS adheres to industry-standard security measures to protect your data.</li>
        <li><strong>Microsoft Clarity:</strong> We use Microsoft Clarity for analytics to improve user experience on our Site. Microsoft Clarity collects data such as user interactions and behavior on the Site.</li>
      </ul>
      <br/>
      <h2>Data Usage and Legal Responsibility</h2>
      <p>
        We aggregate data available in the dark web through our channel partners. We are not responsible for information theft or any consequences resulting from your actions using the data.
      </p>
      <br/>
      <h2>Information We Collect</h2>
      <p>
        We collect both personal identifiable information ("PII") and non-personally identifiable information ("Non-PII"). PII provided by you may include information such as your name, email address, phone number, professional background, and any additional information you provide via the "Contact Us" field. You're not obligated to provide PII, but not doing so may limit your use of certain services.
      </p>
      <br/>
      <h2>Data Collected by Third Parties</h2>
      <p>
        The Site may contain links to third-party services. Our Privacy Policy doesn't apply to these third parties. Please review their privacy policies. Clicking on third-party links may result in cookies being sent to you. We're not responsible for the privacy practices of these third parties.
      </p>
      <br/>
      <h2>Google Analytics</h2>
      <p>
        We use Google Analytics. Learn more about their data collection and processing practices at <a href="www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a>.
      </p>
      <br/>
      <h2>How We Use Your Information</h2>
      <p>
        We may use your information for various purposes, including providing services, communicating with you, administering the Site, conducting research, and preventing fraud. We may disclose your information as required by law, to verify information, prevent fraud, ensure safety, address inappropriate conduct, or in connection with business transactions. When sharing your data with third parties, we ensure they adhere to this Privacy Policy and applicable laws.
      </p>
      <br/>
      <h2>Accessing Your Information</h2>
      <p>
        You may have rights to access, correct, or delete your personal data. Contact us for assistance.
      </p>
      <br/>
      <h2>Data Security</h2>
      <p>
        We implement measures to protect your information, but no method is 100% secure.
      </p>
      <br/>
      <h2>Preventing Identity Theft</h2>
      <p>
        Be cautious of emails requesting personal information purportedly from us. Forward suspicious emails to <a href="mailto:contact@infoleak.io">contact@infoleak.io</a>.
      </p>
      <br/>
      <h2>Users in the European Economic Area (EEA)</h2>
      <p>
        We are compliant with the General Data Protection Regulation (GDPR) to ensure your data privacy rights are protected. We process your personal data based on legal grounds outlined in this Privacy Policy. You have rights regarding your data, and we facilitate exercising those rights.
      </p>
      <br/>
      <h2>Transfer of Your Personal Data</h2>
      <p>
        Your data may be transferred, processed, and stored outside your jurisdiction. By using our Site, you consent to this transfer.
      </p>
      <br/>
      <h2>Compliance with Children's Online Privacy Protection Act</h2>
      <p>
        Our Site isn't for minors under 18. We won't knowingly collect their information without parental consent.
      </p>
      <br/>
      <h2>International Storage</h2>
      <p>
        Your data may be stored outside your jurisdiction, subject to differing data protection laws.
      </p>
      <br/>
      <h2>Law and Jurisdiction</h2>
      <p>
        This Privacy Policy is governed by Israeli law, and any disputes will be subject to the jurisdiction of the courts of Tel Aviv, Israel.
      </p>
      <br/>
      <h2>Contact Us</h2>
      <p>
        For questions about this Privacy Policy or our privacy practices, contact us at <a href="mailto:contact@infoleak.io">contact@infoleak.io</a>.
      </p>
</div>
    )}

    export default PrivacyPolicy;