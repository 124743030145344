import { memo, useCallback, useEffect, useState } from 'react';
import './PreSubscriptionSearch.css';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import arrows from "../../assets/images/discover_arrows.svg"
import { useNavigate } from "react-router-dom";
import { getInfoleaks, getSubscription } from '../../api/getDetails';
import { getSevenData } from '../../api/getDetails';
import RecorderCount from '../../components/recordcount/recordcount.component';
import moment from 'moment';
import LoaderComponents from '../../components/loader/loader';
import { connect } from "react-redux";

const PreSubscriptionSearch = memo( function PreSubscriptionSearch({user, 
  subscriptionStatus, 
  subscriptionType, saveUser, savePayment}) {

  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('user')));
  const navigate = useNavigate();
  const [placeholder, setPlaceholder] = useState('Search by Domain/Email')
  const [searchBy, setSearchBy] = useState('DOMAIN');
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState('');
  const [leakedDetailsData, setLeakedDetailsData] = useState([]);
  const [count, setCount] = useState(0);
  const [subscribtionType, setSubscribtionType] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(()=>{
    if(userDetails && userDetails?.id) {
      setSearchPerformed(true);
      if(subscriptionStatus){
        setSubscribtionType(subscriptionStatus);
      }else{
    getSubscription('api/payment/retrive', userDetails?.id).then((response) => {
      savePayment(response);
      setSubscribtionType(response.data.subscriptionStatus);
    }).catch(error => {
      console.log(error);
    })
  }
  }
    getInfoleaks('api/infoleaks').then((response) => {
      // setData(response.data);
    }).catch(error => {
      console.log(error);
    })
  },[])

  const handleSearchBy = useCallback((event) => {
    setSearchBy(event.target.value);
    setData(null);
    setLeakedDetailsData([]);
    const element = document.getElementById('search-input');
    element.value = '';
    setSearchValue('');
    if(event.target.value === 'DOMAIN') {
      setPlaceholder('Search by domain');
    } else {
      setPlaceholder('Search by email');
    }
  }, [])

  const assignSearchValue = useCallback((event) => {
    setSearchValue(event.target.value);
  }, [])

  const goToSignUp = useCallback(() => {
    navigate('/signup');
  }, [])

  const goToSubscribe = useCallback(() => {
    navigate('/main/chooseplan');
  }, [])

  const getCompromisedDate = useCallback((date) => {
    if(!date){
      return 'N/A';
    }
    if((moment(new Date(), "YYYY-MM-DDTHH:mm:ss.SSSSZ").diff(moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ"), 'days')) >= 0) return moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format('DD MMM, yyyy');
    const new_date = moment(date, "YYYY-DD-MMTHH:mm:ss.SSSSZ").format('DD MMM, yyyy');
    return (moment(new Date(), "YYYY-DD-MMTHH:mm:ss.SSSSZ").diff(new_date, 'days')) >= 0 ? moment(new_date).format('DD MMM, yyyy'):'N/A';
  }, [])

  const goToSearchPage = useCallback(() => {
    if(userDetails && subscribtionType === 'active'){
    navigate('/main/leakeddetails');
    } else {
      navigate('/signup');
    }
  }, [userDetails])

  const navigateToPayment = useCallback(() => {
    if(userDetails && userDetails.id){
      navigate('/main/chooseplan')
    } else {
      navigate('/signup');
    }
  }, [userDetails])

  const validateEmail = useCallback(() => {
    const email = searchValue;
    if (/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(email)) {
      return true;
    }
    return false;
  }, [searchValue])

  const validateDomain = useCallback(() => {
    const domain = searchValue;
    const ext = domain.split('.').length > 1 ? domain.split('.')[domain.split('.').length - 1] : '';
    if(ext?.length < 2) return false;
    return true;
  }, [searchValue])

  const handlePopup = useCallback((showPopup, popupMsg) => {
    setShowErrorPopup(showPopup);
    setErrorMessage(popupMsg);
  }, [])

  const search = useCallback(() => {
    // if(!userDetails || subscribtionType === 'inactive'){
      if(searchValue && searchValue.trim() != '') {
        const searchedVal = searchValue.indexOf('@')>-1 ? 'EMAIL' : 'DOMAIN';
        setSearchBy(searchedVal);
        if(searchedVal == 'EMAIL' && !validateEmail()) {
          handlePopup(true, "Email you have entered is invalid.");
        } else if(searchedVal == 'DOMAIN' && !validateDomain()) {
          handlePopup(true, "Domain you have entered is invalid.");
        } else {
          setLoader(true);
          setSearchPerformed(true);
          getSevenData('api/infoleaks/domain', searchValue).then((response)=>{
            const d = response.data.data.splice(0,5)
            if(!userDetails){
              d.map((item) => {
                item['username'][0] = item['username'][0].slice(0,2)+"*".repeat(item.username[0].length-(item.username[0].split('.')[item.username[0].split('.').length-1]).length+3) +'.'+item.username[0].split('.')[item.username[0].split('.').length-1];
              })
            }
            setLeakedDetailsData(d);
            setCount(response.data.numDocs);
            setData({usersCount: response.data.usersCount, employeesCount: response.data.employeesCount, thirdPartyCredentialsCount: response.data.thirdPartyCredentialsCount})
            setLoader(false);
          }).catch((error)=>{
            setLoader(false);
            if(userDetails && userDetails?.id) {
              setSearchPerformed(true);
            } else {
              setSearchPerformed(false);
            }
          })
        }
      }
    // }
  //   else {
  //   if(searchValue.split('@').length === 1) {
  //   navigate('/main/leakeddetails/'+searchValue);
  //   } else {
  //   const domainName = searchValue.split('@')[1];
  //   navigate('/main/leakeddetails/'+domainName);
  //   }
  // }
  }, [searchValue, userDetails])

  const goToPaymentOptions = useCallback(() => {
    navigate('/main/chooseplan');
  }, [])
  
  return (
      <div className='col-12 min-height-full search-container'>
      <div className={'pt-4 '+(searchPerformed ? 'search-block' : '')}>
        <div className='search-section justify-content-center align-items-center mt-4 pre-subscription'>
          {/* <div className='w-156 disp-none-mobile'>
            <Form.Select size="lg" onChange={handleSearchBy}>
              <option value="DOMAIN">Domain</option>
              <option value="EMAIL">Email</option>
            </Form.Select>
          </div> */}
          <div className='mx-md-4 mx-lg-0 mx-0 w-50 w-md-100 full-width-mobile margin-right-n1'>
            <Form.Control size="lg" id='search-input' className='search-field' type="text" onKeyUp={assignSearchValue} placeholder={placeholder} />
          </div>
          <div className={(searchValue?.length > 0 ? 'search-btn-enable' : 'search-btn-disable') + ' full-width-mobile'}>
            <Button onClick={search}>Search</Button>
          </div>
        </div>
        {searchPerformed && 
        <>
        <br/>
        <div className='container'>
        <div className='col-12 mt-4 pt-4 d-flex'>
          <div className={((!userDetails || (subscribtionType?.toLowerCase() == 'active' || subscribtionType?.toLowerCase() == 'trialing'))?'col-3':'disp-none') + ' disp-none-mobile'}></div>
          <div className='col-lg-6 col-md-12'>
            {searchBy=='DOMAIN'&&<div className='row'>
            <div className='col-md-12 col-lg-4 px-4 position-relative'>
            {loader ? (<LoaderComponents/>):(<div>
              <p className='mb-2 search-count'><RecorderCount recordCount={data?.usersCount}/></p>
              <div className='mobile-flex'>
              <p className='mb-0 search-count-text'>Users</p>
              <p className='mb-0 search-count-text'>leaked</p>
              </div>
              </div>)}
            </div>
            <div className='col-md-12 col-lg-4 px-4 position-relative'>
            {loader ? (<LoaderComponents/>):(<div>
              <p className='mb-2 search-count'><RecorderCount recordCount={data?.employeesCount}/></p>
              <div className='mobile-flex'>
              <p className='mb-0 search-count-text'>Corporate Credentials</p>
              <p className='mb-0 search-count-text'>leaked</p>
              </div>
              </div>)}
            </div>
            <div className='col-md-12 col-lg-4 px-4 position-relative'>
            {loader ? (<LoaderComponents/>):(<div>
              <p className='mb-2 search-count'><RecorderCount recordCount={data?.thirdPartyCredentialsCount}/></p>
              <div className='mobile-flex'>
              <p className='mb-0 search-count-text'>Third Party Credentials</p>
              <p className='mb-0 search-count-text'>leaked</p>
              </div>
              </div>)}
            </div>
            </div>}
          </div>
          <div className={'disp-none-mobile '+((!userDetails || (subscribtionType?.toLowerCase() == 'active' || subscribtionType?.toLowerCase() == 'trialing'))?'col-3':'disp-none')}></div>
          {(userDetails && (subscribtionType?.toLowerCase() != 'active' && subscribtionType?.toLowerCase() != 'trialing')) && <div className='col-6 px-4' onClick={goToSubscribe}>
          <span className='subscribe-signup-btn cursor-pointer' onClick={goToPaymentOptions}>Subscribe to view full data set <img src={arrows} height="15" width="15" className='mx-4 cursor-pointer'></img></span>
          </div>}
        </div>
        </div>
        {/* <div className='my-4 text-center col-12'>
          <p className='pt-4 last-days-data'>Last 7 days data</p>
        </div> */}
        
        <div className="col-12 pt-4">
        {/* {(leakedDetailsData?.length === 0) && <p className="text-center text-white">No data found</p>} */}
        {((leakedDetailsData?.length) > 0 || loader) &&
        <div className='height-75 px-4 position-relative'>
        {loader ? (<LoaderComponents/>):
        (<Table>
          <thead>
            <tr>
              
              <th className="wid-35 px-2">URLS</th>
              <th className="wid-20 px-2">Login</th>
              <th className="wid-10">Password</th>
              <th className="wid-15 px-2">IP</th>
              <th className="wid-20 px-1">Comprimised date</th>
            </tr>
          </thead>
          <tbody className='leaks-table-body'>
            {leakedDetailsData.map((item, index) => (
              <>
                <tr className='tr' key={index}>
                 
                  <td className="px-2 wid-35">{item?.url ? item?.url : 'NA'}</td>
                  <td className="px-2 wid-20">{item?.username ? item?.username : 'NA'}</td>
                  <td>
                    <p className="mb-0 wid-10">{item?.password ? item?.password.slice(0, 60)+(item?.password.length > 60 ? '...' : '') : 'NA'}</p>
                    <p className={`mb-0 ${item?.passwordStrength}`}>{item?.passwordStrength}</p>
                  </td>
                  <td className="px-2 wid-15">{item?.ip ? item?.ip : 'NA'}</td>
                  <td className="px-1 wid-20">{getCompromisedDate(item?.date_compromised)}</td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>)}
        </div>
        }
      </div>
      {(leakedDetailsData?.length > 0) &&
        <div className='text-center col-12'>
          <p className='more-data-found mb-0 curser-pointer' onClick={navigateToPayment}>{count > 4 ? count - 4 : 0} More data sets are found</p>
        </div>
}
        {!userDetails && 
          <div>
        <br/>
        <br/>
        <div className='row d-flex px-4'>
          <div className='col-md-12 col-lg-6 px-4 py-1' onClick={goToSignUp}>
          <span className='subscribe-signup-btn cursor-pointer'>Subscribe + Sign up to view full data set <img src={arrows} height="15" width="15" className='mx-2 cursor-pointer'></img></span>
          </div>
          <div className='col-md-12 col-lg-6 px-4 py-1' onClick={goToSignUp}>
          <span className='subscribe-signup-btn cursor-pointer'>Sign up to view masked data <img src={arrows} height="15" width="15" className='mx-2 cursor-pointer'></img></span>
          </div>
        </div>
        <div className='col-12 d-flex my-3 px-4'>
          <div className='col-6 px-4 text-center'>
            {/* <p className='mb-0 found-data-sets'>3.2Mn data sets of “Darwinbox.com”</p> */}
          </div>
          <div className='col-6 px-4 text-center'>
            {/* <p className='mb-0 found-data-sets'>304 data sets from past 7 days</p> */}
          </div>
        </div>
        </div>
}
        <div className='col-12 mt-4 data-bottom-section py-2 px-4'>
        This Isn't Just Data Clutter - It's Real-Time Intelligence.
        </div>
        </>
}
      </div>
      <Modal show={showErrorPopup} className='alert-modal'>
          <Modal.Header>
            <div className='col-12 export-header d-flex justify-content-between'>
              <div className="modal-title h4">Alert</div>
              <div className='d-flex'>
              <Button onClick={() => handlePopup(false, "")}>X</Button>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
              <div className='w-100 text-center'>
                <p className='mb-0'>{errorMessage}</p>
              </div>
          </Modal.Body>
        </Modal>
      </div>
  );
})
function mapStateToProps(state) {
  return {
    user: state.user.user,
    subscriptionStatus: state.user.subscriptionStatus,
    subscriptionType: state.user.subscriptionType,
  }
}
const mapDispatchToProps = (dispatch) => ({
  saveUser: (payload) => dispatch({ type: "user", payload: payload }),
  savePayment: (payload) => dispatch({ type: "subscription", payload: payload })
});
export default connect(mapStateToProps, mapDispatchToProps)(PreSubscriptionSearch);
