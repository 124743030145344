import { useState } from 'react';
import arrows from "../../assets/images/discover_arrows.svg";
import './LandingSearch.css';
import PreSubscriptionSearch from '../PreSubscriptionSearch/PreSubscriptionSearch';
import whiteArrow from "../../assets/images/white-go.png";

function LandingSearch() {
  const [discoverClicked, setDiscoverClicked] = useState(true);

  function showSearch() {
    setDiscoverClicked(true);
  }

  return (
    <div className='col-12 landing-search'>
      <br className='disp-mb-none'/>
      <br className='disp-mb-none'/>
      <br className='disp-mb-none'/>
      <br className='disp-mb-none'/>
      <br/>
      <div className='col-12 text-center'>
        <div className='d-flex justify-content-center'><p className='line-text-1 gradient-text'>Real-Time Visibility into Data Leakage for Unmatched Security.</p></div>
        <div className='line-text-2 my-4'>
        <p className='mb-0'>Uncover Data Leaks, </p>
        <p className='mb-0'>Protect Your Business!</p>
        </div>
        <p className='line-text-3 mb-0'>Empowering Organizations to Safeguard Against </p>
        <p className='line-text-3 mb-0'>Information Theft and Secure Their Future</p>
      </div>
      <br/>
      {!discoverClicked && 
      <div className='text-center my-4'>
        <span className='discover-btn cursor-pointer' onClick={showSearch}>Discover Your Data Leaks Now <img src={arrows} height="15" width="15" className='mx-2 cursor-pointer hide-on-hover'/><img src={whiteArrow} height="15" width="15" className='mx-2 cursor-pointer d-none d-on-hover'/></span>
      </div>}
      {discoverClicked && 
        <PreSubscriptionSearch />
      }
      <br className='disp-mb-none'/>
      <br className='disp-mb-none'/>
      <br className='disp-mb-none'/>
      <br className='disp-mb-none'/>
      <br className='disp-mb-none'/>
      <br/>
      <></>
    </div>
  );
}

export default LandingSearch;
