export const baseUrl = "/";
//export const baseUrl = "https://www.infoleak.io/";
export const baseUrlDocs = "";
//export const brandScoreUrl = "https://www.infoleak.io/domain-risk-api/v1/";
export const brandScoreUrl = "http://103.210.72.73:8080/";
export const builtWith = "https://api.builtwith.com/free1/api.[xml|json]?KEY=c9c6c62a-9f63-4872-a1ca-6be099670ebe&LOOKUP=";
export const clearbitUrl = 'https://autocomplete.clearbit.com/v1/companies/suggest?query=';


//TEST
//export const PAYPAL_CLIENT_ID = 'AazxNE6-YEQh-oIV9WScHg_6AemqbBKPrwtundUGXtgI_UxZH2iRdNtjWI_m_muKoyK0tNi7xN0dIuvi';
//export const RAZOR_KEY_ID = 'rzp_test_3xInuj2MaVY237';

//PROD
export const PAYPAL_CLIENT_ID = 'AZlFd1ww6rgD1v5nHwnJn5-0ABsG1UH5ob78zDyx6GxBPZifocys9t1CCUC2XUBNAvOysJlblx7KVrbU';
export const RAZOR_KEY_ID = 'rzp_live_9sl6fKXedCON6l';
