import { memo, useCallback, useEffect, useState } from 'react';
import { Form, Table } from "react-bootstrap";
import { getHits, getRepos } from '../../api/getDetails';
import downArrow from '../../assets/images/down_arrow.svg';
import upArrow from '../../assets/images/up_arrow.svg';
import LoaderComponents from '../loader/loader';
import PagenationComponent from '../pagination/pagination';
import './leakedReposMobile.css';

const LeakedReposMobile = memo(function LeakedReposMobile(props) {

    const [repos, setRepos] = useState([]);
    const [repoColors, setRepoColors] = useState(['green', 'purple', 'orange', 'yellow', 'green', 'purple', 'orange', 'yellow']);
    const [selectedRepo, setSelectedRepo] = useState(null);
    const [tabCount, setTabCount] = useState(0);
    const [leakedFiles, setLeakedFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filesCount, setFilesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [expandedCount, setExpandedCount] = useState(0);
    const [searchPerformed, setSearchperformed] = useState(false);

    useEffect(() => {
        if(expandedCount == 0) {
            loadRepos('');
        } else {
            setLeakedFiles(leakedFiles);
            setRepos(repos);
        }
    },[expandedCount]);

    function loadRepos(searchVal) {
        let url = 'api/paste/search-repositories?q='+props.domain;
        if(searchVal.trim().length > 0) {
            url += '&pattern='+searchVal;
        }
        getRepos(url).then((response) => { 
            response?.data?.buckets?.map((bucket, index) => {
                bucket["repoColor"] = repoColors[index];
            })
            setTabCount(0);
            setRepos(response.data.buckets);
            if(response?.data?.buckets?.length > 0) {
                if(searchVal == '') {
                    props.reposCount(response?.data?.buckets?.length);
                }
            }
        });
    }

    const handleSearch = useCallback((e) => {
        if(e.keyCode === 13 && e?.target?.value?.trim() != '') {
          setSearchperformed(true);
            loadRepos(e.target.value);
        } else if(e?.target?.value?.trim() == '' && searchPerformed){
          setSearchperformed(false);
            loadRepos('');
        }
    }, [searchPerformed])

    const goPrev = useCallback(() => {
        if(tabCount > 0) {
            const count = tabCount-1;
            setTabCount(count);
            setSelectedRepo(repos[count]);
            loadRepoFiles(repos[count]);
        }
    }, [tabCount, repos])

    const goNext = useCallback(() => {
        if(tabCount < repos?.length-1) {
            const count = tabCount+1;
            setTabCount(count);
            setSelectedRepo(repos[count]);
            loadRepoFiles(repos[count]);
        }
    }, [tabCount, repos])

    const loadRepoFiles = useCallback((repo) => {
        setLoading(true);
        let url = 'api/paste/search-hits?q='+props.domain+'&repo='+repo?.val+'&page='+currentPage;
        getHits(url).then((response) => { 
          const loadedFiles = response.data.hits;
          if(loadedFiles?.length > 0) {
            loadedFiles[0].enabledExpanded=true;
          }
          setLeakedFiles(loadedFiles);
          setFilesCount(response?.data?.total);
          setLoading(false);
        }).catch((error) => {
        console.log(error);
        setLoading(false);
        });
    }, [currentPage])

    const pageChanged = useCallback((pageEvent) => {
        setCurrentPage(pageEvent.currentPage);
        loadRepoFiles(selectedRepo);
    }, [selectedRepo])

    const updateEnabled = useCallback((item, ind) => {
        leakedFiles.map((obj, index) => {
          if(index == ind) {
            obj.enabledExpanded=!item.enabledExpanded;
            setExpandedCount(expandedCount+1);
          } else {
            obj.enabledExpanded=false;
          }
        })
    }, [leakedFiles, expandedCount])

    const updateRepEnabled = useCallback((repo, ind) => {
      repos.map((obj, index) => {
        if(index == ind) {
          obj.enabledExpanded=!repo.enabledExpanded;
          setSelectedRepo(repo);
          loadRepoFiles(repo);
          setExpandedCount(expandedCount+1);
        } else {
          obj.enabledExpanded=false;
        }
      })
    }, [repos, expandedCount])

    return (
        <>
        { repos?.length > 0 &&
        <>
        <div className='my-4'>
            <Form.Control size="lg" className='search-field' id='leaked-repos-search' type="text" placeholder="Search Repository" onKeyUp={handleSearch}/>
        </div>
        <div className='col-12 repos-section'>
         
       <Table >
       <tbody className='mt-2'>
          { repos?.map((rep, ind) => (
            <>
            <tr key={ind} className={rep?.enabledExpanded ? 'tr-highlight' : ''}>
              <td className={"w-90 px-2"}>{rep?.val}</td>
              <td className={"w-10 px-2"}>
              <img src={rep.enabledExpanded ? upArrow : downArrow} height="25" width="25" className="cursor-pointer" onClick={() => updateRepEnabled(rep, ind)} alt="logo" />
              </td>
            </tr>
            {rep.enabledExpanded &&
            <tr colSpan="" key={'child'+ind} className={rep.enabledExpanded ? 'tr-highlight' : ''}>
              <td colSpan="2">
            <div className='col-12 mt-4 repo-files-section'>
              <div className='files-table position-relative pt-4'>
                {loading ? <LoaderComponents/>:
                <Table >
                  <thead >
                    <tr >
                      <th className="w-50 px-2">File</th>
                      <th className="w-40 text-center">Total Matches</th>
                      <th className="w-10 text-center"></th>
                    </tr>
                  </thead>
                  <tbody className='mt-2'>
                    {leakedFiles && leakedFiles.map((item, index) => (
                      <>
                        <tr key={index} className={item.enabledExpanded ? 'tr-highlight' : ''}>
                          <td className={"w-50 px-2"}><p className="mb-0 cursor-pointer"><a className='file-name' href={'https://github.com/'+item?.repo?.raw+'/blob/'+(item?.branch ? item?.branch?.raw : 'master')+'/'+item?.path?.raw} target='_blank'>{item?.path?.raw}</a></p></td>
                          <td className='w-40 px-2 text-center'><p className="mb-0">{item?.total_matches?.raw}</p></td>
                          <td className={"w-10 px-2"}>
                            <img src={item.enabledExpanded ? upArrow : downArrow} height="25" width="25" className="cursor-pointer" onClick={() => updateEnabled(item, index)} alt="logo" />
                          </td>
                        </tr>
                        {item.enabledExpanded &&
                          <tr colSpan="" key={'child'+index} className={item.enabledExpanded ? 'tr-highlight' : ''}>
                            <td colSpan="3">
                              <div className='file-row-snippet' id={"content_"+index} dangerouslySetInnerHTML={{ __html: item?.content?.snippet }}>
                              </div>
                            </td>
                          </tr>
                        }
                      </>
                    ))}
                  </tbody>
                </Table>}
              </div>
              {filesCount > 0 && 
              <div className="pt-2"><PagenationComponent count={filesCount} pageSize={10} currentPage={currentPage} pageChange={(event)=>{
                pageChanged(event);
              }}/></div>}
            </div>
            </td>
            </tr>
            }
            </>
          ))}
        </tbody>
        </Table>
        </div>
        </>
        }
        { repos?.length == 0 &&
          <div className='no-repos-found'><h2>Congratulations! No issues found.</h2></div>
        }
        </>
    );
})
export default LeakedReposMobile;