import './paymentform.css';
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { useState, useEffect, useRef, memo, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { postPaymentDetails } from '../../api/postDetails';
import arrows from "../../assets/images/discover_arrows.svg";
import Modal from 'react-bootstrap/Modal';
import { getActivePaymentMethod, getSubscription } from '../../api/getDetails';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { FUNDING } from "@paypal/react-paypal-js";
import LoaderComponents from '../../components/loader/loader';
import { RAZOR_KEY_ID, PAYPAL_CLIENT_ID } from "../../api/endpoints";
// import PaymentSubscription from '../PaymentSubscription/paymentSubscription';
import countryCodes from '../../assets/countrycodes.json';
const Razorpay = require('razorpay');

const PaymentForm = memo(function PaymentForm(props) {

    const navigate = useNavigate();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [planType, setPlanType] = useState('PREMIUM');
    const [planTypeInValid, setPlanTypeInValid] = useState(false);
    const [mobileInValid, setMobileInValid] = useState(false);
    const [mobile, setMobile] = useState('');
    const [domains, setDomains] = useState([]);
    const [newDomain, setNewDomain] = useState('');
    const [domainsRequired, setDomainsRequired] = useState(false);
    const [showDomainsPopUp, setShowDomainsPopUp] = useState(false);
    const domainRef = useRef();
    const [activePaymentMethod, setActivePaymentMethod] = useState('PAYPAL');
    const [showPaypalButtons, setShowPaypalButtons] = useState(false);
    const [clientId, setClientId] = useState(PAYPAL_CLIENT_ID);
    const [loader, setLoader] = useState(false);
    const [razorPayLoader, setRazorPayLLoader] = useState(false);
    const [countryCodesList, setCountryCodesList] = useState(countryCodes.countryData);
    const [selectedCountryCode, setSelectedCountryCode] = useState('IN');
    // const [domainsLength, setDomainsLength] = useState(0);

    useEffect(()=>{
        /* getActivePaymentMethod().then((response) => {
            setActivePaymentMethod(response.data.paymentType);
        }).catch(error => {
            setActivePaymentMethod('MANUAL');
            console.log(error);
        }) */
    },[])

    const handleDomainChange = useCallback((event, index) => {
        const data = [...domains];
        data[index] = event.target.value;
        setDomains(data);
    }, [domains])

    const handleNewDomain = useCallback((event) => {
        setNewDomain(event.target.value);
    }, [])
    
    const addOnDomain = useCallback(() => {
        if(newDomain !== '') {
            const data = domains;
            data.push(newDomain);
            domainRef.current.value = '';
            // data.push('');
            setDomains(data);
            // setDomainsLength(domains.length);
            setNewDomain("");
        }
    }, [newDomain, domains])

    const sendIntrest = useCallback(() => {
        if(mobile !== '' && planType !== '' && user.id !== ''){
            const reg = /^\d+$/;
        if(!reg.test(mobile) && mobile.length !== 10){
            setMobileInValid(true);
            return;
        }
        if(planType == 'ENTERPRISE' && domains.length == 0){
            return;
        }
        setPlanTypeInValid(false);
        setMobileInValid(false);
        if(selectedCountryCode == 'IN') {
            initiateRazorPay();
        } else if(selectedCountryCode != 'IN') {
            // if(planType == 'ONETIME'){
                setShowPaypalButtons(true);
            // } else {
            //     createSubscription();
            // }
        } 
        /* else if(activePaymentMethod == 'MANUAL') {
            const obj = {planType: planType, mobile: mobile, userId: user.id};
            if(planType == 'ENTERPRISE'){
                obj.domains = domains;
            } else {
                obj.domains = [];
            }
            postPaymentDetails('api/payment/saveintrest',obj).then((response) => {
                setShowDomainsPopUp(true);
            }).catch(error => {
                console.log(error);
            })
        } */

        } else if(!planType){
            setPlanTypeInValid(true);
        } else if(!mobile){
            setMobileInValid(true);
        }
    }, [mobile, planType, selectedCountryCode, domains])

    const createSubscription = async () => {
        try {
            const data = {quantity: domains.length, planType: planType, domains:domains,noOfDays:planType == 'ONETIME'?7:30}
            await postPaymentDetails('api/payment/v3/create-subscription', data).then((response) => {
                window.open(response.data,'_self');
            }).catch(error => {
                console.log(error);
            })
        } catch (error) {
            console.error('Error creating subscription', error);
            throw error;
        }
    };

    const executeAggrement = async(subscriptionId) => {
        const data = {
            subscriptionId: subscriptionId,
        }
        await postPaymentDetails('api/payment/v3/executeAgreement', data).then((response) => {
            window.open(response.data);
        }).catch(error => {
            console.log(error);
        })
    }

    const initiateRazorPay = useCallback(() => {
        const obj = {planType: planType, mobile: mobile, userId: user.id};
        if(planType == 'ENTERPRISE'){
            obj.domains = domains;
        } else {
            obj.domains = [];
        }
        obj['noOfDays'] = planType == 'ONETIME'?7:30;
        obj['amount'] = planType == 'ENTERPRISE'?domains.length*25000:planType == 'ONETIME'?50000:250000;
        obj['currency'] = "USD";
        setRazorPayLLoader(true);
        postPaymentDetails('api/payment/v2/save-or-update-payment',obj).then((response) => {
            checkout(response.data, obj);
        }).catch(error => {
            setRazorPayLLoader(false);
            console.log(error);
        })
    }, [planType, mobile, domains])

    const checkout = useCallback((ord, obj) => {
        setRazorPayLLoader(false);
        var paymentOptions = {
            "key_id": RAZOR_KEY_ID, // Enter the Key ID generated from the Dashboard
            "amount": ord.amount,
            "currency": ord.currency,
            "order_id": ord.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": async function (response){
                setShowDomainsPopUp(true);
                postPaymentDetails('api/payment/v2/updateOrder',{paymentDetails:response, data_obj:obj}).then((res) => {

                }).catch(error => {
                    console.log(error);
                })
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(paymentOptions);
        rzp1.on('payment.failed', function (response){
            alert("payment failed");
        });
        rzp1.open();
        
    }, [])

    const handleClose = useCallback(() => {
        setShowDomainsPopUp(false);
        getSubscription('api/payment/retrive').then((response) => {
            if(response.data.subscriptionStatus === 'active' || response.data.subscriptionStatus === 'trialing') {
                    navigate('/main/leakeddetails');
            } else {
                    navigate('/main/search');
            }
          }).catch((error) => {
            console.log(error);
            navigate('/main/search');
          })
        // navigate('/main/search');
      }, [])

      const handlePaypalClose = useCallback(() => {
        setShowPaypalButtons(false);
      }, [])

      const createOrder = async () => {
        const obj = {planType: planType, domains: domains};
        obj['amount'] = planType == 'ENTERPRISE'?domains.length*250:planType == 'ONETIME'?500:2500;
        const response = await postPaymentDetails('api/payment/v3/create-order', obj)
        const data = await response;
        return data.data.id;
    };

    const onApprove = async (data, actions) => {
        setLoader(true);
        const obj = {planType: planType};
        if(planType == 'ENTERPRISE'){
            obj.domains = domains;
        } else {
            obj.domains = [];
        }
        obj['noOfDays'] = planType == 'ONETIME'?7:30;
        postPaymentDetails('api/payment/v3/update-order', {orderId: data.orderID, data_Obj: obj}).then((response) => {
            setShowPaypalButtons(false);
            setLoader(false);
            getSubscription('api/payment/retrive').then((response) => {
                if(response.data.subscriptionStatus === 'active' || response.data.subscriptionStatus === 'trialing') {
                        navigate('/main/leakeddetails');
                } else {
                        navigate('/main/search');
                }
            }).catch((error) => {
                console.log(error);
                navigate('/main/search');
            })
        }).catch(error => {
            setLoader(false)
            console.log(error);
        })
    };

    const flagStyle = useCallback((image) => {
        return {backgroundImage:`url(${image})`}
    }, [])

    return(
    <div className='container color-white payment-form pb-4'>
        {razorPayLoader ? <LoaderComponents/> : 
        <>
<p className="font-32">Hi, {user.name}</p>
<p className="font-28">Please select a plan which suits you.</p>
<div className=''>
<div>
<Form.Check className="mx-2 d-inline" size="lg" type="radio" value="PREMIUM" onChange={(e)=>{setPlanType(e.target.value);setDomains([]);setDomainsRequired(false)}} checked={planType=='PREMIUM'}/> Premium ($2500 per month)
<div className='d-flex d-inline'>
<p>Upgrade to the Premium Plan for unparalleled wild card search capabilities across all domains and datasets.</p>
</div>
</div>
<br/>
<div>
<Form.Check className="mx-2 d-inline" size="lg" type="radio" value="ENTERPRISE" onChange={(e)=>{setPlanType(e.target.value);setDomains([]);setDomainsRequired(true)}} checked={planType=='ENTERPRISE'}/> Enterprise ($250 per domain per month)
<div className='d-flex d-inline'>
<p>Upgrade to the Enterprise Plan for real-time monitoring and notifications across all companies digital assets.</p>
</div>
</div>
<br/>
<div>
<Form.Check className="mx-2 d-inline" size="lg" type="radio" value="ONETIME" onChange={(e)=>{setPlanType(e.target.value);setDomains([]);setDomainsRequired(false)}} checked={planType=='ONETIME'}/> OneTime ($500 for 7days)
<div className='d-flex d-inline'>
<p>Opt for the One-Time Plan for a week of access, perfect for getting a comprehensive overview of the platform's capabilities.</p>
</div>
</div>
{planTypeInValid&&<p className='danger-font'>Please select a plan</p>}
<br/>
<div className='col-4'>
<Form.Control size="lg" className='mobile' required type="text" placeholder="Mobile number" onKeyUp={(e)=>setMobile(e.target.value)} pattern='/^\d+$/'/>
{mobileInValid&&<p className='danger-font'>Please enter a valid mobile number</p>}
</div>
<br/>
{planType == 'ENTERPRISE' && <div>
<div className='domains'>
        <br/>
        <div className='col-12 text-left'>
            <p className='mb-0 add-domains-text'>Add your domains to monitor</p>
        </div>
        <br/>
        <div className='domains-section  d-flex'>
            <div className='col-12 mx-2 domains-card'>
            <div className='domains-block'>
            {domains.map((item, index) => (
                <div className='col-12 d-flex my-4 align-items-center' key={index}>
                    <Form.Control size="lg" className='mx-2' id={"domain_"+index} type="text" onChange={(event) => handleDomainChange(event, index)} value={item} placeholder="Enter domain here" />
                </div>
            ))}
            </div>
            { domains.length >= 10 &&
                <p className='plan-suggestion'>
                    Since you added 10 domains and the price is equal to Premium Plan, we suggest you to opt the Premium Plan.
                </p>
            }
            <hr/>
            <div className='col-12 py-2 d-flex add-on'>
                <div className='col-6'>
                <Form.Control ref={domainRef} size="lg" className='mx-2' type="text" onChange={handleNewDomain} placeholder="Enter domain here" />
                </div>
                <div className='col-5 mx-2 px-2'>
                <Button className="cursor-pointer text-white add-domain-btn mb-0" onClick={addOnDomain}>Add domain</Button>
                </div>
            </div>
            {(domainsRequired&&domains.length==0)&&<p className='danger-font'>Domains required</p>}
            </div>
        </div>
        <br/>
    </div>
    <br/>
    </div>}
    <div className='col-4 d-flex align-items-center'>
    <label>Country Code : </label>
    <select className='mx-2' defaultValue={'IN'} onChange={e => setSelectedCountryCode(e.target.value)}>
        {countryCodesList && countryCodesList.map((code, index) => (
            <option className='countryOption' key={'option'+index} style={flagStyle(code.image.toLowerCase())} value={code.code}>{code.name}</option>
        ))}
    </select>
    </div>
<div className='mt-4'>
<Button className="cursor-pointer text-white continue-btn" onClick={sendIntrest}>Proceed<img src={arrows} height="15" width="15" className='mx-2'></img></Button>
</div>
</div>
<Modal show={showDomainsPopUp} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{activePaymentMethod == 'MANUAL' ? 'Your interest is received!' : 'Success'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='center-align text-center'>
            <p className='font-white'>{activePaymentMethod == 'MANUAL' ? 'Thank you for your interest.' : 'Payment Success'}</p>
            {activePaymentMethod == 'MANUAL' && <p className='font-white'>Our team will get in touch with you soon!</p>}
                 </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>


        <Modal show={showPaypalButtons} onHide={handlePaypalClose}>
        <Modal.Header>
          <Modal.Title>Pay Using Paypal</Modal.Title>
        </Modal.Header>
        <Modal.Body className='paypal-body'>
        <div className='paypal-section'>
            {loader ? <LoaderComponents/> : 
            // <PaymentSubscription/>
            <PayPalScriptProvider options={{ 'client-id': clientId }}>
            <PayPalButtons
                createOrder={(data, actions) => createOrder()}
                onApprove={(data, actions) => onApprove(data, actions)}
                style={{ layout: 'vertical', shape: 'rect' }}
            />
            </PayPalScriptProvider>
            }
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePaypalClose}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
        </>
}
    </div>)
})   

  export default PaymentForm;