import './support.css';
function Support() {
  return (
<div className='back-black'>
    <h1 className='font-52'>Support</h1>

    <p>Welcome to infoleak.io Support! We're here to assist you with any questions or issues you may have regarding our information security monitoring services.</p>

    <h2>Contact Information:</h2>
    <p>If you have any questions, concerns, or need assistance, please don't hesitate to reach out to us. You can contact infoleak.io Support at:</p>
    <ul>
        <li><a href="mailto:noreply@infoleak.io">noreply@infoleak.io</a></li>
    </ul>

    <h2>How to Reach Us:</h2>
    <p>For your convenience, you can contact infoleak.io Support via email at any time. Our dedicated support team is committed to providing prompt and helpful assistance to ensure your satisfaction with our services.</p>

    <h2>Support Hours:</h2>
    <p>infoleak.io Support is available to assist you during the following hours:</p>
    <ul>
        <li>Monday - Friday: 9:00 AM - 5:00 PM (GMT)</li>
    </ul>

    <h2>Response Time:</h2>
    <p>We strive to respond to all support inquiries promptly. Our goal is to provide you with timely assistance and resolve any issues you may encounter as quickly as possible.</p>

    <h2>How We Can Help:</h2>
    <p>Our support team is here to assist you with a wide range of topics, including:</p>
    <ol>
        <li>Product inquiries and feature requests</li>
        <li>Technical support and troubleshooting</li>
        <li>Account assistance and billing inquiries</li>
        <li>General questions about our services</li>
    </ol>

    <h2>Documentation and Resources:</h2>
    <p>For self-service support, we offer comprehensive documentation and resources to help you get the most out of our services. You can access our knowledge base, user guides, and FAQs on our website.</p>

    <h2>Feedback:</h2>
    <p>We value your feedback and are continuously striving to improve our services. If you have any suggestions, comments, or feedback, please feel free to share them with us. Your input helps us enhance our offerings and provide you with an exceptional experience.</p>

    <p>Thank you for choosing infoleak.io for your information security monitoring needs. We're here to support you every step of the way!</p>

    <p>Sincerely,</p>
    <p>The infoleak.io Support Team</p>
</div>
  );
}
export default Support;