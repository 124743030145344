import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from "react-router-dom";
import { changePassword } from '../../api/postDetails';
import logo from '../../assets/images/infoleak_text.svg';
import './ResetPassword.css';

function ResetPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPasswordError, setShowNewPasswordError] = useState(false);
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
  const [showPasswordMatchError, setShowPasswordMatchError] = useState(false);
  const [showResetError, setShowResetError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  let { id } = useParams();

  useEffect(()=>{

  },[])

  function navigateToSignIn() {
    navigate('/login');
  }

  function resetNow() {
    if(newPassword !== '' && confirmPassword !== '' && newPassword === confirmPassword) {
      setShowNewPasswordError(false);
      setShowConfirmPasswordError(false);
      setShowPasswordMatchError(false);
      changePassword({newPassword: newPassword, token: id}).then((response) => {
        navigate('/resetsuccess');
      }).catch(error => { 
        setShowResetError(true);
        setErrorMsg(error.response.data.message);
      })
      
    } else {
      if(!newPassword || newPassword === '') {
        setShowNewPasswordError(true);
      }
      if(!confirmPassword || confirmPassword === ''){
        setShowConfirmPasswordError(true);
      }
      if(newPassword !== confirmPassword) {
        setShowPasswordMatchError(true);
      }
    }
  }

  function newPasswordChange(event) {
    if(event.target.value !== '') {
      setNewPassword(event.target.value);
      setShowNewPasswordError(false);
    } else {
      setShowNewPasswordError(true);
    }
  }

  function confirmPasswordChange(event) {
    if(event.target.value !== '') {
      setConfirmPassword(event.target.value);
      setShowConfirmPasswordError(false);
    } else {
      setShowConfirmPasswordError(true);
    }
  }

  function goToHome(){
    navigate('/');
  }

  return (
    <div className="pre-login-screen">
      <div className="pre-login-screen-header pt-3">
          <div className="col-12 d-flex p-4">
              <div className="col-6 position-relative">
              <img className='cursor-pointer' src={logo} width="150" height="40" onClick={goToHome}/>
              </div>
              <div className="col-6 position-relative text-right d-flex justify-content-end align-items-center">
              <button className='header-btn px-4 py-2' onClick={navigateToSignIn}>Sign In</button>
              </div>
          </div>
      </div>
      <div className="pre-login-content-screen">
          <div className="pre-login-content-block px-4">
              <div className="p-4">
                  <p className='my-2 signin-header'>Reset Password</p>
                  <div className='my-4'>
                    <Form.Control required size="lg" type="password" placeholder="New Password" onChange={newPasswordChange} />
                    {showNewPasswordError && <div className='error-msg'>New Password is required.</div>}
                  </div>
                  <div>
                    <Form.Control size="lg" required type="password" placeholder="Confirm Password" onChange={confirmPasswordChange}/>
                    {showConfirmPasswordError && <div className='error-msg'>Confirm Password is required.</div>}
                  </div>
                  {showPasswordMatchError && <div className='error-msg my-2 text-center'>New Password and Confirm Password doesn't Match.</div>}
                  {showResetError && <div className='error-msg my-2 text-center'>{errorMsg}</div>}
                  <div className='my-4'><button className='create-btn px-4 py-2 w-100' onClick={resetNow}>Reset Now</button></div>
              </div>
          </div>
          <p className="mb-0 mt-4 text-white w-100 text-center">Know Your Leaks Before They're Exploited!</p>
      </div>
    </div>
  );
}

export default ResetPassword;
