import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import './index.css';
import Login from './Login';
import Domains from './pages/Domains/Domains';
import LandingPage from './pages/Landing/LandingPage';
import LeakedDetails from './pages/LeakedDetails';
import Payment from './pages/Payment/Payment';
import Plans from './pages/Plans/Plans';
import PreSubscriptionSearch from './pages/PreSubscriptionSearch/PreSubscriptionSearch';
import Subscription from './pages/Subscription/Subscription';
import SubscriptionSuccess from './pages/SubscriptionSuccess/SubscriptionSuccess';
import reportWebVitals from './reportWebVitals';
import Signup from './Signup';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetLinkSuccess from './pages/ResetLinkSuccess/ResetLinkSuccess';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import SetPassword from './pages/SetPassword/SetPassword';
import ResetPasswordSuccess from './pages/ResetPasswordSuccess/ResetPasswordSuccess';
import SubscriptionFailed from './pages/subscriptionfailed/SubscriptionFailed';
import Termsofservice from './components/termsofservice/termsofservice';
import Support from './components/support/support';
import PrivacyPolicy from './components/privacypolicy/privacypolicy';
import CancellationPolicy from './components/cancellationpolicy/cancellationpolicy';
import ApiDocumentsComponent from './components/apidocuments/apiDocuments';
import PaymentForm from './pages/paymentform/paymentform';
import SendEmail from './pages/sendemail/sendemail';
import PageNotFoundComponent from './pages/PageNotFound/pageNotFound';
import ManageUsers from './pages/ManageUsers/manageUsers';
import AboutUs from './pages/aboutus/aboutus';
import ReactGA from "react-ga4";
import SecureCodeLeaks from './pages/securecodeleaks/secureCodeLeaks';
import store from './redux/store';
import { Provider } from 'react-redux';
import { ToastProvider } from './Toast';
import Vendors from './pages/vendors/vendors';
import Acknowledgement from './pages/acknowledgement/acknowledgement';
import ChoosePaymentMethod from './pages/ChoosePaymentMethod/choosepaymentmethod';
import Success from './pages/paymentSuccess/paymentsuccess';
import Cancel from './pages/cancel/cancel'
import Unsubscribe from './pages/unsubscribe/unsubscribe';
import Intercom from '@intercom/messenger-js-sdk';
ReactGA.initialize("G-DRXWT6QJQJ");



const router = createHashRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "/resetlink",
    element: <ResetLinkSuccess />,
  },
  {
    path: "/reset/:id",
    element: <ResetPassword />,
  },
  {
    path: "/verify/:id",
    element: <SetPassword />,
  },
  {
    path: "/resetsuccess",
    element: <ResetPasswordSuccess />,
  },
  {
    path: "/termsofservice",
    element: <Termsofservice />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/cancellationpolicy",
    element: <CancellationPolicy />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/acknowledgement/:id",
    element: <Acknowledgement />,
  },
  {
    path: "/paymentSuccess/:id",
    element: <Success />,
  },
  {
    path: "/paymentFailure",
    element: <Cancel />,
  },
  {
    path: "/main",
    element: <App />,
    children: [
      {
        path: "search",
        element: <PreSubscriptionSearch />,
      },
      {
        path: "leakeddetails",
        element: <LeakedDetails />,
      },
      {
        path: "plans",
        element: <PaymentForm userRole={"admin"} />,
      },
      {
        path: "chooseplan",
        element: <PaymentForm userRole={"user"} />,
      },
      {
        path: "domains",
        element: <Domains />,
      },
      {
        path: "payment/:plan",
        element: <Payment />,
      },
      {
        path: "success",
        element: <SubscriptionSuccess />,
      },
      {
        path: "failed",
        element: <SubscriptionFailed />,
      },
      {
        path: "documents",
        element: <ApiDocumentsComponent />,
      },
      {
        path: "sendinvite",
        element: <SendEmail />,
      },
      {
        path: "unsubscribe",
        element: <Unsubscribe />,
      },
      {
        path: "users",
        element: <ManageUsers />,
      },
      {
        path: "sourcecodeleaks",
        element: <SecureCodeLeaks />
      },
      {
        path: "vendors",
        element: <Vendors/>
      },
      {
        path: "changepaymentMethod",
        element: <ChoosePaymentMethod/>
      },
      {
        path: "*",
        element: <PageNotFoundComponent/>
      }
    ],
  },
  {
    path: "*",
    element: <PageNotFoundComponent/>
  }
]);

Intercom({
  app_id: 'wlpu0zit'
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <body>
  <React.StrictMode>
  <ToastProvider>
    <Provider store={store}>
    <RouterProvider router={router} />
    </Provider>
    </ToastProvider>
  </React.StrictMode>
  </body>
);

reportWebVitals();
