import { memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import download from '../../assets/images/download.svg';
import './generatedFiles.css';
import { generateReport } from '../../api/postDetails';
import { deleteReportFile, downloadReport, getGeneratedReports } from '../../api/getDetails';
import LoaderComponents from '../loader/loader';
import moment from 'moment';

const GeneratedFilesComponent = memo(function GeneratedFilesComponent(props) {
  
  const [reportsLoading, setReportsLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [files, setFiles] = useState([]);
  const [filesLength, setFilesLength] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);
  const [generateError, setGenerateError] = useState('');

  useEffect(()=>{
    loadGeneratedReports();
  },[filesLength])

  const loadGeneratedReports = useCallback(() => {
    setReportsLoading(true);
    getGeneratedReports('api/report/get-generated-report?domain='+props.domain+'&userId='+user?.id).then((response) => { 
      setReportsLoading(false);
      const files = response?.data?.sort((a, b) => (a.created_on > b.created_on) ? -1 : 1);
      setFiles(response.data);
    }).catch((error) => {
      setFiles([]);
    });
  }, [props.domain])

  const showFiles = useCallback(() => {
    setFilesLength(filesLength+1);
    setShowPopUp(true);
  }, [filesLength])

  const handleClose = useCallback(() => {
    setReportsLoading(false);
    setExportLoading(false);
    setShowPopUp(false);
    setGenerateError('');
  }, [])

  const exportNow = useCallback(() => {
    setExportLoading(true);
    setGenerateError('');
    generateReport('api/report/generate-report?domain='+props.domain+'&userId='+user?.id, {}).then((response) => { 
      setExportLoading(false);
      setFilesLength(filesLength+1);
    }).catch((error) => {
      setGenerateError(error?.response?.data?.message);
      setExportLoading(false);
    });
  }, [props.domain, filesLength])

  const downloadFile = useCallback((id) => {
    setGenerateError('');
    downloadReport('api/report/download-report?reportId='+id).then((response) => { 
        const url = response?.data?.url;
        const a = document.createElement('a');
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
    });
  }, [])

  const deleteReport = useCallback((id) => {
    deleteReportFile('api/report/delete-report?reportId='+id).then((response) => { 
      setFilesLength(filesLength+1);
    }).catch((error) => {

    });
  }, [filesLength])

  return (
    <>
    <img className='cursor-pointer' src={download} onClick={showFiles} height="30" width="30" alt="logo" />
    <Modal show={showPopUp} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className='col-12 export-header d-flex justify-content-between'>
            <div className="modal-title h4">Export Leaked Data</div>
            {exportLoading ? <span className='exporting position-relative d-inline'><LoaderComponents micro={true}/></span> : <Button className='mx-2' variant="secondary" onClick={exportNow}>Generate Now</Button>}
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className='domains files-section p-2'>
        {generateError != '' && <div className='col-12 text-center error-msg py-4'>{generateError}</div>}
          {reportsLoading ? <LoaderComponents/>: <>
          <div className='col-12 d-flex'>
            <div className='col-10'>Generated On</div>
            <div className='col-2 text-center'>Download</div>
          </div>
          {files.length > 0 && files.map((file) => (
            <div className='col-12 d-flex align-items-center mt-3'>
            <div className='col-10 generated-date'>{file?.created_on ? moment(file?.created_on, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format('DD MMM, yyyy hh:mm:ss A') : 'NA'}</div>
            {file?.status == 3 && <div className='col-2 text-center d-flex'>
               <img className='cursor-pointer' onClick={() => downloadFile(file?._id)} src={download} height="30" width="30" alt="logo" />
              <span className='file-delete mx-2' onClick={() => deleteReport(file?._id)}>X</span>
            </div>}
            {file?.status == 2 && <div className='col-2 text-center inprogress'>
              In Progress
            </div>}
          </div>
          ))
          }
          </>
        }
        </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='col-12 text-center'>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          </div>
        </Modal.Footer>
    </Modal>
    </>
  );
})

export default GeneratedFilesComponent;