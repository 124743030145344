const { useEffect, memo } = require("react");


const RecorderCount = memo(function RecorderCount(props) {
    useEffect(() => {    
        
    },[]);
  return (
    <>
    {props.recordCount > 0 ? props.recordCount>1000 ? props.recordCount>1000000 ? props.recordCount>1000000000 ? '1B+':parseFloat(parseInt(props.recordCount/100000)/10) + 'M' : parseFloat(parseInt(props.recordCount/1000)) + 'K' : props.recordCount : 0}
    </>
  );
})

export default RecorderCount;