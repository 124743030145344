import { useEffect } from 'react';
import './loader.css';
import loader from './../../assets/images/loader.gif';
import smallLoader from './../../assets/images/loader.gif';

function LoaderComponents(props) {
    const totalPages = parseInt(props.count/props.pageSize);
    useEffect(() => {
    },[]);
    
  return (
    <div className={'w-100 justify-content-center '+props.micro?'d-inline':'d-flex'}>
      <img className='center-aligned-loader' src={props.small?smallLoader:loader} width={props.small==true?'100px':props.micro==true?'24px':'250px'} height={props.micro==true?'12px':"auto"}/>
    </div>
  );
}

export default LoaderComponents;
