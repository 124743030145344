const initialState = {vendors:[]};
const vendorreducer = (state=initialState, action)=>{
    switch (action.type) {
        case "vendors":
            return { ...state, vendors: action.payload};
        default:
            return state;
    }
}

export default vendorreducer;