import './verticle-scroller.css';
import {Form, Table, Modal, Button} from "react-bootstrap";
import info from './../../assets/images/info.png';
import { memo, useCallback, useState } from 'react';
import issuesJson from '../../assets/suggested-fixes.json';

const VerticleScroller = memo(function VerticleScroller(props) {

    const [filter, setFilter] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);
    const [issueDoc, setIssueDoc] = useState(null);
    const issues = issuesJson;
    const [suggestedFix, setSuggestedFix] = useState('');

    const handleFilter = useCallback((e) => {
        setFilter(e.target.value);
    }, [])

    const openPopup = useCallback((doc) => {
        let issueId = doc.riskId;
        setSuggestedFix(issuesJson[issueId].details);
        setIssueDoc(doc);
        setShowPopUp(true);
    }, [])

    const handleClose = useCallback(() => {
        setSuggestedFix('');
        setShowPopUp(false);
    }, [])

    return (
        <>
        <div className="verticle-scroller">
            <div className='row'>
            <div className='col-8'>
                <div className="title">
                {props.name}
                    <span className='count-label'>
                        {props.data.length}
                    </span>
                </div>
            </div>
            <div className='col-4'>
            <Form.Select className='filter-options' size="lg" onChange={(e)=>handleFilter(e)}>
            <option value={''} selected>All</option>
                <option value={0}>Failed</option>
                <option value={1}>Passed</option>
            </Form.Select>
            </div>
            </div>

        <div className='scroller'>
        {(props.data && props.data.length>0) &&<Table className='mt-2 table'>
        <tbody >
            {props?.data.map((doc, index) =>
            {return (filter == '' || filter==doc.passed) && 
            <tr key={index}>
            <td>{index+1}</td>
            <td><div>
            {doc.title}
                </div>
                <div className='font-small'>{doc.severity}</div></td>
            <td>{<img className='cursor-pointer' src={info} onClick={() => openPopup(doc)}/>}</td>
            <td><span className={doc.passed==1?'passed':'failed'}>{doc.passed==1?'Passed':'Failed'}</span></td>
            </tr>
            }
            )}
        </tbody>
        </Table>}
        {props.statusPending && <div className='font-white'>We are fetching your data</div>}
        {!props.statusPending && props.data.length==0 && <div className='font-white'>No records to show.</div>}
        </div>
        </div>
        <Modal show={showPopUp} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className='col-12 brand-issue-header d-flex justify-content-between'>
            <div className="modal-title h4">{issueDoc?.title}</div>
            <Button onClick={handleClose}>X</Button>
          </div>
        </Modal.Header>
        <Modal.Body className='p-4'>
            <div className='col-12 modal-body-text'>
                {/* <h3>Description</h3>
                <p className='font-14'>{issueDoc?.description}</p>
                <br/> */}
                <div className='suggested-text mt-4' dangerouslySetInnerHTML={{ __html:  suggestedFix}}></div>
            </div>
        </Modal.Body>
    </Modal>
        </>
    );
    })

export default VerticleScroller;