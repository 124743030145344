import { useEffect, useState } from 'react';
import { Table, Button, Form, Modal } from "react-bootstrap";
import './notifyModel.css';

function NotifiModel(props){
    const [email, setEmail] = useState('');
    const [showEmailError, setEmailError] = useState(false);
    const [subject, setSubject] = useState('');
    const [showSubjectError, setSubjectError] = useState(false);
    const [emailBody, setEmailBody] = useState('');
    const [showEmailBodyError, setEmailBodyError] = useState(false);
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // useEffect(()=>{
    //     setEmail('');
    //     setSubject('');
    //     setEmailBody('');
    // })

    function assignEmail (e) {
        setEmail(e.target.value);
    }

    function assignSubject (e) {
        setSubject(e.target.value);
    }

    function assignBody (e) {
        setEmailBody(e.target.value);
    }

    function sendNotification() {
        let formValid = true;
        if(subject.trim().length == 0 || subject.length>50){
            setSubjectError(true);
            formValid = false;
        } else {
            setSubjectError(false);
        }
        if(emailBody.trim().length == 0 || emailBody.length>200){
            setEmailBodyError(true);
            formValid = false;
        } else {
            setEmailBodyError(false);
        }
        if(email.trim().length == 0 || !emailPattern.test(email)){
            setEmailError(true);
            formValid = false;
        } else {
            setEmailError(false);
        }
    if(formValid == true){
        const body = {
        email: email,
        subject: subject,
        body: emailBody,
        }
        props.sendNotification(body);
    } else return;

    }
    function handleAlertPopup(showPopup,) {
        props.closeAlertPopup(showPopup);
    }
    return (
        <div className='w-100 text-center p-3'>
                <p>Please enter an Email to whom you want to notify.</p>
                <Form.Control size="lg" className='search-field' type="text" onKeyUp={assignEmail} placeholder='Please enter Email' />
                {showEmailError && <div className='col-12 d-flex justify-content-lg-start'><p className='mb-0 text-danger'>Email is required.</p></div>}
                <br/>
                <Form.Control size="lg" max-length={50} className='search-field' type="text" onKeyUp={assignSubject} placeholder='Please enter Subject text' />
                {showSubjectError && <div className='col-12 d-flex justify-content-lg-start'><p className='mb-0 text-danger'>Subject is required and should be less than 50 characters.</p></div>}
                <br/>
                <Form.Control size="lg" max-length={200} as="textarea" rows={6} className='search-field' type="text" onKeyUp={assignBody} placeholder='Please enter Email body text' />
                {showEmailBodyError && <div className='col-12 d-flex justify-content-lg-start'><p className='mb-0 text-danger'>Email body is required and should be less than 200 characters.</p></div>}
                <br/>
                <br/>
                <div className='col-12 d-flex justify-content-between'>
                <Button onClick={() => handleAlertPopup(false, "")}>close</Button>
                <Button  variant="secondary" size="sm" onClick={() => sendNotification()}>Notify</Button>
                </div>
              </div>
    )
}

export default NotifiModel;