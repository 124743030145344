import React from 'react';
import { useTimeout } from '../hooks/useTimeout';
import './Toast.css';

export function Toast (props) {
  useTimeout(props.close, 300000);

  return (
    <div className="toaster">
      <div className="toast__text">{props.children}</div>
      <div>
        <button onClick={props.close} className="toast__close-btn">
          x
        </button>
      </div>
    </div>
  );
};
