import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { getNotifiedData } from '../../api/postDetails';
import { acknowledge } from '../../api/getDetails';
import LoaderComponents from '../../components/loader/loader';
import './acknowledgement.css';
import logo from '../../assets/images/infoleak_text.svg'

function Acknowledgement() {

  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('user')));
  const navigate = useNavigate();
  const [leakedDetailsData, setLeakedDetailsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(0);
  let { id } = useParams();

  useEffect(()=>{
    if(id) {
      getNotified();
    }
  },[])

  function getNotified() {
    getNotifiedData('api/infoleaks/getNotifiedData', {notifiedId: id}).then((response) => { 
      setLeakedDetailsData(response?.data?.data?.records);
      setStatus(response?.data?.status);
    }).catch((error) => {
      
    });
  }

  function getCompromisedDate(date){
    if(!date){
      return 'N/A';
    }
    if((moment(new Date(), "YYYY-MM-DDTHH:mm:ss.SSSSZ").diff(moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ"), 'days')) >= 0) return moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format('DD MMM, yyyy');
    const new_date = moment(date, "YYYY-DD-MMTHH:mm:ss.SSSSZ").format('DD MMM, yyyy');
    return (moment(new Date(), "YYYY-DD-MMTHH:mm:ss.SSSSZ").diff(new_date, 'days')) >= 0 ? moment(new_date).format('DD MMM, yyyy'):'N/A';
  }

  function applyAcknowledge() {
    acknowledge('api/infoleaks/userAcknowledgement?notifiedId='+id).then((response)=>{
      setStatus(1);
    })
  }

  function goToHome(){
    navigate('/');
  }

  function navigateToSignIn() {
    navigate('/login');
  }

  return (
    <div className="pre-login-screen">
        <div className="pre-login-screen-header pt-3">
          <div className="col-12 d-flex p-4">
              <div className="col-6 position-relative">
              <img className='cursor-pointer' src={logo} width="150" height="40" onClick={goToHome}/>
              </div>
              <div className="col-6 position-relative text-right d-lg-flex justify-content-end align-items-center disp-mob-none">
                  <p className="cursor-pointer text-white mx-3 mb-0 account-link">Already have account?</p>
                  <button className='header-btn px-4 py-2' onClick={navigateToSignIn}>Sign In</button>
              </div>
          </div>
      </div>
        <div className="col-12 mt-4 pt-4">
        {((leakedDetailsData?.length) > 0 || loader) &&
        <div className='height-75 px-4 position-relative'>
        {loader ? (<LoaderComponents/>):
        (
          <>
        <Table>
          <thead>
            <tr>
              
              <th className="wid-35 px-2">URLS</th>
              <th className="wid-20 px-2">Login</th>
              <th className="wid-10">Password</th>
              <th className="wid-15 px-2">IP</th>
              <th className="wid-20 px-1">Comprimised date</th>
            </tr>
          </thead>
          <tbody className='leaks-table-body'>
            {leakedDetailsData.map((item, index) => (
              <>
                <tr className='tr' key={index}>
                 
                  <td className="px-2 wid-35">{item?.url ? item?.url : 'NA'}</td>
                  <td className="px-2 wid-20">{item?.username ? item?.username : 'NA'}</td>
                  <td>
                    <p className="mb-0 wid-10">{item?.password ? item?.password.slice(0, 60)+(item?.password.length > 60 ? '...' : '') : 'NA'}</p>
                    <p className={`mb-0 ${item?.passwordStrength}`}>{item?.passwordStrength}</p>
                  </td>
                  <td className="px-2 wid-15">{item?.ip ? item?.ip : 'NA'}</td>
                  <td className="px-1 wid-20">{getCompromisedDate(item?.date_compromised)}</td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
        <div className='col-12 mt-4 text-center'>
        {status<1&&<Button variant="secondary" size="sm" onClick={() => applyAcknowledge()}>Acknowledge</Button>}
        {status>0&&<p className='text-white'>These records already acknowledged.</p>}
        </div>
        </>)}
        </div>
        }
      </div>
    </div>
  );
}

export default Acknowledgement;