import axios from "axios";
import { baseUrl } from "./endpoints";

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const Base64 = {
  btoa: (input = '')  => {
    let str = input;
    let output = '';

    for (let block = 0, charCode, i = 0, map = chars;
    str.charAt(i | 0) || (map = '=', i % 1);
    output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

      charCode = str.charCodeAt(i += 3/4);

      if (charCode > 0xFF) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }

      block = block << 8 | charCode;
    }

    return output;
  },

  atob: (input = '') => {
    let str = input.replace(/=+$/, '');
    let output = '';

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (let bc = 0, bs = 0, buffer, i = 0;
      buffer = str.charAt(i++);

      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  }
};

export default Base64;
const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const keys = JSON.parse(window.localStorage.getItem('access_token'));
    config.headers = { 
      'Authorization': `Bearer ${keys}`,
      'Accept': 'application/json',
    //   'Content-Type': 'application/x-www-form-urlencoded'
    }
    return config;
  },
  (error) => {
    Promise.reject(error)
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401) {
    originalRequest._retry = true;
    const AccessToken = await refreshAccessToken();            
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + AccessToken;
    return axiosApiInstance(originalRequest);
  } else if(error.response.status === 403){
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    window.location.href = '/#/login';
  }
  return Promise.reject(error);
});

const refreshAccessToken = async () => {
    const keys = JSON.parse(window.localStorage.getItem('access_token'));
    const config = axios.create();
  config.interceptors.request.use(
    (request) => {
    //   request.headers['RefreshToken'] = keys.RefreshToken;
    //   request.headers['grant_type'] =  'RefreshToken';
    //   request.headers['scope'] = 'read';
      request.headers.Authorization = 'Basic ' + Base64.btoa('client' + ':' + 'clientpassword');
      request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  )
  const response = await config.post(baseUrl + 'oauth/token?' + 'RefreshToken='+keys.RefreshToken+
  '&grant_type=RefreshToken&'+
  'scope=read', config.interceptors.request).then((data)=>{
    keys.AccessToken = data.data.AccessToken;
    keys.RefreshToken = data.data.RefreshToken;
    window.localStorage.setItem('access_token', JSON.stringify(keys));
    return data.data.AccessToken;
});
}
export const getMethod = async (url) => {
    const response = await axiosApiInstance.get(url)
    return response;
  }
export const postMethod = async (url, data) => {
    const response = await axiosApiInstance.post(url, data);
    return response;
}
export const putMethod = async (url, data) => {
    const response = await axiosApiInstance.put(url, data);
    return response;
}