import { useEffect, useState } from 'react';
import './static.styles.css';
import go from './../../assets/images/go.png';
import RecorderCount from '../recordcount/recordcount.component';
import { getInfoleaks } from '../../api/getDetails';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/infoleak_text.svg'
import LoaderComponents from '../loader/loader';
import SubscriptionPlans from '../../pages/SubscriptionPlans/subscriptionPlans';

function Static(props) {
    const [activeLine, setActiveLine] = useState(0);
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [gridData, setGridData] = useState([
        {header:'Unlock the Power of Infoleak.io', statement:'Harnessing Deep Dark Web Insights to Proactively Defend Against Infostealers and Threat Actors. Stay Ahead of Data Breaches with Cutting-Edge SaaS Solutions, Setting Us Apart from Traditional Tools.'},
        {header:'The Anatomy of Corporate Data Breaches', statement:'Infostealers exploit vulnerabilities to infiltrate corporate networks, compromising sensitive data. Stay ahead of threats with Infoleak.io\'s proactive defense strategies, safeguarding your business from deva'},
        {header:'Empower Your Security', statement:'Utilize Infoleak.io to Detect Data Leaks Before They Surface. Gain Real-Time Insights from the Deep Dark Web, Outsmarting Threats and Securing Your Assets Proactively.'},
        {header:'Stay Ahead of the Curve', statement:'9 Billion Credentials in Just 7 Days, with a Staggering 30 Million Records Added Daily. Stay Informed, Stay Secure with Infoleak.io'}
    ]);
    const [showCard1, setShowCard1] = useState(false);
    const [showCard2, setShowCard2] = useState(false);
    const [showCard3, setShowCard3] = useState(false);
    const [showCard4, setShowCard4] = useState(false);
    useEffect(()=>{
        console.log(props);
        // getInfoleaks('api/infoleaks').then((response) => {
        //     setData(response.data);
        //   }).catch(error => {
        //     console.log(error);
        //   })
    })

    function navigateToSubscription() {
        if(user) {
            navigate('/main/chooseplan');
        } else {
            navigate('/login');
        }
    }

    function navigateExternal(url){
        window.open(url, '_blank');
    }
return (<>
<div className='row'>
<div className='col-md-12 col-lg-4'>
<div className='sub-card position-relative'>
{props.loading ? (<LoaderComponents small={true}/>) : (<>
<p className='sub-card-header'><RecorderCount recordCount={props?.data?.usersCount}/></p>
<br className='disp-mb-none'/>
<br className='disp-mb-none'/>
<p className='sub-card-body'>Users leaked </p>
{/* <p className='sub-card-footer'>Know yours</p> */}
</>)}
</div>
</div>
<div className='col-md-12 col-lg-4'>
<div className='sub-card position-relative'>
{props.loading ? (<LoaderComponents small={true}/>) : (<>
<p className='sub-card-header'><RecorderCount recordCount={props?.data?.employeesCount}/></p>
<br className='disp-mb-none'/>
<br className='disp-mb-none'/>
<p className='sub-card-body'>Corporate Credentials leaked</p>
{/* <p className='sub-card-footer'>Know yours</p> */}
</>)}
</div>
</div>
<div className='col-md-12 col-lg-4'>
<div className='sub-card position-relative'>
{props.loading ? (<LoaderComponents small={true}/>) : (<>
<p className='sub-card-header'><RecorderCount recordCount={props?.data?.numDocs}/></p>
<br className='disp-mb-none'/><br className='disp-mb-none'/>
<p className='sub-card-body'>Credentials leaked </p>
{/* <p className='sub-card-footer'>Know yours</p> */}
</>)}
</div>
</div>
</div>
<div className='concerns-section'>
    <div className='heder-line'>
            <div className={"line carousal-line"} ></div>
    </div>
    <div className='col-12 d-flex justify-content-center'>
    <div className='landscape-text gradient-text'>
    Landscape of data threats - Largest breaches 
    </div>
    </div>
<div className='concerns-heading'>
Strengthen defenses against breaches and sophistication.
</div>
</div>
<div className='full-width-scroll'>
<div className='row full-width-scroll-section margin-bottom-50 h-200'>
<div className='col-3 d-flex'>
<div className={'concern-card-wrapper'+(showCard1 ? '-highlight' : '')} onMouseEnter={() => setShowCard1(true)} onMouseLeave={() => setShowCard1(false)}>
<div className='concern-card cursor-pointer' onClick={()=>navigateExternal('https://www.darkreading.com/vulnerabilities-threats/researchers-discover-thousands-of-lg-smart-tvs-at-risk-of-attacks')}>
{!showCard1 && 
<>
<p className='concern-card-header-text'>LG Smart TVs at Risk of Attacks, Thanks to 4 OS Vulnerabilities</p>
<br/>
<br/>
<br/>
<br/>
<p className='mb-0 concern-date'>19th April 2024</p>
</>
}
{showCard1 && 
    <p className='concern-card-text'>They impact webOS 4.9.7-5.30.40 running on LG43UM7000PLA, webOS 5.5.0-04.50.51 running on OLED55CXPUA, webOS 6.3.3-442 (kisscurl-kinglake)-03.36.50 running on OLED48C1PUB, and webOS 7.3.1-43 (mullet-mebin)-03.33.85 running on OLED55A23LA.</p>
}
</div>
</div>
</div>
<div className='col-3 d-flex'>
<div className={'concern-card-wrapper'+(showCard2 ? '-highlight' : '')} onMouseEnter={() => setShowCard2(true)} onMouseLeave={() => setShowCard2(false)}>
<div className='concern-card cursor-pointer' onClick={()=>navigateExternal('https://thehackernews.com/2024/04/hackers-target-middle-east-governments.html')}>
{!showCard2 && 
<>
    <p className='concern-card-header-text'>Hackers Target Middle East Governments with Evasive "CR4T" Backdoor</p>
    <br/>
    <br/>
<br/>
    <p className='mb-0 concern-date'>19th April 2024</p>
    </>
}
    {showCard2 && 
<p className='concern-card-text'>A new threat, 'DuneQuixote', targets Middle Eastern governments with sophisticated evasion tactics and uses a sneaky cross-platform backdoor called CR4T. </p>
}
</div>
</div>
</div>
<div className='col-3 d-flex'>
<div className={'concern-card-wrapper'+(showCard3 ? '-highlight' : '')} onMouseEnter={() => setShowCard3(true)} onMouseLeave={() => setShowCard3(false)}>
<div className='concern-card cursor-pointer' onClick={()=>navigateExternal('https://thehackernews.com/2024/04/global-police-operation-disrupts.html')}>
{!showCard3 && 
<>
    <p className='concern-card-header-text'>Global law enforcement cracked down on LabHost phishing service!</p>
    <br/>
    <br/>
    <br/>
<br/>
    <p className='mb-0 concern-date'>18th April 2024</p>
    </>
}
    {showCard3 && 
<p className='concern-card-text'>As many as 37 individuals have been arrested as part of an international crackdown on a cybercrime service called LabHost that has been used by criminal actors to steal personal credentials from victims around the world.</p>
}
</div>
</div>
</div>
<div className='col-3 d-flex'>
<div className={'concern-card-wrapper'+(showCard4 ? '-highlight' : '')} onMouseEnter={() => setShowCard4(true)} onMouseLeave={() => setShowCard4(false)}>
<div className='concern-card cursor-pointer' onClick={()=>navigateExternal('https://thehackernews.com/2024/04/hackers-exploit-openmetadata-flaws-to.html')}>
{!showCard4 && 
<>
    <p className='concern-card-header-text'>Hackers Exploit OpenMetadata Flaws to Mine Crypto on Kubernetes</p>
    <br/>
    <br/>
<br/>
    <p className='mb-0 concern-date'>17th April 2024</p>
    </>
}
    {showCard4 && 
<p className='concern-card-text'>Heads up, Kubernetes users! Hackers have found a way into OpenMetadata and are using your resources to mine cryptocurrencies</p>
}
</div>
</div>
</div>
</div>
</div>
<div className='margin-top-50 margin-bottom-50 text-center m-4'>
    <button className='btn btn-primary p-3 find-btn' onClick={navigateToSubscription}>Let’s find yours<img className='mx-2' src={go} alt="img"/></button>
</div>
{/* <div className='full-width-scroll'> */}
<div className='trusted-section margin-top-50 margin-bottom-50'>
<div className='row'>
    <div className='col-md-12 col-lg-6'>
<p className='trusted-text'>Proactive Defense Against  <span className='purple-text'>Infostealers</span> and <span className='purple-text'>Threat Actors</span> Worldwide.</p>
    </div>
    <div className='col-md-12 col-lg-6 overflow-scroll'>
        {gridData.map((item, index) => (
        <div className='trusted-card'>
            <p className='trusted-card-heading'>{item.header} </p>
            <p className='trusted-card-body'>{item.statement}</p>
        </div>
        ))};
    </div>

</div>
</div>
{/* </div> */}
<div className='text-center'>
<div className='d-flex w-100 justify-content-center'>
<p className='sub-card-footer'>Recent breaches</p>
</div>
<div className='partner-text'>
Partner with us to fortify defenses against escalating data leaks and sophisticated threats globally.
</div>
<div className='partner-counts'>
<div className='row text-left'>
    <div className='col-4 mobile-partner-counts position-relative'>
    {props.loading ? (<LoaderComponents small={true}/>) : (<>
<p className='partner-counts-header'><RecorderCount recordCount={props?.data?.usersCount}/></p>
<p className='sub-card-footer partner-counts-body'>Users leaked </p>
</>)}
    </div>
    <div className='col-4 mobile-partner-counts position-relative'>
    {props.loading ? (<LoaderComponents small={true}/>) : (<>
<p className='partner-counts-header'><RecorderCount recordCount={props?.data?.employeesCount}/></p>
<p className='sub-card-footer partner-counts-body'>Corporate Credentials leaked  </p>
</>)}
    </div>
    <div className='col-4 mobile-partner-counts position-relative'>
    {props.loading ? (<LoaderComponents small={true}/>) : (<>
<p className='partner-counts-header'><RecorderCount recordCount={props?.data?.numDocs}/></p>
<p className='sub-card-footer partner-counts-body'>Credentials leaked  </p>
</>)}
    </div>
</div>
<div className='margin-top-50 margin-bottom-50 text-center'>
    <button className='btn btn-primary p-3 margin-top-50 find-btn' onClick={navigateToSubscription}>Know your data breach<img className='mx-4' src={go} alt="img"/></button>
</div>
<br/>
<div>
    <SubscriptionPlans/>
</div>
</div>
<div className='footer-section'>
<div className='w-100'></div>
</div>
<div className='footer-logo'>
<img src={logo} width={150} height={40} alt="img" className=''/>
</div>
<div className='row'>
    <div className='col-md-12 col-lg-6'>
    <p className='p-0 m-0 sub-card-footer feature-moto'>Let’s secure the future</p>
    <p className='p-0 m-0 feature-moto'>of your company.</p>
    </div>
    <div className='col-md-12 col-lg-6'>
    <div className=' text-center m-4'>
    <button className='btn btn-primary p-3 find-btn' onClick={navigateToSubscription}>Find the current risks<img className='mx-4' src={go} alt="img"/></button>
</div>
    </div>
</div>
<div className='margin-top-50 margin-bottom-50 footer'>
<hr/>
</div>
<div className='margin-top-50 margin-bottom-50'>
<div className='d-lg-flex justify-content-between policy-text w-100'>
<div className='mobile-footer-section'><a className='mobile-footer-section-tag' href='/#/privacypolicy' target='_blank'>Privacy Policy</a><img className='mobile-footer-section-img' src={go} alt='img'/></div>
<div className='mobile-footer-section'><a className='mobile-footer-section-tag' href='/#/support' target='_blank'>Support</a><img className='mobile-footer-section-img' src={go} alt='img'/></div>
<div className='mobile-footer-section'><a className='mobile-footer-section-tag' href='/#/cancellationpolicy' target='_blank'>Cancellation Policy</a><img className='mobile-footer-section-img' src={go} alt='img'/></div>
<div className='mobile-footer-section'><a className='mobile-footer-section-tag' href='/#/termsofservice' target='_blank'>Terms Of Service</a><img className='mobile-footer-section-img' src={go} alt='img'/></div>
<div className='mobile-footer-section'>@2024 InfoLeak</div>
</div>
</div>
</div>
</>)
}

export default Static;