import './SubscriptionSuccess.css';
import { useNavigate } from "react-router-dom";
import checkIcon from "../../assets/images/check-circle.svg";
import { Button } from 'react-bootstrap';
import { memo, useCallback, useEffect, useState } from 'react';
import { getSubscription } from '../../api/getDetails';

const SubscriptionSuccess = memo(function SubscriptionSuccess() {

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const [subscriptionType, setSubscriptionType] = useState('ENTERPRISE');

  useEffect(()=>{
    if(user) {
      getSubscription('api/payment/retrive', user.id).then((response) => {
        setSubscriptionType(response.data.subscriptionType);
      }).catch((error) => {

      })
    }
  },[])

  const navigateToLogin = useCallback(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    navigate('/');
  }, [])

  const navigateToDomains = useCallback(() => {
    navigate('/main/domains');
  }, [])

  return (
    <div className='sub-success'>
      <br/>
      <br/>
      <br/>
      <div className='col-12 d-flex'>
      <div className='col-2'></div>
      <div className='col-8 success-card p-4 d-grid justify-content-center'>
        <div className='col-12 d-flex align-items-center mt-2'>
            <p className='mb-0'><img src={checkIcon} height="35" width="35"/></p>
            <p className='mx-2 mb-0 success-msg'>Success! Let's get started</p>
        </div>
        <div className='col-12 my-4 text-center'>
            <p className='mb-0 active-msg'>Your infoleak cybersecurity plan is now active and</p>
            <p className='mb-0 active-msg'>will expire on 18/04/2025</p>
        </div>
        <div className='col-12 py-4 text-center'>
          {subscriptionType === 'ENTERPRISE' ? (<Button className="cursor-pointer text-white goto-btn mb-0" onClick={navigateToDomains}>Add domains</Button>):(<Button className="cursor-pointer text-white goto-btn mb-0" onClick={navigateToLogin}>Continue</Button>)}
        </div>
      </div>
      <div className='col-2'></div>
      </div>
    </div>
  );
})

export default SubscriptionSuccess;
