import './LandingPage.css';
import React from 'react';
import withRouter from '../../withRouter';
import Search from '../LandingSearch/LandingSearch';
import Static from '../../components/static/static.component';
import logo from '../../assets/images/infoleak_text.svg';
import { Link } from 'react-router-dom';
import { getInfoleaks, getSubscription } from '../../api/getDetails';
import { Dropdown } from 'react-bootstrap';
import { tokenExpired } from '../../api/getDetails';

class LandingPage extends React.Component {
  
    constructor(props) { 
        super(props);
        this.state = {
          data: null,
          userDetails: JSON.parse(localStorage.getItem('user'))
        }
      }
      componentDidMount(){
        this.validateToken();
      }

      validateToken() {
        const token = JSON.parse(window.localStorage.getItem('access_token'));
        if(token) {
          tokenExpired(token).then((response) => {
            getSubscription('api/payment/retrive', JSON.parse(localStorage.getItem('user')).id).then((response) => {
              if(response.data.subscriptionStatus === 'active' || response.data.subscriptionStatus === 'trialing'){
              window.location.href = window.location.origin+'/#/main/leakeddetails';
              } else {
                window.location.href = window.location.origin+'/#/main/search';
              }
            })
          }).catch(error => { 
            localStorage.removeItem('user');
    localStorage.removeItem('access_token');
            this.state = {
              data: null,
              userDetails: null
            }
            this.getInfoLeaks();
          })
        } else {
          this.getInfoLeaks();
        }
      }

      getInfoLeaks() {
        this.setState({data:this.state.data, userDetails: this.state.userDetails, loading: true});
        getInfoleaks('api/infoleaks').then((response) => {
          this.setState({data:response.data, userDetails: this.state.userDetails, loading: false});
        }).catch(error => {
          console.log(error);
        })
      }
      logout=()=>{
        localStorage.removeItem('user');
    localStorage.removeItem('access_token');
        this.setState({userDetails: null, data: this.state.data, loading: false});
      }
      styles = {
        background: '#1B1D25 !important',
    borderRadius: '50%',
    fontSize: '20px',
    color: '#ebebeb',
    fontStyle: "normal",
    fontWeight: "500",
      }
      render() {
        return (
          <div className='wrapper'>
          <div className={'col-12 d-flex wraper-header'}>
            <div className='col-md-2'></div>
            <div className='col-md-8 col-12 d-flex header-section py-3'>
              <div className='w-50 px-4'>
              <img src={logo} width="150" height="40"/>
              </div>
              <div className='w-50 d-flex justify-content-end align-items-center px-4'>
              <Link to={'/aboutus'} className="aboutus-link cursor-pointer px-2 right-border mobile-font"> About Us </Link>
                { !this.state.userDetails &&
                <>
                <Link to={'/login'} className="signin-link cursor-pointer px-2 mobile-font"> Sign In </Link>
                </>
                }
                { this.state.userDetails && this.state.userDetails.name &&
                <div className="col-4 d-flex justify-content-end align-items-center">
                <span className="d-flex header-menu-userSection-textlable cursor-pointer position-relative user-name justify-content-end">
                  <span className='header-name'>{this.state.userDetails.name}</span>
                  <Dropdown>
      <Dropdown.Toggle style={this.styles} variant='secondary' className='header-menu-userSection-textlable-icon user-icon position-absolute d-flex justify-content-center align-items-center'>
      {this.state.userDetails?.name[0].toUpperCase()}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                </span>
              </div>
                }
              </div>
            </div>
            <div className='col-md-2'></div>
          </div>
          <div className='landing-search'>
            <Search />
          </div>
          <div className='container'>
            <Static data={this.state.data} loading={this.state.loading}/>
          </div>
          </div>
        )
      }
}

export default withRouter(LandingPage);