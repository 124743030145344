import './HeaderComponent.css';
import logo from '../../assets/images/infoleak_text.svg';
import copy_icon from '../../assets/images/copy_icon.svg';
import document_icon from '../../assets/images/document.svg';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Dropdown } from 'react-bootstrap';
import { getAPIToken, createAPIToken } from '../../api/getDetails';
import { connect } from "react-redux";

function HeaderComponent({user, 
  subscriptionStatus, 
  paymentMethod,
  subscriptionType, saveUser, savePayment}) {

  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('user')));
  const [isTokenGenerated, setIsTokenGenerated] = useState(false);
  const [isDocumentsScreen, setIsDocumentsScreen] = useState(false);
  const [generatedToken, setGeneratedToken] = useState('INFOLEAKSINFOLEAKS');
  const location = useLocation();

    const [isSuccess, setIsSuccess] = useState(false);
    useEffect(()=>{
      if(window.location.toString().split("/")[window.location.toString().split("/").length -1] === 'documents') {
        setIsDocumentsScreen(true);
      } else {
        setIsDocumentsScreen(false);
      }
      fetchAPIToken();
    },[location]);

  function fetchAPIToken() {
    getAPIToken('api/apitokens/get-api-token').then((response) => {
      if(response?.data?.apiTokenId) {
        setIsTokenGenerated(true);
        setGeneratedToken(response.data.apiTokenId);
      } else {
        setGeneratedToken(false);
      }
    }).catch((error) => {
      setIsTokenGenerated(false);
    })
  }

  function generateAPIToken() {
    createAPIToken('api/apitokens/create-api-token').then((response) => {
      if(response?.data?.apiTokenId) {
        setIsTokenGenerated(true);
        setGeneratedToken(response.data.apiTokenId);
      } else {
        setGeneratedToken(false);
      }
    }).catch((error) => {
      setIsTokenGenerated(false);
    })
  }

  function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    navigate('/login');
  }

  function sendInvite() {
    navigate('/main/sendinvite');
  }

  function manageUsers() {
    navigate('/main/users');
  }
  function managePayments(){
    navigate('/main/changepaymentMethod');
  }

  function goHome() {
    if((!!subscriptionStatus) && (subscriptionStatus == 'active' || subscriptionStatus == 'trialing')){
      navigate('/main/leakeddetails');
    } else if(subscriptionStatus) {
      navigate('/main/search');
    } else {
      navigate('/');
    }
  }

  function capitalizeFirstLetter() {
    return userDetails.name?.charAt(0).toUpperCase();
  }

  function navigateToApiDocuments() {
    navigate('/main/documents');
  }

  function cancelSubscription() {
    navigate('/main/unsubscribe');
  }

  return (
    <div className="col-12 d-flex px-4 py-4 header-border app-header">
        <div className="col-4 px-2">
        <img src={logo} width="150" height="40" className='cursor-pointer' onClick={goHome}/>
        </div>
        <div className="col-8 d-flex justify-content-end align-items-center">
          { userDetails &&
          <>
          {!isDocumentsScreen &&
          <div className='disp-mob-none'>
            <div className='mx-2 d-flex align-items-center'>
              <span className='api-docs-link mx-3' onClick={navigateToApiDocuments}><img src={document_icon} width="35" height="35" className='cursor-pointer'/><p className='mb-0 mx-2'>API Documents</p></span>
              <div className='d-flex align-items-center api-doc-section'>
                <span className='api-key-text'>{isTokenGenerated ? '' : 'Free '}API Key</span>
                <span className='api-key-value mx-2'>{generatedToken.slice(0, 15)+(generatedToken.length > 15 ? '...' : '')}</span>
                <img src={copy_icon} width="25" height="25" className='cursor-pointer' onClick={() =>  navigator.clipboard.writeText(generatedToken)}/>
              </div>
            </div>
            </div>
          }
          {/* {!isTokenGenerated && <Button variant='secondary' className='mx-2' onClick={generateAPIToken}>Generate API Key</Button>} */}
          <span className="d-flex header-menu-userSection-textlable cursor-pointer position-relative user-name">
            <span>{userDetails.name}</span>
            <Dropdown className='position-unset'>
            <Dropdown.Toggle variant='secondary' className='header-menu-userSection-textlable-icon user-icon position-absolute d-flex justify-content-center align-items-center'>
               <span className='letter-spacing-normal font-18'>{userDetails?.name.slice(0,2).toUpperCase()}</span>
               </Dropdown.Toggle>
               <Dropdown.Menu>
                { userDetails.role == 'ADMIN' &&
                <>
                <Dropdown.Item onClick={sendInvite}>Send Email</Dropdown.Item>
                <hr className='m-2'/>
                <Dropdown.Item onClick={manageUsers}>Update Payment Status</Dropdown.Item>
                <hr className='m-2'/>
                <Dropdown.Item onClick={managePayments}>Update Payment Method</Dropdown.Item>
                <hr className='m-2'/>
                </>
                }
                {(userDetails.role != 'ADMIN' && paymentMethod == 'PAYPAL' && subscriptionType != 'ONETIME') &&
                  <>
                  <Dropdown.Item onClick={cancelSubscription}>Cancel Subscription</Dropdown.Item>
                  <hr className='m-2'/>
                  </>
                }
                 <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
               </Dropdown.Menu>
             </Dropdown>
          </span>
          </>
          }
        </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    subscriptionStatus: state.user.subscriptionStatus,
    subscriptionType: state.user.subscriptionType,
    paymentMethod: state.user.paymentMethod
  }
}
const mapDispatchToProps = (dispatch) => ({
  saveUser: (payload) => dispatch({ type: "user", payload: payload }),
  savePayment: (payload) => dispatch({ type: "subscription", payload: payload })
});


export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
