import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/infoleak_text.svg';
import './ResetPasswordSuccess.css';
import checkIcon from "../../assets/images/check-circle.svg";

function ResetPasswordSuccess() {
  const navigate = useNavigate();

  useEffect(()=>{
  },[])

  function navigateToSignIn() {
    navigate('/login');
  }
  function goToHome(){
    navigate('/');
  }

  return (
    <div className="pre-login-screen">
      <div className="pre-login-screen-header pt-3">
          <div className="col-12 d-flex p-4">
              <div className="col-6 position-relative">
              <img className='cursor-pointer' src={logo} width="150" height="40" onClick={goToHome}/>
              </div>
              <div className="col-6 position-relative text-right d-flex justify-content-end align-items-center">
                  <button className='header-btn px-4 py-2' onClick={navigateToSignIn}>Sign In</button>
              </div>
          </div>
      </div>
      <div className="pre-login-content-screen">
          <div className="pre-login-content-block px-4">
          <div className="p-4">
              <div className='col-12 d-flex align-items-center mt-4'>
                <p className='mb-0'><img src={checkIcon} height="35" width="35"/></p>
                <p className='mx-2 mb-0 reset-success-msg'>Password Reset Successfully.</p>
              </div>
                  <div className='my-4'><button className='create-btn px-4 py-2 w-100' onClick={navigateToSignIn}>Sign In</button></div>
              </div>
          </div>
          <p className="mb-0 mt-4 text-white w-100 text-center">At Infoleaks, we Unifying Security from People to Platform</p>
      </div>
    </div>
  );
}

export default ResetPasswordSuccess;
