import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './subscriptionPlans.css';
import { useNavigate } from "react-router-dom";
import checkIcon from "../../assets/images/check-icon.svg";

function SubscriptionPlans() {
    const navigate = useNavigate();

    function goToSignup() {
        navigate('/signup');
    }

    return (
        <div className='disp-mobile-none subscription-plans margin-top-50'>
            <div className='text-center'>
                <div className='col-12 d-grid justify-content-center align-items-center'><p className='mb-0 plans-heading'>Subscription Plans</p></div>
                <p className='mb-0 mt-2 plans-sub-heading'>Subscribe to real time visibility</p>
                <p className='mb-0 plans-sub-heading'>into data leakages</p>
            </div>
            <div className='margin-top-50 row'>
                <div className='col-lg-4 col-md-12 premium'>
                    <div className='sub-plan-card'>
                        <div className='plan-title-row mt-4'>
                            <div className='col-7 plan-label-bg'>Premium</div>
                            <div className='col-5'></div>
                        </div>
                        <div className='col-12 p-4'>
                            <p className='card-text mt-3'>Wildcard search for 30 days</p>
                            <div className='my-4'>
                            <p><img src={checkIcon} height="15" width="15"/><span className='card-text mx-2'>Real-time Notifications</span></p>
                            <p><img src={checkIcon} height="15" width="15"/><span className='card-text mx-2'>Search API</span></p>
                            </div>
                            <div className='my-4 pt-2 pricing-row'>
                                <div>
                                    <p className='mb-0'><span className='dollar'>$</span><span className='price'>2500</span></p>
                                </div>
                                <div className='mx-2 pt-3'>
                                    <p className='mb-0 pricing-text'>per</p>
                                    <p className='mb-0 pricing-text'>month</p>
                                </div>
                            </div>
                            <div className='pt-3 col-12'>
                                <Button onClick={goToSignup} className='w-100'>Subscribe now</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-12 px-0 py-3'>
                    <div className='business'>
                        <div className='sub-plan-card'>
                            <div className='plan-title-row mt-4'>
                                <div className='col-7 plan-label-bg'>Business</div>
                                <div className='col-5'></div>
                            </div>
                            <div className='col-12 py-3 px-4'>
                                <p className='card-text mb-0'>Threat Intelligence of domain valid for 30 days</p>
                                <div className='my-3'>
                                <p className='mb-2'><img src={checkIcon} height="15" width="15"/><span className='card-text mx-2'>Real-time Notifications</span></p>
                                <p className='mb-2'><img src={checkIcon} height="15" width="15"/><span className='card-text mx-2'>Search API</span></p>
                                </div>
                                <div className='pricing-row'>
                                    <div>
                                        <p className='mb-0'><span className='dollar'>$</span><span className='price'>250</span></p>
                                    </div>
                                    <div className='mx-2 pt-3'>
                                        <p className='mb-0 pricing-text'>per</p>
                                        <p className='mb-0 pricing-text'>month</p>
                                    </div>
                                </div>
                                <p className='mb-0 pricing-text-sub'>For single domain</p>
                                <div className='mt-4 pt-1 col-12'>
                                    <Button onClick={goToSignup} className='w-100'>Subscribe now</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-12 px-0 py-3'>
                    <div className='my-3 onetime'>
                        <div className='sub-plan-card'>
                        <div className='plan-title-row mt-3'>
                            <div className='col-7 plan-label-bg'>One-Time Access</div>
                            <div className='col-5'></div>
                        </div>
                        <div className='col-12 p-4'>
                            <p className='card-text mb-0'>Wildcard search for 7 days</p>
                            <div className='my-4'>
                            <p className='mb-2'><img src={checkIcon} height="15" width="15"/><span className='card-text mx-2'>Real-time Notifications</span></p>
                            <p className='mb-2'><img src={checkIcon} height="15" width="15"/><span className='card-text mx-2'>Search API</span></p>
                            </div>
                            <div className='mt-2 pt-1 pricing-row'>
                                <div>
                                    <p className='mb-0'><span className='dollar'>$</span><span className='price'>500</span></p>
                                </div>
                                <div className='mx-2 pt-3'>
                                    <p className='mb-0 pricing-text'>per 7</p>
                                    <p className='mb-0 pricing-text'>days</p>
                                </div>
                            </div>
                            <div className='mt-4 col-12'>
                                <Button onClick={goToSignup} className='w-100'>Subscribe now</Button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionPlans;   