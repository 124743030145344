import { memo, useCallback, useEffect, useState } from 'react';
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSubscription } from '../../api/getDetails';
import back_icon from '../../assets/images/back_icon.svg';
import LeakedReposMobile from '../../components/leaked-repos-mobile/leakedReposMobile';
import LeakedFiles from '../../components/leakedfiles/leakedFiles';
import LeakedRepos from '../../components/leakedrepos/leakedRepos';
import './secureCodeLeaks.css';

const SecureCodeLeaks = memo(function SecureCodeLeaks({domain}) {
    
    const navigate = useNavigate();
    const [selectedDomain, setSelectedDomain] = useState(domain);
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('user')));
    const [selectedRepo, setSelectedRepo] = useState(null);
    const [repoCount, setRepoCount] = useState(0);
    const [reposChecked, setReposChecked] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState(null);
    const [domains, setDomains] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        getDomains();
    },[]);

    function getDomains() {
        if(!selectedDomain) {
            navigate('/main/leakeddetails');
            return;
        }
        getSubscription('api/payment/retrive', userDetails.id).then((response) => {
            setSubscriptionType(response.data.subscriptionType);
            if(response.data.subscriptionStatus === 'active' || response.data.subscriptionStatus === 'trialing') {
                if(response.data.subscriptionType === 'ENTERPRISE') {
                    getDomains('api/user/get-domains', userDetails.id).then((domainResponse) => {
                        if(domainResponse.data.domains.length>0){
                            setDomains(domainResponse.data.domains);
                        } else {
                            setDomains([]);
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const goBack = useCallback(() => {
        getSubscription('api/payment/retrive', userDetails?.id).then((response) => {
            if(response.data.subscriptionStatus === 'active' || response.data.subscriptionStatus === 'trialing') {
                  navigate('/main/leakeddetails');
            } else {
                  navigate('/main/search');
            }
        }).catch((error) => {
            console.log(error);
            navigate('/main/search');
        })
    }, [])

    const repoSelected = useCallback((repo) => {
        setSelectedRepo(repo);
    }, [])

    const changeDomain = useCallback((event) => {
        setSelectedDomain(event.target.value);
    }, [])

return (<>
{selectedDomain &&
<div className='row p-4'>
    <div className='col-12'>
    <div className='row source-row'>
        <span className='source-header-text'><img src={back_icon} width="25" height="25" className='cursor-pointer mx-2' onClick={goBack}/>Source code leaks ({repoCount})</span>
        {subscriptionType && subscriptionType != 'ENTERPRISE' && 
            <div className='source-domain-section mx-2'>
                <span className='source-domain-text'>{selectedDomain}</span>
            </div>
        }
        {subscriptionType && subscriptionType === 'ENTERPRISE' && 
            <Form.Select size="lg" onChange={(event) => changeDomain(event)}>
                {domains && domains.map((dom, index) => (
                    <option value={dom} key={index}>{dom}</option>
                ))}
            </Form.Select>
        }
    </div>
    </div>
    {(width > 768) && ((!reposChecked && repoCount == 0) || (reposChecked && repoCount > 0)) && <><div className='col-3 mt-4 disp-mob-none'>
    <LeakedRepos callBack={(repo)=> repoSelected(repo)} domain={selectedDomain} reposCount={(reposCount) => setRepoCount(reposCount)} repoChecked={(repoChecked) => setReposChecked(repoChecked)}/>
    </div>
    <div className='col-9 mt-4 disp-mob-none'>
    <LeakedFiles domain={selectedDomain} repo={selectedRepo}/>
    </div>
    </>
    }
    {(width <= 768) && <div className='col-12 disp-mob-block p-0'>
        <LeakedReposMobile domain={selectedDomain} reposCount={(reposCount) => setRepoCount(reposCount)}/>
    </div>}
    { reposChecked && repoCount == 0 && 
        <div className='no-repos-found'><h2>Congratulations! No issues found.</h2></div>
    }
</div>
}
</>)
})

function mapStateToProps(state) {
    return {
        domain: state.user.string
    }
  }
export default connect(mapStateToProps)(SecureCodeLeaks);