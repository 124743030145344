import './Signup.css';
import logo from './assets/images/infoleak_text.svg'
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { regesterUser, verifyEmailUser } from './api/postDetails';

function Signup() {

  const navigate = useNavigate();

  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showUserNameError, setShowUserNameError] = useState(false);
  const [showUserExist, setShowUserExist] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
  const [showPasswordMatchError, setShowPasswordMatchError] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [domainInvalid, setDomainInvalid] = useState(false);
  const domainsList = ["gmail.com","yahoo.com","hotmail.com","aol.com","hotmail.co.uk","hotmail.fr","msn.com","yahoo.fr","wanadoo.fr",
  "orange.fr","comcast.net","yahoo.co.uk","yahoo.com.br","yahoo.co.in","live.com","rediffmail.com","free.fr",
  "gmx.de","web.de","yandex.ru","ymail.com","libero.it","outlook.com","uol.com.br","bol.com.br",
  "mail.ru","cox.net","hotmail.it","sbcglobal.net","sfr.fr","live.fr","verizon.net","live.co.uk","googlemail.com","yahoo.es","ig.com.br",
  "live.nl","bigpond.com","terra.com.br","yahoo.it","neuf.fr","yahoo.de","alice.it","rocketmail.com","att.net","laposte.net","facebook.com",
  "bellsouth.net","yahoo.in","hotmail.es","charter.net","yahoo.ca","yahoo.com.au","rambler.ru","hotmail.de","tiscali.it","shaw.ca","yahoo.co.jp",
  "sky.com","earthlink.net","optonline.net","freenet.de","t-online.de","aliceadsl.fr","virgilio.it","home.nl","qq.com","telenet.be","me.com","yahoo.com.ar",
  "tiscali.co.uk","yahoo.com.mx","voila.fr","gmx.net","mail.com","planet.nl","tin.it","live.it","ntlworld.com","arcor.de","yahoo.co.id","frontiernet.net",
  "hetnet.nl","live.com.au","yahoo.com.sg","zonnet.nl","club-internet.fr","juno.com","optusnet.com.au","blueyonder.co.uk","bluewin.ch","skynet.be","sympatico.ca",
  "windstream.net","mac.com","centurytel.net","chello.nl","live.ca","aim.com","bigpond.net.au"]
  function navigateToSignIn() {
    navigate('/login');
  }

  function registerUser() {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(username !== '' && password !== '' && email !== '' && confirmPassword !== '' && !showPasswordMatchError && emailPattern.test(email)) {
      setShowUserNameError(false);
      setShowUserNameError(false);
      setShowPasswordError(false);
      setShowConfirmPasswordError(false);
      setShowUserExist(false);
      regesterUser({name: username, password: password, username: email}).then((response) => { 
        // localStorage.setItem('userName', username);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('access_token', JSON.stringify(response.data.accessToken));
        navigate('/main/chooseplan');
      }).catch(error => { 
        if(error.response.data.message === 'User already exists') {
          setShowUserExist(true);
        }
      })
    } else {
      if(!username || username === '') {
        setShowUserNameError(true);
      }
      if(!password || password === ''){
        setShowPasswordError(true);
      }
      if(!email || email === '' || !emailPattern.test(email)){
        setShowEmailError(true);
      }
      if(!confirmPassword || confirmPassword === ''){
        setShowConfirmPasswordError(true);
      }
    }
  }

  function verifyEmail() {
    setDomainInvalid(false);
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(email !== '' && emailPattern.test(email)) {
      // if(domainsList.indexOf(email.split('@')[1])>-1){
      //   setDomainInvalid(true);
      //   return;
      // }
      setShowEmailError(false);
      setShowUserExist(false);
      verifyEmailUser({username: username, email: email}).then((response) => {
        setEmailVerified(true);
      }).catch(error => {
        if(error.response.data.message === 'User already exists') {
          setShowUserExist(true);
        }
      })
    } else {
      if(!email || email === '' || !emailPattern.test(email)){
        setShowEmailError(true);
      }
    }
  }

  function userNameChange(event) {
    if(event.target.value !== '') {
      setUserName(event.target.value);
      setShowUserNameError(false);
    } else {
      setShowUserNameError(true);
    }
  }

  function passwordChange(event) {
    if(event.target.value !== '') {
      setPassword(event.target.value);
      setShowPasswordError(false);
      if(event.target.value && confirmPassword) {
        if(event.target.value !== confirmPassword) {
          setShowPasswordMatchError(true);
        } else {
          setShowPasswordMatchError(false);
        }
      }
    } else {
      setShowPasswordError(true);
    }
  }

  function emailChange(event) {
    if(event.target.value !== '') {
      setEmail(event.target.value);
      setShowEmailError(false);
    } else {
      setShowEmailError(true);
    }
  }

  function confirmPasswordChange(event) {
    if(event.target.value !== '') {
      setConfirmPassword(event.target.value);
      setShowConfirmPasswordError(false);
      if(password && event.target.value) {
        if(password !== event.target.value) {
          setShowPasswordMatchError(true);
        } else {
          setShowPasswordMatchError(false);
        }
      }
    } else {
      setShowConfirmPasswordError(true);
    }
  }
  function goToHome(){
    navigate('/');
  }

  return (
    <div className="pre-login-screen">
      <div className="pre-login-screen-header pt-3">
          <div className="col-12 d-flex p-4">
              <div className="col-6 position-relative">
              <img className='cursor-pointer' src={logo} width="150" height="40" onClick={goToHome}/>
              </div>
              <div className="col-6 position-relative text-right d-lg-flex justify-content-end align-items-center disp-mob-none">
                  <p className="cursor-pointer text-white mx-3 mb-0 account-link">Already have account?</p>
                  <button className='header-btn px-4 py-2' onClick={navigateToSignIn}>Sign In</button>
              </div>
          </div>
      </div>
      <div className="pre-login-content-screen">
          <div className="pre-login-content-block px-4">
          {!emailVerified &&  <div className="p-4">
              <p className='my-2 signin-header'>Create new account</p>
                  <div className='my-4'>
                    <Form.Control required size="lg" type="text"
                     placeholder="Name" onChange={userNameChange}/>
                    {showUserNameError && <div className='error-msg'>Please enter Name.</div>}
                  </div>
                  <div>
                    <Form.Control size="lg" required type="text" placeholder="Email" onChange={emailChange} onKeyUp={(e) => {e.target.value = e.target.value.toLowerCase()}}/>
                    {showEmailError && <div className='error-msg'>Please enter valid Email.</div>}
                  </div>
                  <div>
                    {showUserExist && <div className='error-msg'>User already exist.</div>}
                    {domainInvalid && <div className='error-msg'>The domain you choosed is not a valid one.</div>}
                  </div>
                  {/* {emailVerified && <div className='my-4'>
                    <Form.Control required size="lg" type="password" placeholder="Password" onChange={passwordChange}/>
                    {showPasswordError && <div className='error-msg'>Please enter Password.</div>}
                  </div>}
                  {emailVerified && <div>
                    <Form.Control size="lg" required type="password" placeholder="Re-enter Password" onChange={confirmPasswordChange}/>
                    {showConfirmPasswordError && <div className='error-msg'>Please enter Confirm Password.</div>}
                  </div>} */}
                  {showPasswordMatchError && <div className='error-msg my-2 text-center'>Passwords doesn't match.</div>}
                  <div className='my-4'>
                    <button className='create-btn px-4 py-2 w-100' onClick={emailVerified?registerUser:verifyEmail}>{emailVerified?'Create new account':'Verify Email'}</button>
                  </div>
                  <div className='disp-lg-none disp-mob-block'>
                  <p className='my-2 text-center white-font'>Already have account? <span className='cursor-pointer color-blue' onClick={navigateToSignIn}>Sign In</span></p>
                  </div>
              </div>}
              {emailVerified && <div className="p-4">
                <p className='my-2 signin-header'>Create new account</p>
                <p className='my-2 signin-body'>A verification mail is sent to your registered email please verify it to complete your registration.</p>
                </div>}
          </div>
          <p className="mb-0 mt-4 text-white w-100 text-center">Know Your Leaks Before They're Exploited!</p>
      </div>
    </div>
  );
}

export default Signup;
