import React from 'react';
import { useParams } from 'react-router-dom';
 
const withRouter = WrappedComponent => props => {
  const params = useParams();
    window.gtag('send', 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname            
    });
  return (
    <WrappedComponent
      {...props}
      params={params}
    />
  );
};
 
export default withRouter;