import axios from "axios";
import { baseUrl, apiUrl } from "./endpoints";
import { postMethod } from "./interceptor";
import { brandScoreUrl } from "./endpoints";
// import {useDispatch, useSelector} from 'react-redux';

// post with interceptor
export const postRequest = async (url, obj) => {
  return postMethod(baseUrl + url, obj);
};

// post without interceptor
export const postUpadtePassword = async (obj) => {
  const response = await axios.post(baseUrl + 'auth/password/change/', obj);
  return response;
};

export const postRequestDetails = async (obj) => {

  const response = await axios.post(baseUrl + '', obj);
  return response;
};

export const postLoginDetails = async (obj) => {
  const config = axios.create();
  const response = await config.post(baseUrl + 'api/auth/login', obj);
  return response;
};

export const regesterUser = async (obj) => {
  const config = axios.create();
  const response = await config.post(baseUrl + 'api/auth/register', obj);
  return response;
};

export const postPayment = async (body) => {
  const response = await postMethod(baseUrl +'api/payment/save-or-update-payment', body);
  return response;
}

export const disableUserPayment = async (body) => {
  const response = await postMethod(baseUrl + 'api/payment/disablepayment', body);
  return response;
}

export const verifyEmailUser = async (obj) => {
  const config = axios.create();
  const response = await config.post(baseUrl + 'api/auth/send-verify-link', obj);
  return response;
}

export const forgotPassword = async (obj) => {
  const response = await axios.post(baseUrl + 'api/auth/forgot-password', obj);
  return response;
};

export const getBrandConsolidatedScoreData = async (domains) => {
  const config = axios.create();
  const response = await config.post(brandScoreUrl+'domain-risk/getTotalScore', {"domains": 
    domains
  });
  return response;
};

export const changePassword = async (obj) => {
  const response = await axios.post(baseUrl + 'api/auth/change-password', obj);
  return response;
};
export const setPassword = async (obj) => {
  const response = await axios.post(baseUrl + 'api/auth/set-password', obj);
  return response;
};

export const saveSubscription = async function(endPoint, body) {
  const response = await postMethod(baseUrl + endPoint, body);
  return response;
};

export const saveDomains = async function(endPoint, body) {
  const response = await postMethod(baseUrl + endPoint, body);
  return response;
};

export const postPaymentDetails = async function(endPoint, body) {
  const response = await postMethod(baseUrl + endPoint, body);
  return response;
}

export const createVendor = async function(endPoint, body) {
  const response = await postMethod(baseUrl + endPoint, body);
  return response;
}

export const notifyUser = async function(body) {
  const response = await postMethod(baseUrl + 'api/compromiseduser/notifyUsers', body);
  return response;
}

export const cancelSubscribtion = async function(body) {
  const response = await postMethod(baseUrl + 'api/payment/v3/cancel-subscription', body);
  return response;
}


export const getAPIData = async (url, obj, apiKey) => {
  const config = axios.create();
  config.interceptors.request.use(
    (request) => {
      // request.headers.Authorization = 'Bearer ' + JSON.parse(window.localStorage.getItem('access_token'));
      request.headers['api-key'] = apiKey;
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  )
  const response = await config.post(baseUrl + url, obj);
  return response;
};

export const generateReport = async function(endPoint, body) {
  const response = await postMethod(baseUrl + endPoint, body);
  return response;
}

export const getNotifiedData = async function(endPoint, body) {
  const response = await postMethod(baseUrl + endPoint, body);
  return response;
}

export const getBrandScoreDataForMultiple = async (domains) => {
  const config = axios.create();
  const response = await config.post(brandScoreUrl+'domain-risk/getIndividualScores', {"domains": domains});
  return response;
};

export const reinitiate = async (domain) => {
  const config = axios.create();
  const response = await config.get(brandScoreUrl+'domain-risk/reinitiate/'+domain);
  return response;
}