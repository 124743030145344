import './usersPaymentform.css';
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { postPayment } from '../../api/postDetails';
import arrows from "../../assets/images/discover_arrows.svg";

function UsersPaymentForm(props) {
    const navigate = useNavigate();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [planType, setPlanType] = useState('PREMIUM');
    const [planTypeInValid, setPlanTypeInValid] = useState(false);
    const [mobileInValid, setMobileInValid] = useState(false);
    const [domainCount, setDomainCount] = useState(0);
    const [daysInValid, setDaysInValid] = useState(false);
    const [noOfDays, setNoOfDays] = useState(0);
    const [domains, setDomains] = useState([]);
    const [newDomain, setNewDomain] = useState('');
    const [domainsRequired, setDomainsRequired] = useState(false);
    const [showDomainsPopUp, setShowDomainsPopUp] = useState(false);
    const domainRef = useRef();
    const domaincountRef = useRef();
    const daysRef = useRef();
    // const [domainsLength, setDomainsLength] = useState(0);
    function handleDomainChange(event, index) {
        const data = [...domains];
        data[index] = event.target.value;
        setDomains(data);
      }

      function handleNewDomain(event) {
        setNewDomain(event.target.value);
      }

      function addOnDomain() {
        if(newDomain !== '') {
            const data = domains;
            data.push(newDomain);
            domainRef.current.value = '';
            setDomains(data);
            if(domainCount<domains.length){
                setDomainCount(domains.length);
            }
            setNewDomain("");
        }
      }

    useEffect(()=>{
        if(props.user.payment.length>0){
            setPlanType(props.user.payment[0].subscriptionType);
            setDomainCount(props.user.payment[0].noOfDomains);
            domaincountRef.current.value = props.user.payment[0].noOfDomains;
        }
        if(props.user.domains.length>0){
            setDomains(props.user.domains[0].domains);
        }
    },[props])

    function submitPayment(){
        if(planType==''){
            setPlanTypeInValid(true);
            return;
        }
        if(planType=='ENTERPRISE'&&domains.length==0){
            setDomainsRequired(true);
            return;
        }
        if(planType=='ONETIME'&&noOfDays==0){
            setDaysInValid(true);
            return;
        }
        setPlanTypeInValid(false);
        setMobileInValid(false);
        postPayment({userId:props.user._id, planType:planType, noOfDomains:domainCount, domains:domains, payment_status:1, noOfDays:noOfDays}).then((response) => {
            props.callBack();
        }).catch((error) => {
            console.log(error);
        })
    }

    return(<div className='container color-white payment-form'>
<div className=''>
<div>
<Form.Check className="mx-2 d-inline" size="lg" type="radio" value="PREMIUM" onChange={(e)=>{setPlanType(e.target.value);setDomains([]);setDomainsRequired(false)}} checked={planType=='PREMIUM'}/> Premium
</div>
<br/>
<div>
<Form.Check className="mx-2 d-inline" size="lg" type="radio" value="ENTERPRISE" onChange={(e)=>{setPlanType(e.target.value);setDomains([]);setDomainsRequired(true)}} checked={planType=='ENTERPRISE'}/> Enterprise
</div>
<br/>
<div>
<Form.Check className="mx-2 d-inline" size="lg" type="radio" value="ONETIME" onChange={(e)=>{setPlanType(e.target.value);setDomains([]);setDomainsRequired(false);}} checked={planType=='ONETIME'}/> OneTime
</div>
{planTypeInValid&&<p className='danger-font'>Please select a plan</p>}
<br/>
<div className='col-4'>
<Form.Control ref={domaincountRef} size="lg" className='mobile' required type="number" placeholder="No of Domains" onKeyUp={(e)=>setDomainCount(e.target.value)}/>
{mobileInValid&&<p className='danger-font'>Please enter a valid Number of Domains</p>}
</div>
<br/>
{planType == 'ENTERPRISE' && <div>
<div className='domains'>
        <br/>
        <div className='col-12 text-left'>
            <p className='mb-0 add-domains-text'>Add your domains to monitor</p>
        </div>
        <br/>
        <div className='domains-section  d-flex'>
            <div className='col-12 mx-2 domains-card'>
            <div className='domains-block'>
            {domains.map((item, index) => (
                <div className='col-12 d-flex my-4 align-items-center' key={index}>
                    <Form.Control size="lg" className='mx-2' id={"domain_"+index} type="text" onChange={(event) => handleDomainChange(event, index)} value={item} placeholder="Enter domain here" />
                </div>
            ))}
            </div>
            <hr/>
            <div className='col-12 py-2 d-flex add-on'>
                <div className='col-6'>
                <Form.Control ref={domainRef} size="lg" className='mx-2' type="text" onChange={handleNewDomain} placeholder="Enter domain here" />
                </div>
                <div className='col-5 mx-2 px-2'>
                <Button className="cursor-pointer text-white add-domain-btn mb-0" onClick={addOnDomain}>Add domain</Button>
                </div>
            </div>
            {(domainsRequired&&domains.length==0)&&<p className='danger-font'>Domains required</p>}
            </div>
        </div>
        <br/>
    </div>
    <br/>
    </div>}
{planType == 'ONETIME' && <div className='col-4'>
<Form.Control ref={daysRef} size="lg" className='mobile' required type="number" placeholder="No of days" onKeyUp={(e)=>setNoOfDays(e.target.value)}/>
{daysInValid&&<p className='danger-font'>Please enter no of domains</p>}
<br/>
    </div>}
<div>
<Button className="cursor-pointer text-white continue-btn mb-0" onClick={submitPayment}>Submit<img src={arrows} height="15" width="15" className='mx-2'></img></Button>
</div>
</div>
</div>)
}   
export default UsersPaymentForm;