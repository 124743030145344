import { memo, useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from 'react-bootstrap';
import { getPaymentMethods, savePaymentTypes } from '../../api/getDetails';
import arrows from "../../assets/images/discover_arrows.svg";
import LoaderComponents from "../../components/loader/loader";
import "./choosepaymentmethod.css";

const ChoosePaymentMethod = memo(function ChoosePaymentMethod(props) {
    const [paymentType, setPaymentType] = useState('PAYPAL');
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);

    useEffect(()=>{
        getInitialMethods();
    }, []);

    function getInitialMethods(){
        getPaymentMethods().then((res)=>{
            setPaymentTypes(res.data);
            setPaymentType(res.data.find((f)=>f.active));
        } ).catch(error=>{

        });
    }

    function savePaymentType(){
        setLoader(true);
        savePaymentTypes(paymentType._id).then((res)=>{
            setShowPopUp(true);
            setLoader(false);
        } ).catch(error=>{
            setLoader(false);
        });
    }

    const handleClose = useCallback(() => {
        setShowPopUp(false);
    }, []);

    return (
        <div className="text-white">
            {loader ? <LoaderComponents/> : 
            <>
                {paymentTypes && paymentTypes.map((item, index) => (
                    <div>
                    <Form.Check className="mx-2 d-inline" size="lg"  type="radio" value={item} onChange={(e)=>{setPaymentType(item)}} checked={paymentType?.paymentType==item.paymentType}/> Accept payments through {item.paymentType}.
                    <div>
                    <br/>
                    </div>
                    </div>
                ))}
                <div className='col-12 text-left'>
                    <Button className="cursor-pointer text-white continue-btn mb-0" onClick={savePaymentType}>Update<img src={arrows} height="15" width="15" className='mx-2'></img></Button>
                </div>
                <Modal show={showPopUp} onHide={handleClose} className="payment-method">
                    <Modal.Header>
                    <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='center-align text-center'>
                            <p className='font-white'>Payment Method Changed Successfully!</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            }
        </div>
    )
})

export default ChoosePaymentMethod;
