import { getSubscription, getDomains } from "./../api/getDetails";
import { combineReducers } from "redux";
import  vendorreducer  from './vendorStore/vendorreducer';
const initialState = {
    subscriptionStatus: '',
    noOfDomains:0,
    domainList:[],
    user: {name: '', email: '', role: '', id: '', status: ''},
    leakedDetailsData: [], 
    count: 0,
    currentPage: 0, 
    subscriptionType: null,
    domains: [],
    thirdPartySelected: false, 
    employeesSelected: false, 
    usersSelected: false, 
    string:'',
    paymentMethod: ''
};  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "user":
            return { ...state, user: action.payload};
        case 'subscription':
            return {
                ...state,
                subscriptionType: action.payload.data.subscriptionType,
                subscriptionStatus: action.payload.data.subscriptionStatus,
                noOfDomains: action.payload.data.noOfDomains,
                paymentMethod: action.payload.data.paymentMethod
        }
        case 'domains':
        return {
            ...state,
            domains: action.payload.data.domains
        }
        case 'pageChanged':
            return {
                ...state,
                currentPage: action.payload
            }
        case 'leakedDetailsData':
            return {
                ...state,
                leakedDetailsData: action.payload.records, count: action.payload.numDocs
            }
        case 'filters':
            return {
                ...state,
                thirdPartySelected: action.payload.thirdPartySelected,
                employeesSelected: action.payload.employeesSelected,
                usersSelected: action.payload.usersSelected
            }
        case 'string':
            return {
                ...state,
                string: action.payload
            }
      default:
        return state;
    }
  };
  
  const rootReducer = combineReducers({
    user: userReducer,
    vendor: vendorreducer
  });
  
  export default rootReducer;
  